import React from "react";
import { Modal, Backdrop, Fade, Box, Typography, Button } from '@mui/material';
import Spinner from "../../Spinner";
import { modalStyle } from './styles';

const CancelSubscriptionModalNotGt = ({ openCancelSubscriptionModalNotGt, setOpenCancelSubscriptionModalNotGt, cancelSubscriptionNotGt, isCancelLoading }) => {
    return (
        <Modal
          open={openCancelSubscriptionModalNotGt}
          onClose={() => setOpenCancelSubscriptionModalNotGt(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openCancelSubscriptionModalNotGt}>
            <Box sx={{ ...modalStyle }}>
              <Typography variant="h6" component="h2">
                Are you sure you want to cancel this subscription?
              </Typography>
              <Box mt={2} display="flex" justifyContent="space-between">
                {isCancelLoading && <Spinner />}
                <Button variant="contained" color="secondary" onClick={cancelSubscriptionNotGt} disabled={isCancelLoading}>
                  Yes
                </Button>
                <Button variant="contained" color="primary" onClick={() => setOpenCancelSubscriptionModalNotGt(false)} disabled={isCancelLoading}>
                  No
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      );
    };

export default CancelSubscriptionModalNotGt;