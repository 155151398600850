import React, { useState, useEffect } from "react";
import FloatCart from "../FloatCart";
import Header from "../Header";
import Footer from "../Footer";
import HamburgerMenu from "../HamburgerMenu";
import UpdateCourseDetails from "./UpdateCourseDetails";
import "./style.css";
import "react-vertical-timeline-component/style.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import { getBaseUrl } from "../Utils/getBaseUrl";
import CreateCourseBgImage from "../../assets/CreateCourseBgImage.jpg";
import Spinner from "../Spinner";

const CourseMetadataEdit = () => {
  const location = useLocation();
  const { course } = location.state || {};

  const [ownership, setOwnwership] = useState(false);
  const [metaData, setMetaData] = useState({});

  useEffect(() => {
    const checkEligibility = async () => {
      const email = localStorage.getItem("email");
      if (!email || !course?.id) {
        navigate("/");
      }
      try {
        const baseUrl = getBaseUrl();
        const dataRequest = {
          user_email: email,
          course_id: course?.id,
        };

        const response = await fetch(`${baseUrl}/api/teacher-course-eligibility-data`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dataRequest),
        });

        if (response.ok) {
          const responseData = await response.json();
          setMetaData(responseData);
          setOwnwership(true);
        } else {
          navigate("/");
        }
      } catch (e) {
        navigate("/");
      }
    };
    checkEligibility();
  }, []);

  const [state, setState] = useState({
    isOpen: false,
    menuHidden: true,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const email = localStorage.getItem("email");
    if (!email) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (!state.menuHidden) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "static";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.position = "static";
    }
  }, [state.menuHidden]);

  useEffect(() => {
    document.body.style.overflow = "visible";
    document.body.style.position = "static";
  }, []);

  const openFloatCart = () => {
    setState((prevState) => ({ ...prevState, isOpen: !prevState.isOpen }));
  };

  const openMenuWindow = () => {
    setState((prevState) => ({ ...prevState, menuHidden: false }));
  };

  const isMenuOpen = (state) => {
    const menuHidden = state.isOpen === false;
    setState((prevState) => ({ ...prevState, menuHidden }));
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setState((prevState) => ({ ...prevState, menuHidden: true }));
  };

  return (
    !ownership ? (
      <Spinner />
    ) : (
      <div id="outer-container">
        <HamburgerMenu
          isMenuOpenFunc={isMenuOpen}
          pageWrapId="page-wrap"
          outerContainerId="outer-container"
          isOpenStatus={state.menuHidden}
          callbackClose={callbackRoutingCloseMenu}
        />
        <div id="page-wrap">
          <Header openMenu={openMenuWindow} openCart={openFloatCart} />
          <div
            style={{
              backgroundImage: `url(${CreateCourseBgImage})`,
              backgroundSize: "cover",
              backgroundBlendMode: "overlay",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <UpdateCourseDetails metaData={metaData} />
            </div>
          </div>
          <Footer />
          <FloatCart isTheCartOpen={state.isOpen} history={navigate} />
        </div>
      </div>
    )
  );
};

export default CourseMetadataEdit;