import React, { useState, useRef, useEffect } from "react";
import { initializeCanvas } from "./CanvasUtils";
import { initializeWebSocket, closeWebSocket } from "./WebsocketUtils";
import { getBaseUrl } from "../../Utils/getBaseUrl";
import ColorPicker from "./ColorPicker";
import ParticipantsList from "./ParticipantsList";
import Canvas from "./Canvas";
import Spinner from "../../Spinner";
import "./Drawing.css";

const Drawing = ({ courseId, chapterNumber }) => {
  const [sessionId, setSessionId] = useState(null);
  const [isHost, setIsHost] = useState(false);
  const [isDrawingEnabled, setIsDrawingEnabled] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedColor, setSelectedColor] = useState("#000000");
  const canvasRef = useRef(null);
  const socketRef = useRef(null);
  const [canvasWidth, setCanvasWidth] = useState(window.innerWidth - 40);
  const websocketUrl = `wss://77g0u0607h.execute-api.us-east-2.amazonaws.com/production/?courseId=${courseId}&chapterNumber=${chapterNumber}`;
  const userId = parseInt(localStorage.getItem("user_id"), 10);
  const [isLoading, setIsLoading] = useState(false);
  const [drawingData, setDrawingData] = useState(null);

  useEffect(() => {
    const handleResize = () => setCanvasWidth(window.innerWidth - 40);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (drawingData && canvasRef.current) {
      initializeCanvas(drawingData, canvasRef);
    }
  }, [drawingData]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      disconnectWebSocket();
    };

    return () => {
      handleVisibilityChange(); // Call handleVisibilityChange when the component unmounts
    };
  }, []);

  const startSession = async () => {
    try {
      setIsLoading(true);
      const baseUrl = getBaseUrl();

      const dataRequest = {
        course_id: courseId,
        chapter_number: chapterNumber,
        host_id: userId,
      };

      const response = await fetch(`${baseUrl}/api/create-drawing-session`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      });

      const { session_id: newSessionId, host_name } = await response.json();

      setUsers([
        { user_id: userId, name: host_name, is_drawing_enabled: true },
      ]);

      setSessionId(newSessionId);
      setIsHost(true);
      setIsDrawingEnabled(true);
      initializeWebSocket(
        newSessionId,
        websocketUrl,
        socketRef,
        userId,
        true,
        setUsers,
        canvasRef,
        setIsDrawingEnabled
      );
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const joinSession = async (sessionIdToJoin) => {
    if (!sessionIdToJoin) return;
    try {
      setIsLoading(true);
      const baseUrl = getBaseUrl();

      const dataRequest = {
        course_id: courseId,
        chapter_number: chapterNumber,
        drawing_session_id: sessionIdToJoin,
        user_id: userId,
      };

      const response = await fetch(`${baseUrl}/api/join-drawing-session`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      });
      if (response.status !== 200) {
        alert("No such session exists");
        setIsLoading(false);
        return;
      }
      const currentDrawingCanvas = await response.json();

      setUsers(currentDrawingCanvas.participants);

      setSessionId(sessionIdToJoin);
      setDrawingData(currentDrawingCanvas.drawing_data);
      initializeWebSocket(
        sessionIdToJoin,
        websocketUrl,
        socketRef,
        userId,
        isHost,
        setUsers,
        canvasRef,
        setIsDrawingEnabled
      );
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  const clearMine = () => {
    clearCanvas();
    socketRef.current.send(JSON.stringify({ type: "clear", userId: userId }));
  };

  const clearAll = () => {
    if (isHost) {
      clearCanvas();
      socketRef.current.send(JSON.stringify({ type: "clear", userId: "all" }));
    }
  };

  const disconnectWebSocket = () => {
    ("disconnecting websocket");
    if (socketRef.current) {
      closeWebSocket(socketRef);
      socketRef.current = null;
      setIsDrawingEnabled(false);
    }
  };

  return (
    <div>
      {sessionId ? (
        <div>
          <h3>Session ID: {sessionId}</h3>
          <ParticipantsList
            users={users}
            isHost={isHost}
            userId={userId}
            socketRef={socketRef}
            sessionId={sessionId}
            courseId={courseId}
            chapterNumber={chapterNumber}
          />
          <ColorPicker
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
          />
          <Canvas
            canvasRef={canvasRef}
            canvasWidth={canvasWidth}
            isDrawingEnabled={isDrawingEnabled}
            selectedColor={selectedColor}
            socketRef={socketRef}
            courseId={courseId}
            chapterNumber={chapterNumber}
            sessionId={sessionId}
            userId={userId}
            isHost={isHost}
          />
        </div>
      ) : (
        <div className="center-container">
          <h3>Start or Join a Drawing Session</h3>
          <div className="button-container">
            <button className="styled-button" onClick={startSession}>
              Start New Session
            </button>
            <button
              className="styled-button"
              onClick={() => {
                const sessionIdToJoin = prompt("Enter Session ID:");
                if (sessionIdToJoin) joinSession(sessionIdToJoin);
              }}
            >
              Join Existing Session
            </button>
          </div>
        </div>
      )}
      {isLoading && <Spinner />}
    </div>
  );
};

export default Drawing;
