import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faVolumeUp,
  faRepeat,
  faVolumeMute,
  faVolumeXmark,
} from "@fortawesome/free-solid-svg-icons";
import { getBaseUrl } from "../Utils/getBaseUrl";
import { languages } from "./languages"; // Import the languages object

import { useMediaQuery } from "react-responsive";

const apiKey = "AIzaSyCIBPx5Ww6q7Cbuqca6EYoZmTTLeYiRJU4";

// Function to replace placeholders with images
const replaceWithImages = (text, attachments, isDesktopOrLarger) => {
  if (!text) {
    return "";
  }
  return text.replace(/\[attach(\d+)\]/g, (match, index) => {
    const attachmentIndex = parseInt(index) - 1;
    if (attachments[attachmentIndex]) {
      return `<img style="width: ${
        isDesktopOrLarger ? "500px" : "400px"
      }; height: ${
        isDesktopOrLarger ? "500px" : "400px"
      }; object-fit: contain;" src="${
        attachments[attachmentIndex]
      }" alt="attachment${index}" />`;
    } else {
      return match;
    }
  });
};

const Flashcard = ({ quizData, chapterContent }) => {
  const isDesktopOrLarger = useMediaQuery({ minWidth: 768 });
  const course_language = chapterContent.course_language || "en";

  if (quizData.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        No bookmarks present.
      </div>
    );
  }

  const [currentCardIndex, setCurrentCardIndex] = useState(0); // Use index for dataSource
  const [isFlipped, setIsFlipped] = useState(false); // Track flip state
  const [cards, setCards] = useState(quizData); // New state variable for a copy of quizData
  const [disableBookmark, setDisableBookmark] = useState(false);
  const email = localStorage.getItem("email");

  const currentCard = quizData[currentCardIndex];
  const question = replaceWithImages(
    currentCard.question,
    currentCard.attachments,
    isDesktopOrLarger
  );
  const answer = replaceWithImages(
    currentCard.answer,
    currentCard.attachments,
    isDesktopOrLarger
  );
  const option1 = replaceWithImages(
    currentCard.option1,
    currentCard.attachments,
    isDesktopOrLarger
  );
  const option2 = replaceWithImages(
    currentCard.option2,
    currentCard.attachments,
    isDesktopOrLarger
  );
  const option3 = replaceWithImages(
    currentCard.option3,
    currentCard.attachments,
    isDesktopOrLarger
  );

  useEffect(() => {
    setCurrentCardIndex(0);
    setCards(quizData);
  }, [chapterContent]);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  const handleNextCard = () => {
    if (currentCardIndex < quizData.length - 1) {
      setCurrentCardIndex(currentCardIndex + 1);
      setIsFlipped(false); // Reset flip state when moving to next card
    }
  };

  const handlePrevCard = () => {
    if (currentCardIndex > 0) {
      setCurrentCardIndex(currentCardIndex - 1);
      setIsFlipped(false); // Reset flip state when moving to previous card
    }
  };

  const handleBookmark = async () => {
    setDisableBookmark(true);
    // Update locally
    const newCards = [...cards];
    newCards[currentCardIndex].bookmarked =
      newCards[currentCardIndex].bookmarked === 0 ? 1 : 0;
    setCards(newCards);

    // Update on the server
    try {
      const baseUrl = getBaseUrl();
      const dataRequest = {
        quiz_question_id: quizData[currentCardIndex].id,
        user_email: email,
        course_id: chapterContent.course_id,
      };
      const response = await fetch(`${baseUrl}/api/bookmark-question`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      });

      if (!response.ok) {
        const responseData = await response.json();
        console.error("Error setting up bookmark:", responseData);
      }
    } catch (error) {
      console.error("Error setting up bookmark:", error);
    }

    setDisableBookmark(false);
  };

  const handleSpeakUrdu = async (text) => {
    const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`;
    const data = {
      input: { text },
      voice: { languageCode: "ur-PK" }, // Ensure the correct language code and voice name
      audioConfig: { audioEncoding: "MP3" },
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      const audioContent = result.audioContent;
      const audio = new Audio(`data:audio/mp3;base64,${audioContent}`);
      audio.play();
    } catch (error) {
      console.error("Error with Google Text-to-Speech API:", error);
    }
  };

  const handleSpeak = async () => {
    const utterance = new SpeechSynthesisUtterance();
    utterance.text = isFlipped
      ? quizData[currentCardIndex].answer
      : quizData[currentCardIndex].question;
    if (quizData[currentCardIndex]?.course_type === "live") {
      let lang;
      if (isFlipped) {
        lang = languages[quizData[currentCardIndex]?.answer_language];
      } else {
        lang = languages[quizData[currentCardIndex]?.question_language];
      }
      if (lang === "ur-PK") {
        await handleSpeakUrdu(utterance.text);
        return;
      } else {
        utterance.lang = lang;
      }
    } else {
      utterance.lang = isFlipped
        ? languages[localStorage.getItem("user-language")]
        : languages[course_language];
    }

    window.speechSynthesis.speak(utterance);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div style={{ marginBottom: "10px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ flex: 1 }}>
            <FontAwesomeIcon
              icon={faArrowCircleLeft}
              size="3x"
              style={{ padding: "10px" }}
              color={currentCardIndex === 0 ? "gray" : "green"}
              onClick={currentCardIndex === 0 ? null : handlePrevCard}
            />
          </div>
          <div style={{ flex: 2, margin: "0 5px" }}>
            {currentCardIndex + 1} / {quizData.length}
          </div>
          <div>
            <FontAwesomeIcon
              icon={faArrowCircleRight}
              size="3x"
              style={{ padding: "10px" }}
              color={
                currentCardIndex === quizData.length - 1 ? "gray" : "green"
              }
              onClick={handleNextCard}
              disabled={currentCardIndex === quizData.length - 1}
            />
          </div>
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            {((isFlipped &&
              quizData[currentCardIndex]?.answer_language !== "none") ||
              (!isFlipped &&
                quizData[currentCardIndex]?.question_language !== "none")) && (
              <div style={{ width: "80px" }}>
                <FontAwesomeIcon
                  icon={faVolumeUp}
                  size="3x"
                  style={{ padding: "10px" }}
                  onClick={handleSpeak}
                />
              </div>
            )}

            {((isFlipped &&
              quizData[currentCardIndex]?.answer_language === "none") ||
              (!isFlipped &&
                quizData[currentCardIndex]?.question_language === "none")) && (
              <div style={{ width: "80px" }}>
                <FontAwesomeIcon
                  icon={faVolumeXmark}
                  size="3x"
                  style={{ padding: "10px" }}
                  onClick={handleSpeak}
                />
              </div>
            )}
          </div>
          <div style={{ flex: 1 }}>
            <button
              onClick={handleBookmark}
              disabled={disableBookmark}
              style={{
                fontSize: "20px",
                width: "50px", // Explicitly set the width
                height: "50px", // Explicitly set the height
                borderRadius: "50%",
                padding: "10px",
                backgroundColor: "#000080",
                border: "1px solid black",
                color:
                  quizData[currentCardIndex].bookmarked === 1
                    ? "yellow"
                    : "white",
                display: "flex", // Center the content
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {quizData[currentCardIndex].bookmarked === 1 ? "★" : "☆"}
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
          paddingTop: "50px",
          width: "90vw",
          minHeight: isDesktopOrLarger ? "600px" : "500px",
          maxHeight: isDesktopOrLarger ? "600px" : "500px",
          border: "2px solid black",
          borderRadius: "10px",
          backgroundColor: "#1a1a1a",
          color: "yellow",
          overflowY: "auto",
          transition: "transform 0.8s",
          transformStyle: "preserve-3d",
          transform: isFlipped ? "rotateY(180deg)" : "rotateY(0deg)",
        }}
        onClick={handleFlip}
      >
        <div style={{ position: "absolute", top: 0, left: 0, padding: "10px" }}>
          <FontAwesomeIcon icon={faRepeat} size="1x" />
        </div>
        <div
          style={{
            display: isFlipped ? "none" : "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            backfaceVisibility: "hidden",
          }}
        >
          <h3 dangerouslySetInnerHTML={{ __html: question }}></h3>
        </div>
        <div
          style={{
            display: isFlipped ? "flex" : "none",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            backfaceVisibility: "visible",
            transform: "rotateY(180deg)",
          }}
        >
          <h3 dangerouslySetInnerHTML={{ __html: answer }}></h3>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "10px",
          backgroundColor: "#ddd",
          borderRadius: "5px",
          marginTop: "10px",
          width: "90%",
        }}
      >
        <div
          style={{
            width: `${((currentCardIndex + 1) / quizData.length) * 100}%`,
            height: "100%",
            backgroundColor: "#007bff",
            borderRadius: "5px",
          }}
        />
      </div>
    </div>
  );
};

export default Flashcard;
