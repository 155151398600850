import React from 'react';
import Divider from '@mui/material/Divider';


const CustomerHistoryTabPanel = ({ customerHistoryItems }) => {
    let showCustomerHistory;
    try {
      if (customerHistoryItems && Array.isArray(customerHistoryItems) && customerHistoryItems.length > 0) {
        showCustomerHistory = customerHistoryItems.map((item) => {
          return (
            <div key={item.sku} style={{
              marginTop: "10px",
              border: "5px solid green",
              borderRadius: "25px",
              padding: "20px",
              fontFamily: "Times New Roman"
            }}>
              <div>
                <Divider variant="middle" />
                <div>
                  <div>
                    {item.product_title}
                  </div>
                  <div>
                    SKU:&nbsp;  {item.product_sku}
                  </div>
                  <div>
                    Quantity:&nbsp;{item.total_quantity}
                  </div>
                  <Divider variant="middle" />
                </div>
              </div>

              <Divider />

            </div>
          )
        }
        )
      } else {
        return (<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          No customer history available.
        </div>)
      }
    }
    catch (e) {
      return 'Error loading customer history.'
    }
    return showCustomerHistory;
  };

    export default CustomerHistoryTabPanel;
