import React from 'react';
import PropTypes from 'prop-types';

const CartProduct = ({ product, changeProductQuantity }) => {

  const shelfItemStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
    margin: '10px',
  };

  const detailsStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10px',
  };

  const titleStyle = {
    color: 'black',
  };

  const quantityStyle = {
    fontSize: '15px',
    color: 'black',
  };

  const priceStyle = {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  };

  const productFormatWithCurrency = (
    <p>
      $ <span> </span>
      {product.price}
    </p>
  );

  const imgStyle = {
    width: '100px',
    height: '100px',
    objectFit: 'contain',
  };

  return (
    <>
      <div style={shelfItemStyle}>
        <img
          style={imgStyle}
          src={product.images[0]}
          alt={product.title}
        />
      </div>
      <div>
        <div style={detailsStyle}>
          <div>
            <p style={titleStyle}>{product.title}</p>
          </div>
          <div>
            <p style={quantityStyle}>Quantity: {product.quantity}</p>
          </div>
        </div>
        <div style={priceStyle}>{productFormatWithCurrency}</div>
      </div>
    </>
  );
};

CartProduct.propTypes = {
  product: PropTypes.object.isRequired,
  removeProduct: PropTypes.func.isRequired,
  changeProductQuantity: PropTypes.func.isRequired,
};

export default CartProduct;