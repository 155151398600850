export const initializeWebSocket = (
  sessionPinToJoin,
  websocketUrl,
  socketRef,
  userId,
  isHost,
  setUsers,
  setHost,
  setSessionPin,
  setQuestionToStart,
  setQuestionStatus,
  setScoreCard,
  setCurrentQuestionScore
) => {
  let updatedWebsocketUrl;
  if (isHost) {
    updatedWebsocketUrl = `${websocketUrl}&kahootSessionPin=234234&kahootHostId=${userId}`;
  } else {
    updatedWebsocketUrl = `${websocketUrl}&kahootSessionPin=${sessionPinToJoin}&kahootUserId=${userId}`;
  }

  const socket = new WebSocket(updatedWebsocketUrl);
  socketRef.current = socket;

  // Extend the WebSocket instance to include closeCode and closeReason properties
  socket.closeCode = 1000; // Default close code
  socket.closeReason = "Kahoot Closure"; // Default close reason

  // Function to set the close code and reason
  socket.setCloseDetails = (code, reason) => {
    socket.closeCode = code;
    socket.closeReason = reason;
  };

  socket.onopen = () => {
    if (isHost) {
      const initialMessage = JSON.stringify({
        type: "get-session-pin",
        kahoot_host_id: userId,
      });
      socket.send(initialMessage);
    }
  };

  socket.onmessage = (event) => {
    handleWebSocketMessage(
      event,
      setUsers,
      userId,
      setHost,
      setSessionPin,
      setQuestionToStart,
      setQuestionStatus,
      setScoreCard
    );
  };

  socket.onclose = (event) => {
    console.error("WebSocket closed:", event);
    console.error("Close code:", event.code);
    console.error("Close reason:", event.reason);
    setSessionPin(null);
    setHost(null);
    setUsers([]);
    setQuestionToStart(0);
    setQuestionStatus("stop");
    setScoreCard(null);
    setCurrentQuestionScore(0);
  };

  socket.onerror = (error) => {
    console.error("WebSocket error:", error);
  };

  // Listen for the beforeunload event to set a custom close reason
  window.addEventListener("beforeunload", () => {
    socket.setCloseDetails(1000, "Kahoot Closure");
    socket.close(socket.closeCode, socket.closeReason);
  });
};

// Function to close the WebSocket using the predefined code and reason
export const closeWebSocket = (socketRef) => {
  if (socketRef.current) {
    socketRef.current.close(
      socketRef.current.closeCode,
      socketRef.current.closeReason
    );
  }
};

export const handleWebSocketMessage = (
  event,
  setUsers,
  userId,
  setHost,
  setSessionPin,
  setQuestionToStart,
  setQuestionStatus,
  setScoreCard
) => {
  let message;
  if (typeof event.data === "string") {
    try {
      message = JSON.parse(event.data);
    } catch (error) {
      console.error("Error parsing message:", error);
      return;
    }
  } else {
    message = event.data;
  }

  if (message.body === "Connected") {
    setSessionPin(message.sessionId);
  } else if (message.type === "updateKahootParticipants") {
    setUsers(message.participants);
    setHost(message.host);
  } else if (message.session === "ended") {
    // Handle session ended message
  } else if (message.type === "upload-score") {
    // Handle upload score message
  } else if (message.type === "start-question") {
    // Handle start question message
    setQuestionToStart(message.question_number);
    setQuestionStatus("start");
  } else if (message.type === "stop-question") {
    // Handle stop question message
    setScoreCard(message);
    setQuestionToStart(message.question_number + 1);
    setQuestionStatus("stop");
  } else if (message.type === "get-session-details") {
    // Handle get session pin message
    setSessionPin(message.session_pin);
    setHost({ id: message.host_user_id, host_name: message.host_name });
  }
};
