import { getBaseUrl } from '../Utils/getBaseUrl';

export const getUserInfo = async (email) => {
    try {
        const baseUrl = getBaseUrl();
        const dataCheckSubscriptionStatus = {
            email: email,
        };

        const userResponse = await fetch(`${baseUrl}/api/user-info`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dataCheckSubscriptionStatus),
        });
        if (!userResponse.ok) {
            throw new Error('Error getting user info');
        }
        const userData = await userResponse.json();
        return userData;
    } catch (e) {
        console.error('Error getting user info details:', e);
        throw e;
    }
};