import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Fade } from "react-awesome-reveal";

import gtCeo from "./../../assets/gtCeo.jpg";
import gtCto from "./../../assets/gtCto.jpg";
import gtLead1 from "./../../assets/gtLead1.jpg";
import gtLead2 from "./../../assets/gtLead2.jpg";

import "./style.css"; // Import the CSS file

const TeamInfo = () => {
  return (
    <div>
      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>Our Team</h2>
      <Row>
        <Col xs={12} md={6} lg={3} className="d-flex justify-content-center">
          <Fade direction="up" triggerOnce>
            <Card
              border="success"
              style={{
                height: "400px",
                width: "100%",
                margin: "2px",
                transition: "transform 0.2s",
              }}
              className="team-card"
            >
              <Card.Img variant="top" src={gtCeo} style={{ height: "200px" }} />
              <Card.Body style={{ textAlign: "center" }}>
                <Col>
                  <Row
                    style={{
                      display: "grid",
                      placeItems: "center",
                      marginTop: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Ahmed Ghumman
                  </Row>
                  <Row
                    style={{
                      display: "grid",
                      placeItems: "center",
                      marginTop: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Founder
                  </Row>
                  <Row
                    style={{
                      display: "grid",
                      placeItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <a
                      href="https://github.com/ghumman"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="animated-link"
                    >
                      @github
                    </a>
                  </Row>
                </Col>
              </Card.Body>
            </Card>
          </Fade>
        </Col>
        <Col xs={12} md={6} lg={3} className="d-flex justify-content-center">
          <Fade direction="up" triggerOnce delay={100}>
            <Card
              border="success"
              style={{
                height: "400px",
                width: "100%",
                margin: "2px",
                transition: "transform 0.2s",
              }}
              className="team-card"
            >
              <Card.Img variant="top" src={gtCto} style={{ height: "200px" }} />
              <Card.Body style={{ textAlign: "center" }}>
                <Col>
                  <Row
                    style={{
                      display: "grid",
                      placeItems: "center",
                      marginTop: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Alex Carter
                  </Row>
                  <Row
                    style={{
                      display: "grid",
                      placeItems: "center",
                      marginTop: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    CTO
                  </Row>
                </Col>
              </Card.Body>
            </Card>
          </Fade>
        </Col>
        <Col xs={12} md={6} lg={3} className="d-flex justify-content-center">
          <Fade direction="up" triggerOnce delay={200}>
            <Card
              border="success"
              style={{
                height: "400px",
                width: "100%",
                margin: "2px",
                transition: "transform 0.2s",
              }}
              className="team-card"
            >
              <Card.Img variant="top" src={gtLead1} style={{ height: "200px" }} />
              <Card.Body style={{ textAlign: "center" }}>
                <Col>
                  <Row
                    style={{
                      display: "grid",
                      placeItems: "center",
                      marginTop: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Samir Patel
                  </Row>
                  <Row
                    style={{
                      display: "grid",
                      placeItems: "center",
                      marginTop: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Lead Software Engineer
                  </Row>
                </Col>
              </Card.Body>
            </Card>
          </Fade>
        </Col>
        <Col xs={12} md={6} lg={3} className="d-flex justify-content-center">
          <Fade direction="up" triggerOnce delay={300}>
            <Card
              border="success"
              style={{
                height: "400px",
                width: "100%",
                margin: "2px",
                transition: "transform 0.2s",
              }}
              className="team-card"
            >
              <Card.Img variant="top" src={gtLead2} style={{ height: "200px" }} />
              <Card.Body style={{ textAlign: "center" }}>
                <Col>
                  <Row
                    style={{
                      display: "grid",
                      placeItems: "center",
                      marginTop: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Kevin Lin
                  </Row>
                  <Row
                    style={{
                      display: "grid",
                      placeItems: "center",
                      marginTop: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Lead Software Engineer
                  </Row>
                </Col>
              </Card.Body>
            </Card>
          </Fade>
        </Col>
      </Row>
    </div>
  );
};

export default TeamInfo;