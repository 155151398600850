import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getBaseUrl } from "../Utils/getBaseUrl";
import Spinner from "../Spinner";
import { Button } from "@mui/material";
import { withAlert } from "react-alert";
import "./style.css";
import CourseFormFields from "./CourseFormFields";
import CourseCategories from "./CourseCategories";
import FileInput from "./FileInput";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UpdateCourseDetails(props) {
  const navigate = useNavigate();
  const metaData = props.metaData;

  const initialCategories = {
    kids: false,
    programming: false,
    language: false,
    certifications: false,
    live: false,
  };

  const categories = metaData.categories.reduce((acc, category) => {
    if (acc.hasOwnProperty(category)) {
      acc[category] = true;
    }
    return acc;
  }, { ...initialCategories });

  const initialFormData = {
    courseName: metaData.name,
    courseDescription: metaData.description,
    categories: categories,
    images: [null, null, null],
    timing: metaData.timing,
    instructor: metaData.instructor,
    zoomLink: metaData.class_meeting_link,
    monthlyCost: metaData.cost.toString(),
    courseVideoLink: metaData.promotional_video_link, // New field for course video link
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isChanged, setIsChanged] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setIsChanged(true);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      categories: { ...formData.categories, [name]: checked },
    });
    setIsChanged(true);
  };

  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    if (file && file.size <= 1.8 * 1024 * 1024) {
      // 1.8 MB limit
      const newImages = [...formData.images];
      newImages[index] = file;
      setFormData({ ...formData, images: newImages });
      setIsChanged(true);
    } else {
      setErrors({ ...errors, images: "Each image must be less than 1.8MB" });
    }
  };

  const getChangedFields = () => {
    const changedFields = {};
    Object.keys(formData).forEach((key) => {
      if (JSON.stringify(formData[key]) !== JSON.stringify(initialFormData[key])) {
        changedFields[key] = formData[key];
      }
    });
    return changedFields;
  };

  const handleSubmit = async () => {
    setErrors({});
    const errors = {};

    if (!formData.courseName.trim()) {
      errors.courseName = "Course name is required";
    } else if (formData.courseName.length > 100) {
      errors.courseName = "Course name cannot be more than 100 characters";
    }

    if (!formData.courseDescription.trim()) {
      errors.courseDescription = "Course description is required";
    } else if (formData.courseDescription.length > 1000) {
      errors.courseDescription =
        "Course description cannot be more than 1000 characters";
    }

    const selectedCategories = Object.keys(formData.categories).filter(
      (category) => formData.categories[category]
    );

    if (!formData.timing.trim()) {
      errors.timing = "Timing is required";
    }

    if (!formData.instructor.trim()) {
      errors.instructor = "Instructor is required";
    }

    if (!formData.zoomLink.trim()) {
      errors.zoomLink = "Zoom link is required";
    }

    if (!formData.monthlyCost.trim()) {
      errors.monthlyCost = "Monthly cost is required";
    } else if (
      isNaN(formData.monthlyCost) ||
      formData.monthlyCost < 0 ||
      formData.monthlyCost > 1000
    ) {
      errors.monthlyCost = "Monthly cost must be between 0 and 1000";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    try {
      setIsLoading(true);
      const baseUrl = getBaseUrl();
      const formDataToSend = new FormData();
      const changedFields = getChangedFields();

      formDataToSend.append("course_id", metaData.id);

      if (changedFields.courseName) {
        formDataToSend.append("course_name", changedFields.courseName);
      }
      if (changedFields.courseDescription) {
        formDataToSend.append("course_description", changedFields.courseDescription);
      }
      if (changedFields.categories) {
        selectedCategories.push("courses"); // Add 'courses' category
        formDataToSend.append("categories", JSON.stringify(selectedCategories));
      }
      if (changedFields.images) {
        changedFields.images.forEach((image, index) => {
          if (image) {
            formDataToSend.append(`image_${index}`, image);
          }
        });
      }
      if (changedFields.timing || changedFields.instructor || changedFields.zoomLink) {
        formDataToSend.append(
          "meetingDetails",
          JSON.stringify({
            timing: changedFields.timing || formData.timing,
            instructor: changedFields.instructor || formData.instructor,
            zoomLink: changedFields.zoomLink || formData.zoomLink,
          })
        );
      }
      if (changedFields.monthlyCost) {
        formDataToSend.append("monthly_cost", changedFields.monthlyCost);
      }
      if (changedFields.courseVideoLink) {
        formDataToSend.append("course_video_link", changedFields.courseVideoLink);
      }
      formDataToSend.append("user_email", localStorage.getItem("email")); // Append user email

      const response = await fetch(`${baseUrl}/api/update-course-metadata`, {
        method: "POST",
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error("Failed to update course");
      }
      toast.success('Course updated successfully');
      setErrorMessage("Course updated successfully.");
      navigate("/MyAccount/Teaching");
    } catch (error) {
      toast.error('Failed to update course. Please try again later');
      setErrorMessage("Failed to update course. Please try again later.");
      console.error("Error creating course:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="form-container">
          <h1 className="header">Update Course</h1>
          <CourseFormFields
            formData={formData}
            handleChange={handleChange}
            errors={errors}
          />
          <CourseCategories
            formData={formData}
            handleCheckboxChange={handleCheckboxChange}
            errors={errors}
          />
          <FileInput
            formData={formData}
            handleFileChange={handleFileChange}
            errors={errors}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              className="submitButton"
              disabled={!isChanged}
            >
              Update Course
            </Button>
          </div>
          {errorMessage && <p className="errorMessage">{errorMessage}</p>}
        </div>
      )}
    </div>
  );
}

export default withAlert()(UpdateCourseDetails);