import React from 'react';
import { makeStyles } from '@mui/styles';
import Review from './Review'; // Adjust the import path as necessary

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    button: {
        borderRadius: '10px',
        fontSize: '20px',
        fontWeight: 'bold',
        backgroundColor: 'green',
        color: 'white',
        paddingTop: '10px',
        paddingBottom: '10px',
        margin: '10px',
    },
    heading: {
        marginTop: '20px',
    },
});

const QuizResult = ({ score, quizData, userAnswers, handleRestartQuiz, handleUploadScore, isBookmarkedTab, isLoading }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.buttonContainer}>
                <button className={classes.button} onClick={handleRestartQuiz}>Restart Quiz</button>
                {!isBookmarkedTab && (
                    <button className={classes.button} onClick={handleUploadScore} disabled={isLoading}>
                        Upload Score
                    </button>
                )}
            </div>
            <h2 className={classes.heading}>You scored {score} out of {quizData.length} questions!</h2>
            <Review quizData={quizData} userAnswers={userAnswers} />
        </div>
    );
};

export default QuizResult;