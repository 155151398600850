import React from 'react';
import PropTypes from 'prop-types';
import './style.css'; // Assuming you have a CSS file for styling

const AdImage = ({ src }) => {
  return (
    <div className="ad-image-container">
      <img src={src} alt="Advertisement" className="ad-image" />
    </div>
  );
};

export default AdImage;