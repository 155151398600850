import React, { useState, useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';


function TotalSeminarsAnimation() {
  const desiredTotalSeminars = 150;
  const desiredTotalUsers = 1800;
  const desiredTotalPresenters = 40;

  const numberElementRef = useRef(null)

  const [currentValueTotalSeminars, setCurrentValueTotalSeminars] = useState(0);
  const [isAnimatingTotalSeminars, setIsAnimatingTotalSeminars] = useState(false);

  const [currentValueTotalUsers, setCurrentValueTotalUsers] = useState(0);
  const [isAnimatingTotalUsers, setIsAnimatingTotalUsers] = useState(false);

  const [currentValueTotalPresenters, setCurrentValueTotalPresenters] = useState(0);
  const [isAnimatingTotalPresenters, setIsAnimatingTotalPresenters] = useState(false);

  useEffect(() => {
    if (isAnimatingTotalSeminars) {
      const intervalId = setInterval(() => {
        setCurrentValueTotalSeminars((prevValue) => {
          const numericPrevValue = Number(prevValue); // Ensure numeric conversion
          return Number.isNaN(numericPrevValue) ? 0 : numericPrevValue + 1;
        });
        if (currentValueTotalSeminars >= desiredTotalPresenters) {
          setCurrentValueTotalSeminars(desiredTotalSeminars + '+');
          clearInterval(intervalId);
          setIsAnimatingTotalSeminars(false);
        }
      }, 10); // Adjust interval duration as needed

      return () => clearInterval(intervalId);
    }
  }, [isAnimatingTotalSeminars, currentValueTotalSeminars]);

  useEffect(() => {
    if (isAnimatingTotalUsers) {
      const intervalId = setInterval(() => {
        setCurrentValueTotalUsers((prevValue) => {
          const numericPrevValue = Number(prevValue); // Ensure numeric conversion
          return Number.isNaN(numericPrevValue) ? 0 : numericPrevValue + 1;
        });
        if (currentValueTotalUsers >= desiredTotalPresenters) {
          setCurrentValueTotalUsers(desiredTotalUsers + '+');
          clearInterval(intervalId);
          setIsAnimatingTotalUsers(false);
        }
      }, 10); // Adjust interval duration as needed

      return () => clearInterval(intervalId);
    }
  }, [isAnimatingTotalUsers, currentValueTotalUsers]);

  useEffect(() => {
    if (isAnimatingTotalPresenters) {
      const intervalId = setInterval(() => {
        setCurrentValueTotalPresenters((prevValue) => {
          const numericPrevValue = Number(prevValue); // Ensure numeric conversion
          return Number.isNaN(numericPrevValue) ? 0 : numericPrevValue + 1;
        });
        if (currentValueTotalPresenters >= desiredTotalPresenters) {
          setCurrentValueTotalPresenters(desiredTotalPresenters + '+');
          clearInterval(intervalId);
          setIsAnimatingTotalPresenters(false);
        }
      }, 10); // Adjust interval duration as needed

      return () => clearInterval(intervalId);
    }
  }, [isAnimatingTotalPresenters, currentValueTotalPresenters]);



  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        // Trigger animation here
        setIsAnimatingTotalSeminars(true);
        setIsAnimatingTotalUsers(true);
        setIsAnimatingTotalPresenters(true);
      } else {
        setCurrentValueTotalSeminars(0);
        setCurrentValueTotalUsers(0);
        setCurrentValueTotalPresenters(0);
      }
    });

    observer.observe(numberElementRef.current);

    return () => observer.disconnect();
  }, []);


  return (
    <div ref={numberElementRef} className={isAnimatingTotalSeminars ? 'animating' : ''}>
      <h1 style={{ margin: '10px', textAlign: 'center' }}>So Far...</h1>
      <Container>
        <Row>
          <Col xs={12} md={4} style={{ margin: '5px auto' }}>
            <Card border="success" style={{ width: '18rem', height: '20rem', margin: '0 auto' }}>
              <Card.Body style={{ textAlign: 'center' }}>
                <Col>
                  <Row style={{ display: 'grid', placeItems: 'center' }}>
                    <div style={{ width: '250px', height: '250px', borderRadius: '200px', backgroundColor: 'green', color: 'white', display: 'grid', placeItems: 'center', fontSize: '80px' }}>
                      {currentValueTotalSeminars}
                    </div>
                  </Row>
                  <Row style={{ display: 'grid', placeItems: 'center', marginTop: '10px', fontWeight: 'bold' }}>
                    Total Seminars
                  </Row>
                </Col>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={4} style={{ margin: '5px auto' }}>
            <Card border="success" style={{ width: '18rem', height: '20rem', margin: '0 auto' }}>
              <Card.Body style={{ textAlign: 'center' }}>
                <Col>
                  <Row style={{ display: 'grid', placeItems: 'center' }}>
                    <div style={{ width: '250px', height: '250px', borderRadius: '200px', backgroundColor: 'green', color: 'white', display: 'grid', placeItems: 'center', fontSize: '80px' }}>
                      {currentValueTotalUsers}
                    </div>
                  </Row>
                  <Row style={{ display: 'grid', placeItems: 'center', marginTop: '10px', fontWeight: 'bold' }}>
                    Total Users
                  </Row>
                </Col>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={4} style={{ margin: '5px auto' }}>
            <Card border="success" style={{ width: '18rem', height: '20rem', margin: '0 auto' }}>
              <Card.Body style={{ textAlign: 'center' }}>
                <Col>
                  <Row style={{ display: 'grid', placeItems: 'center' }}>
                    <div style={{ width: '250px', height: '250px', borderRadius: '200px', backgroundColor: 'green', color: 'white', display: 'grid', placeItems: 'center', fontSize: '80px' }}>
                      {currentValueTotalPresenters}
                    </div>
                  </Row>
                  <Row style={{ display: 'grid', placeItems: 'center', marginTop: '10px', fontWeight: 'bold' }}>
                    Total Presenters
                  </Row>
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TotalSeminarsAnimation;