import React from "react";
import { Button } from "@mui/material";
import { toast } from 'react-toastify';
import AttachmentIcon from '@mui/icons-material/Attachment';

const FileUpload = ({ attachedFile, setAttachedFile, setAttachedFileContent, handleCreateChapterUsingAI }) => {
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === "application/pdf" || file.type === "text/plain") && file.size <= 2500 * 1024) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setAttachedFileContent(e.target.result);
      };
      reader.readAsText(file);
      setAttachedFile(file);
    } else {
      toast.error("Please upload a valid PDF or text file not greater than 2.5 MB.");
    }
  };

  return (
    <div>
      <input
        type="file"
        accept="application/pdf,text/plain"
        style={{ display: "none" }}
        id="file-upload"
        onChange={handleFileUpload}
      />
      <label htmlFor="file-upload">
        <Button
          variant="contained"
          component="span"
          style={{ marginLeft: "10px" }}
        >
          Attach PDF/Text File
        </Button>
      </label>
      {attachedFile && <AttachmentIcon style={{ marginLeft: "10px" }} />}
      <Button
        variant="contained"
        color="primary"
        onClick={handleCreateChapterUsingAI}
        style={{ marginLeft: "10px" }}
      >
        Create Chapter Using AI
      </Button>
    </div>
  );
};

export default FileUpload;