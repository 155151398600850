import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import roboticsArm from './../MainPage/roboticsArm.jpg';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import './style.css';
import TimezoneSelector from './TimezoneSelector';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { set } from 'react-ga';
import useRegisterClass from './useRegisterClass';
import Spinner from '../Spinner';




const TrialDetails = () => {
    const isDesktopOrLarger = useMediaQuery({ minWidth: 768 });

    const [phone, setPhone] = useState('');
    const [childName, setChildName] = useState(null);
    const [parentName, setParentName] = useState(null);
    const [customerEmail, setCustomerEmail] = useState(null);
    const grades = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const availableTimeSlots = ['07:00 AM', '08:00 AM', '09:00 AM', '10:00 AM', '11:00 AM', '12:00 PM', '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM', '06:00 PM', '07:00 PM', '08:00 PM', '09:00 PM', '10:00 PM', '11:00 PM'];
    const [selectedGrade, setSelectedGrade] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [errorPhone, setErrorPhone] = useState('');
    const [errorGrade, setErrorGrade] = useState('');
    const [errorZone, setErrorZone] = useState('');
    const [errorSlot, setErrorSlot] = useState('');
    const [errorDate, setErrorDate] = useState('');
    const [errorChildName, setErrorChildName] = useState('');
    const [errorParentName, setErrorParentName] = useState('');
    const [errorCustomerEmail, setErrorCustomerEmail] = useState('');
    const [selectGradeReady, setSelectGradeReady] = useState(false);
    const [selectSlotReady, setSelectSlotReady] = useState(false);
    const [selectedTimezone, setSelectedTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [isLoading, setIsLoading] = useState(false);

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const dayAfterTomorrow = new Date(today);
    dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);

    const dayAfterDayAfterTomorrow = new Date(today);
    dayAfterDayAfterTomorrow.setDate(dayAfterDayAfterTomorrow.getDate() + 3);
    const options = { weekday: 'long', day: '2-digit', month: 'short' };
    const availableDates = [tomorrow, dayAfterTomorrow, dayAfterDayAfterTomorrow].map(date =>
        new Intl.DateTimeFormat('en-US', options).format(date)
    );

    const registerClass = useRegisterClass();

    useEffect(() => {
        // Set the default timezone to the user's current timezone
        setSelectedTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    }, []);

    const handleGradeSelectClick = () => {
        if (!phone) {
            setErrorPhone('Please enter your phone number');
        }
        if (!selectedGrade) {
            setErrorGrade('Please select a grade');
        }
        if (!phone || !selectedGrade) {
            return;
        }
        setSelectGradeReady(true);
    };


    const handleTimeSlotClick = () => {
        if (!selectedTimezone) {
            setErrorZone('Please enter your time zone');
        }
        if (!selectedDate) {
            setErrorDate('Please select a date');
        }
        if (!selectedSlot) {
            setErrorSlot('Please select a time slot');
        }
        if (!selectedTimezone || !selectedDate || !selectedSlot) {
            return;
        }
        setSelectSlotReady(true);
    };

    const handleSubmitTrial = () => {
        if (!childName) {
            setErrorChildName('Please enter your child name');
        }
        if (!parentName) {
            setErrorParentName('Please enter your parent name');
        }
        let emailCorrect = true;
        if (!customerEmail) {
            setErrorCustomerEmail('Please enter your email');
            emailCorrect = false;
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(customerEmail)) {
                setErrorCustomerEmail('Please enter a valid email');
                emailCorrect = false;
            }
        }
        if (!childName || !parentName || !emailCorrect) {
            return;
        }
        finalizeTrial();
    };

    const finalizeTrial = async () => {
        try {
            setIsLoading(true);
            const responseRegister = await registerClass(
                phone,
                childName,
                parentName,
                customerEmail,
                selectedGrade,
                selectedSlot,
                selectedTimezone.value || selectedTimezone,
                selectedDate
            );
        } catch (error) {
            console.error('Error registering class', error);
        } finally {
            setIsLoading(false);
        };
    }

    return (
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', flexDirection: isDesktopOrLarger ? 'row' : 'column' }}>
            {isLoading && <Spinner />}
            <div style={{ display: 'flex', flex: 4, margin: '20px', flexDirection: 'column' }}>
                {!selectGradeReady && (
                    <>
                        <h1>Book Free Robotics Trial Class</h1>
                        <div style={{ padding: '20px', fontFamily: 'Roboto, sans-serif' }}>
                            <div style={{ fontFamily: 'cursive', fontSize: '25px' }}>Phone Number</div>
                            <PhoneInput
                                country={'us'}
                                value={phone}
                                onChange={phone => setPhone(phone)}
                            />
                            {errorPhone && !phone && <div style={{ color: 'red' }}>{errorPhone}</div>}
                        </div>
                        <div className="grade-selector">
                            <h2>Select Child's Grade</h2>
                            <div className="grade-grid">
                                {grades.map(grade => (
                                    <div
                                        key={grade}
                                        className={`grade-button ${selectedGrade === grade ? 'selected' : ''}`}
                                        onClick={() => setSelectedGrade(grade)}
                                    >
                                        <strong>{grade}</strong>
                                    </div>
                                ))}
                            </div>
                            {errorGrade && !selectedGrade && <div style={{ color: 'red' }}>{errorGrade}</div>}
                        </div>

                        <div style={{ padding: '20px' }}>
                            <Button
                                variant="outlined"
                                startIcon={<AccessTimeIcon />}
                                style={{ color: 'red', borderColor: 'red', padding: '20px', fontSize: '20px' }}
                                onClick={handleGradeSelectClick}
                            >
                                Pick a Time Slot
                            </Button>
                        </div>
                    </>)}
                {selectGradeReady && !selectSlotReady && (
                    <>
                        <TimezoneSelector
                            selectedTimezone={selectedTimezone}
                            setSelectedTimezone={setSelectedTimezone}
                            errorZone={errorZone}
                        />
                        <div className="grade-selector">
                            <h2>Select Date of Trial Class</h2>
                            <div className="grade-grid">
                                {availableDates.map(dd => (
                                    <div
                                        key={dd}
                                        className={`grade-button ${selectedDate === dd ? 'selected' : ''}`}
                                        onClick={() => setSelectedDate(dd)}
                                    >
                                        <strong>{dd}</strong>
                                    </div>
                                ))}
                            </div>
                            {errorDate && !selectedDate && <div style={{ color: 'red' }}>{errorDate}</div>}
                        </div>
                        <div className="grade-selector">
                            <h2>Pick your time slot</h2>
                            <div className="grade-grid">
                                {availableTimeSlots.map(slot => (
                                    <div
                                        key={slot}
                                        className={`grade-button ${selectedSlot === slot ? 'selected' : ''}`}
                                        onClick={() => setSelectedSlot(slot)}
                                    >
                                        <strong>{slot}</strong>
                                    </div>
                                ))}
                            </div>
                            {errorSlot && !selectedSlot && <div style={{ color: 'red' }}>{errorSlot}</div>}
                        </div>
                        <div>
                            <Button
                                variant="outlined"
                                startIcon={<EventAvailableIcon />}
                                style={{ color: 'red', borderColor: 'red', padding: '20px', fontSize: '20px' }}
                                onClick={handleTimeSlotClick}
                            >
                                Finish Scheduling Your Trial Class
                            </Button>
                        </div>
                    </>
                )}
                {selectGradeReady && selectSlotReady && (
                    <>
                        <div style={{ display: 'flex', flex: 1, padding: '20px' }}>
                            <h1>
                                Student Details
                            </h1>
                        </div>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', padding: '20px' }}>
                            <div style={{ fontFamily: 'cursive', fontSize: '25px' }}>
                                Child's Name
                            </div>
                            <div>
                                <TextField
                                    id="child-name"
                                    variant="outlined"
                                    placeholder="Enter child's name"
                                    fullWidth
                                    value={childName}
                                    onChange={(e) => setChildName(e.target.value)}
                                />
                            </div>
                            <div>
                                {errorChildName && !childName && <div style={{ color: 'red' }}>{errorChildName}</div>}
                            </div>
                        </div>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', padding: '20px' }}>
                            <div style={{ fontFamily: 'cursive', fontSize: '25px' }}>
                                Parent's Name
                            </div>
                            <div>
                                <TextField
                                    id="parent-name"
                                    variant="outlined"
                                    placeholder="Enter parent's name"
                                    fullWidth
                                    value={parentName}
                                    onChange={(e) => setParentName(e.target.value)}
                                />
                            </div>
                            <div>
                                {errorParentName && !parentName && <div style={{ color: 'red' }}>{errorParentName}</div>}
                            </div>
                        </div>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', padding: '20px' }}>
                            <div style={{ fontFamily: 'cursive', fontSize: '25px' }}>
                                Email
                            </div>
                            <div>
                                <TextField
                                    id="email"
                                    variant="outlined"
                                    placeholder="Enter email"
                                    fullWidth
                                    value={customerEmail}
                                    onChange={(e) => setCustomerEmail(e.target.value)}
                                />
                            </div>
                            <div>
                                {errorCustomerEmail && <div style={{ color: 'red' }}>{errorCustomerEmail}</div>}
                            </div>
                        </div>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', padding: '20px' }}>

                            <Button
                                variant="outlined"
                                startIcon={<SendIcon />}
                                style={{ color: 'red', borderColor: 'red', padding: '10px 20px', fontSize: '20px', height: '50px' }}
                                onClick={handleSubmitTrial}
                                disabled={isLoading}
                            >
                                Submit
                            </Button>
                        </div>
                        <div style={{ display: 'flex', flex: 1 }}></div>
                    </>
                )}
            </div>


            <div style={{ display: 'flex', flex: 4, flexDirection: 'column', margin: '20px', alignContent: 'center' }}>
                <div style={{
                    flex: 1,
                    marginLeft: '20px',
                    fontSize: '30px',
                    fontWeight: 'bold',
                    fontFamily: 'Courier New',
                    color: '#333',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    color: 'white',
                    marginRight: '20px',
                }}>
                    <div style={{ backgroundColor: 'red', padding: '10px' }}>
                        Grade: 1 to 10
                    </div>
                </div>
                <div style={{ flex: 1, marginLeft: '20px', fontSize: '40px', fontWeight: 'bold', fontFamily: 'Courier New', color: '#007BFF' }}>
                    Empower your child to become a Future Innovator
                </div>
                <div style={{ flex: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'auto' }}>
                <div className="ad-image-container">
                    <img src={roboticsArm} alt="Robotics" className="ad-image" />
                    {/* <img src={roboticsArm} alt="Robotics" style={{ width: '100%', height: '100%', objectFit: 'contain' }} /> */}
                </div>
                </div>
            </div>

        </div>
    );
};

export default TrialDetails;