import React, { useState, useEffect } from 'react';
import TimezoneSelect from 'react-timezone-select';

const TimezoneSelector = ({ selectedTimezone, setSelectedTimezone, errorZone }) => {

  return (
    <div style={{fontFamily: 'cursive', padding: '20px'}}>
      <h2>Select Your Timezone</h2>
      <TimezoneSelect value={selectedTimezone} onChange={setSelectedTimezone} />

      <div>
      <strong>Selected Timezone:</strong> {selectedTimezone.value}
      </div>
      {errorZone && !selectedTimezone && <div style={{ color: 'red' }}>{errorZone}</div>}
    </div>
  );
};

export default TimezoneSelector;