import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css'; // Import the CSS file

const QuizNavigation = ({
    userAnswers,
    setCurrentQuestion,
    currentQuestion,
    quizData,
    handlePreviousQuestion,
    handleNextQuestion,
    handleSubmitQuiz,
    handleBookmark,
    disableBookmark
}) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <FontAwesomeIcon
                icon={faArrowCircleLeft}
                size="3x"
                style={{ padding: '10px' }}
                color={currentQuestion === 0 ? 'gray' : 'green'}
                onClick={currentQuestion === 0 ? null : handlePreviousQuestion}
            />

            <DropdownButton
                id="dropdown-basic-button"
                title={`Question ${currentQuestion + 1} / ${quizData.length}`}
                style={{ margin: '0 10px' }}
                className="dropdown-scrollable" // Add custom class
            >
                {quizData.map((question, index) => (
                    <Dropdown.Item
                        key={index}
                        onClick={() => setCurrentQuestion(index)}
                        style={{ color: userAnswers[index] === null ? 'red' : 'green' }} // Conditional color
                    >
                        Question {index + 1}
                    </Dropdown.Item>
                ))}
            </DropdownButton>

            {currentQuestion === quizData.length - 1 ? (
                <button
                    style={{
                        borderRadius: '10px',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        backgroundColor: 'green',
                        color: 'white',
                        paddingTop: '10px',
                        paddingBottom: '10px'
                    }}
                    onClick={handleSubmitQuiz}
                >
                    Submit Quiz
                </button>
            ) : (
                <FontAwesomeIcon
                    icon={faArrowCircleRight}
                    size="3x"
                    style={{ padding: '10px' }}
                    color='green'
                    onClick={handleNextQuestion}
                />
            )}

            <button
                onClick={handleBookmark}
                style={{
                    fontSize: '20px',
                    width: '50px', // Explicitly set the width
                    height: '50px', // Explicitly set the height
                    borderRadius: '50%',
                    padding: '10px',
                    backgroundColor: '#000080',
                    border: '1px solid black',
                    color: quizData[currentQuestion].bookmarked === 1 ? 'yellow' : 'white',
                    display: 'flex', // Center the content
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                disabled={disableBookmark}
            >
                {quizData[currentQuestion].bookmarked === 1 ? '★' : '☆'}
            </button>
        </div>
    );
};

export default QuizNavigation;