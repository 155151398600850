import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { FaYoutube, FaFacebook, FaInstagram, FaPinterest, FaLinkedin } from 'react-icons/fa';
import { FaSquareXTwitter } from "react-icons/fa6";
import './style.css';
import { useNavigate } from 'react-router-dom';
import { youtubeLink, facebookLink, instagramLink, twitterLink, pintrestLink } from './../Utils/constants'

const Header = () => { 
  const navigate = useNavigate();
  const fbOpen = () => {
    window.open('https://www.facebook.com/GhummanTech/');
  };

  const privacyOpen = () => {
    navigate('/Privacy');
  };

  const userAgreementOpen = () => {
    navigate('/UserAgreement');
  };

  const exchangeOpen = () => {
    navigate('/Exchange');
  };

  const sizeChartOpen = () => {
    navigate('/SizeChart');
  };

  const contactOpen = () => {
    navigate('/ContactUs');
  };

  const aboutOpen = () => {
    navigate('/AboutUs');
  };
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <a style={{ display: "inline-block", borderTopStyle: "solid" }}>
          &ensp;&ensp;&ensp;
        </a>
      </div>
      <Box
        display="flex"
        justifyContent="space-between"
        p={1}
        m={1}
      >


        <Box p={1}>

          <div style={{ fontFamily: "Georgia", cursor: "pointer" }} onClick={() => exchangeOpen()}>Exchanges & Refunds</div>
          <div style={{ fontFamily: "Georgia", cursor: "pointer" }} onClick={() => privacyOpen()}>Privacy Policy</div>
          <div style={{ fontFamily: "Georgia", cursor: "pointer" }} onClick={() => userAgreementOpen()}>User Agreement</div>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          p={1}
          m={1}
        >

        <Box p={1}>
          <a href={youtubeLink} target="_blank" rel="noopener noreferrer">
            <FaYoutube style={{ color: 'red', margin: '5px' }} />
          </a>
          <a href={facebookLink} target="_blank" rel="noopener noreferrer">
            <FaFacebook style={{ color: 'blue', margin: '5px' }} />
          </a>
          <a href={instagramLink} target="_blank" rel="noopener noreferrer">
            <FaInstagram style={{ color: 'purple', margin: '5px' }} />
          </a>
          <a href={twitterLink} target="_blank" rel="noopener noreferrer">
            <FaSquareXTwitter style={{ color: "black", margin: '5px' }} />
          </a>
          <a href={pintrestLink} target="_blank" rel="noopener noreferrer">
            <FaPinterest style={{ color: "red", margin: '5px' }} />
          </a>
          <a href="https://www.linkedin.com/company/ghummantech" target="_blank" rel="noopener noreferrer">
            <FaLinkedin style={{ color: "blue", margin: '5px' }} />
          </a>
        </Box>
        <Box p={1}>
          &copy; {new Date().getFullYear()} Ghumman Tech 
        </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          p={1}
          m={1}
        >
          <Box p={1}>

            <div style={{ fontFamily: "Georgia", cursor: "pointer" }} onClick={() => contactOpen()}>Contact us</div>
            <div style={{ fontFamily: "Georgia", cursor: "pointer" }} onClick={() => aboutOpen()}>About us</div>
          
          </Box>
        </Box>
      </Box>

    </div>
  );
}
export default Header;