import React from "react";
import "./Clock.css";

const Clock = ({ remainingTime }) => {
  const seconds = remainingTime % 60;
  const secondsDegrees = (seconds / 60) * 360 + 90;

  return (
    <div className="clock">
      <div className="clock-face">
        <div
          className="hand"
          style={{ transform: `rotate(${secondsDegrees}deg)` }}
        ></div>
      </div>
    </div>
  );
};

export default Clock;
