import React, { useState, useEffect } from 'react';
import FloatCart from '../FloatCart';
import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';
import SeminarInfo from './SeminarInfo';
import './style.css';
import 'react-vertical-timeline-component/style.min.css';
import { useNavigate } from 'react-router-dom';


const Seminar = () => {
  const [state, setState] = useState({
    isOpen: false,
    menuHidden: true,
  });
  const navigate = useNavigate();

  const openFloatCart = () => {
    setState((prevState) => ({ ...prevState, isOpen: !prevState.isOpen }));
  };

  const openMenuWindow = () => {
    setState((prevState) => ({ ...prevState, menuHidden: false }));
  };

  const isMenuOpen = (state) => {
    const menuHidden = state.isOpen === false;
    setState((prevState) => ({ ...prevState, menuHidden }));
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setState((prevState) => ({ ...prevState, menuHidden: true }));
  };

  return (
    <div id="outer-container">
      <HamburgerMenu
        isMenuOpenFunc={isMenuOpen}
        pageWrapId="page-wrap"
        outerContainerId="outer-container"
        isOpenStatus={state.menuHidden}
        callbackClose={callbackRoutingCloseMenu}
      />
      <div id="page-wrap">
        <Header openMenu={openMenuWindow} openCart={openFloatCart} />
          <div style={{marginLeft: '10px', marginRight: '10px'}}>
            <SeminarInfo />
          </div>
        <Footer />
        <FloatCart isTheCartOpen={state.isOpen} history={navigate} />
      </div>
    </div>
  );
};

export default Seminar;