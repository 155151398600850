import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getBaseUrl } from '../Utils/getBaseUrl';
import Spinner from '../Spinner';
import { Card, CardContent, CardMedia, Typography, Chip, Grid, Button, IconButton, Box } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import ShareIcon from '@mui/icons-material/Share';
import LinkIcon from '@mui/icons-material/Link';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles

function ThreeLatestCourses() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        setIsLoading(true);
        const baseUrl = getBaseUrl();

        const response = await fetch(`${baseUrl}/api/last-three-courses`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        });

        if (response.status === 500) {
          navigate('/Careers');
          return; // Exit the function to avoid further code execution
        }

        const data = await response.json();
        setCourses(data.products);
      } catch (error) {
        console.log('Unable to fetch job details');
      } finally {
        setIsLoading(false);
      }
    };

    fetchJobDetails();
  }, []);

  const handleShare = async (course) => {
    const shareUrl = `https://www.ghummantech.com/Categories/Product/${course.sku}`;
    try {
      await navigator.share({
        title: course.title,
        url: shareUrl,
      });
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.log('Share failed:', error);
      }
    }
  };

  const handleCopyLink = (course) => {
    const shareUrl = `https://www.ghummantech.com/Categories/Product/${course.sku}`;
    navigator.clipboard.writeText(shareUrl).then(() => {
      alert('Link copied to clipboard!');
    });
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Box>
      <div className='header-title' style={{ color: 'rgb(254, 1, 0)', fontWeight: 'bold', fontFamily: 'Times New Roman', textAlign: 'center' }}>
        Latest Courses for Curious Minds
      </div>
      <div className='header-subtitle' style={{ textAlign: 'center', marginTop: '20px' }}>
        Inspire the Next Generation—Launch Your Course Now!
      </div>
      <Grid container spacing={3} style={{ padding: '20px' }}>
        {courses.map((course) => (
          <Grid item xs={12} sm={6} md={4} key={course.id} style={{ padding: '20px' }}>
            <Card>
              <Carousel showThumbs={false} showStatus={false} autoPlay interval={3000} infiniteLoop>
                {course.images.map((image, index) => (
                  <CardMedia
                    key={index}
                    component="img"
                    height="300" // Slightly increased the height
                    image={image}
                    alt={course.title}
                  />
                ))}
              </Carousel>
              <CardContent>
                <Typography variant="h6" noWrap style={{ fontWeight: 'bold', fontFamily: 'Times New Roman' }}>
                  {course.title}
                </Typography>
                <Chip
                  label={course.price === 0 ? 'FREE!' : `$${course.price}`}
                  color="primary"
                  variant="outlined"
                />
                <Chip
                  label={course.included_in_membership ? 'Ghumman Tech' : course.owner_name}
                  color="secondary"
                  variant="outlined"
                />
                <IconButton
                  aria-label="share"
                  onClick={() => handleShare(course)}
                >
                  <ShareIcon />
                </IconButton>
                <IconButton
                  aria-label="copy link"
                  onClick={() => handleCopyLink(course)}
                >
                  <LinkIcon />
                </IconButton>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate(`/Categories/Product/${course.sku}`)}
                >
                  View Course
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default ThreeLatestCourses;