import React from 'react';
import seminars from '../../assets/landingPageThreeCards/seminarRoom.jpg';
import SeminarList from '../Seminars/SeminarList';

const SeminarsLandingPageDetails = () => {
  const containerStyle = {
    width: '80vw',
    backgroundImage: `url(${seminars})`,
    backgroundSize: 'cover', // Stretch image to fill container
    backgroundPosition: 'center', // Center image
    display: 'flex', // Enable flexbox for centering
    justifyContent: 'center', // Center content horizontally
    alignItems: 'center', // Center content vertically
  };

  const textStyle = {
    fontSize: '3em', // Adjust font size as needed
  };

  return (
    <div style={containerStyle}>
      <SeminarList/>
    </div>
  );
};

export default SeminarsLandingPageDetails;
