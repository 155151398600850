import React from "react";
import { TextField, Button } from "@mui/material";
import { toast } from 'react-toastify';

const ChapterForm = ({
  chapterName,
  setChapterName,
  chapterDescription,
  setChapterDescription,
  chapterVideo,
  setChapterVideo,
  chapterText,
  setChapterText,
  uploadedPdf,
  setUploadedPdf,
  metadataChapterChanged,
  setMetadataChapterChanged,
  metadataFileChanged,
  setMetadataFileChanged,
  isUpdateDisabled,
  setIsUpdateDisabled,
  handlePdfUpload,
}) => {
  return (
    <div>
      <h3>Add Chapter</h3>
      <TextField
        label="Chapter Name"
        value={chapterName}
        onChange={(e) => {
          setChapterName(e.target.value);
          setMetadataChapterChanged(true);
          setIsUpdateDisabled(false);
        }}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Chapter Description"
        value={chapterDescription}
        onChange={(e) => {
          setChapterDescription(e.target.value);
          setMetadataChapterChanged(true);
          setIsUpdateDisabled(false);
        }}
        fullWidth
        margin="normal"
        multiline
        rows={4}
        maxRows={10}
      />
      <TextField
        label="Chapter Video"
        value={chapterVideo}
        onChange={(e) => {
          setChapterVideo(e.target.value);
          setMetadataFileChanged(true);
          setIsUpdateDisabled(false);
        }}
        fullWidth
        margin="normal"
      />
      <input
        type="file"
        accept="application/pdf"
        style={{ display: "none" }}
        id="pdf-upload"
        onChange={handlePdfUpload}
      />
      <label htmlFor="pdf-upload">
        <Button
          variant="contained"
          component="span"
          fullWidth
          margin="normal"
        >
          {chapterText ? "Change PDF" : "Upload PDF"}
        </Button>
      </label>
    </div>
  );
};

export default ChapterForm;