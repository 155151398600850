import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formatPrice } from "../../../../services/util";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const Product = ({ product, history, currency }) => {
  const navigate = useNavigate();
  product.quantity = 1;

  let formattedPrice = formatPrice(product.price, product.currencyId);

  const goToProduct = (product) => {
    navigate({
      pathname: `/Categories/Product/${product.sku}`,
      state: { productClicked: product },
    });
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Card
        onClick={() => goToProduct(product)}
        data-sku={product.sku}
        style={{
          margin: "10px",
          cursor: "pointer",
          transition: "background-color 0.3s",
          width: "200px",
          height: "400px",
          overflow: "hidden",
        }}
        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "white")}
      >
        <CardContent>
          <img
            style={{
              width: "100%",
              height: "150px",
              objectFit: "contain",
              borderRadius: "10px",
            }}
            src={product.images[0]}
            alt={product.title}
          />
          <p
            style={{
              margin: "10px",
              fontSize: "18px",
              fontFamily: "fantasy",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {product.title}
          </p>
          <div style={{ color: "red" }}>-----</div>
          <div>
            {product.price === 0 ? (
              <Chip
                label="FREE !"
                style={{
                  fontWeight: "bold",
                  backgroundColor: "green",
                  color: "white",
                }}
              />
            ) : (
              <div className="val">
                <small>
                  $<span> </span>
                </small>
                <b>{formattedPrice} </b>
                per month
              </div>
            )}
          </div>
          {product.included_in_membership === 1 && (
            <div>Included in Membership</div>
          )}
          <Chip
            label={
              product.owner === "ghummantech@gmail.com" &&
              product.owner_name === "Ahmed Ghumman"
                ? "Ghumman Tech"
                : product.owner_name
            }
            variant="outlined"
            style={{
              backgroundColor:
                product.owner === "ghummantech@gmail.com" &&
                product.owner_name === "Ahmed Ghumman"
                  ? "#f80103"
                  : "black",
              color: "white",
              padding: "5px 10px", // Adjusted padding for pill shape
              marginTop: "10px",
              borderRadius: "20px", // Increased borderRadius for pill shape
            }}
          />
        </CardContent>
      </Card>
    </div>
  );
};

Product.propTypes = {
  product: PropTypes.object.isRequired,
};

export default connect(null, null)(Product);