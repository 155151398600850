import React, { useState, useEffect } from "react";
import QuizResult from "./QuizResult";
import QuestionCard from "./QuestionCardClick";
import QuizNavigation from "./QuizNavigation";

import { getBaseUrl } from "../Utils/getBaseUrl";
import { languages } from "./languages";

import { useMediaQuery } from "react-responsive";
import Spinner from "../Spinner";
import { replaceWithImages } from "../Utils/replaceWithImages";

const apiKey = "AIzaSyCIBPx5Ww6q7Cbuqca6EYoZmTTLeYiRJU4";

const Quiz = ({ quizData, chapterContent, isBookmarkedTab }) => {
  const isDesktopOrLarger = useMediaQuery({ minWidth: 768 });

  const course_language = chapterContent.course_language || "en";

  if (quizData.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        No bookmarks present.
      </div>
    );
  }

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [score, setScore] = useState(0);
  const [shuffledOptions, setShuffledOptions] = useState([]);
  const [userAnswers, setUserAnswers] = useState(
    Array(quizData.length).fill(null)
  ); // New state variable
  const [localQuestions, setLocalQuestions] = useState(quizData);
  const [disableBookmark, setDisableBookmark] = useState(false);
  const email = localStorage.getItem("email");
  const [isLoading, setIsLoading] = useState(false);

  const currentCard = quizData[currentQuestion];
  const question = replaceWithImages(
    currentCard.question,
    currentCard.attachments,
    isDesktopOrLarger
  );
  const answer = replaceWithImages(
    currentCard.answer,
    currentCard.attachments,
    isDesktopOrLarger
  );
  const option1 = replaceWithImages(
    currentCard.option1,
    currentCard.attachments,
    isDesktopOrLarger
  );
  const option2 = replaceWithImages(
    currentCard.option2,
    currentCard.attachments,
    isDesktopOrLarger
  );
  const option3 = replaceWithImages(
    currentCard.option3,
    currentCard.attachments,
    isDesktopOrLarger
  );

  useEffect(() => {
    if (quizData && Array.isArray(quizData) && quizData.length > 0) {
      const shuffled = shuffleOptions([
        {
          text: option1,
          language: quizData[currentQuestion]?.option1_language,
          origText: currentCard.option1,
        },
        {
          text: option2,
          language: quizData[currentQuestion]?.option2_language,
          origText: currentCard.option2,
        },
        {
          text: option3,
          language: quizData[currentQuestion]?.option3_language,
          origText: currentCard.option3,
        },
        {
          text: answer,
          language: quizData[currentQuestion]?.answer_language,
          origText: currentCard.answer,
        },
      ]);
      setShuffledOptions(shuffled);
    }
    setLocalQuestions(quizData);
  }, [quizData, currentQuestion, chapterContent]);

  useEffect(() => {
    setSelectedOption(userAnswers[currentQuestion]); // Reset selected option on question change
  }, [currentQuestion]);

  const handleOptionChange = (op) => {
    setSelectedOption(op.text);
    const updatedAnswers = [...userAnswers];
    updatedAnswers[currentQuestion] = op.origText;
    setUserAnswers(updatedAnswers);
  };

  const handleNextQuestion = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < quizData.length) {
      setCurrentQuestion(nextQuestion);
    }
  };

  const handlePreviousQuestion = () => {
    const prevQuestion = currentQuestion - 1;
    if (prevQuestion >= 0) {
      setCurrentQuestion(prevQuestion);
    }
  };

  const handleSubmitQuiz = () => {
    if (userAnswers.includes(null)) {
      alert("Please answer all the questions before submitting the quiz.");
    } else {
      setIsSubmitted(true);
      const correctAnswers = userAnswers.filter(
        (answer, index) => answer === quizData[index].answer
      );
      setScore(correctAnswers.length); // Calculate score based on user's answers
    }
  };

  const handleRestartQuiz = () => {
    setCurrentQuestion(0);
    setSelectedOption(null);
    setIsSubmitted(false);
    setScore(0);
    setUserAnswers(Array(quizData.length).fill(null)); // Reset user's answers
  };

  const handleUploadScore = async () => {
    try {
      setIsLoading(true);
      const baseUrl = getBaseUrl();
      const scorePercentage = (score / quizData.length) * 100;
      const dataRequest = {
        course_id: chapterContent.course_id,
        chapter_id: chapterContent.chapter_number,
        user_email: email,
        score: scorePercentage,
      };

      const response = await fetch(`${baseUrl}/api/upload-score`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      });

      if (!response.ok) {
        const responseData = await response.json();
        console.error("Error uploading score:", responseData);
      } else {
        alert("Score uploaded successfully!");
      }
    } catch (error) {
      console.error("Error uploading score:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const shuffleOptions = (array) => {
    let currentIndex = array.length,
      randomIndex;

    // While there are elements remaining to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  const handleBookmark = async () => {
    setDisableBookmark(true);
    // Update locally
    const newLocalQuestions = [...localQuestions];
    newLocalQuestions[currentQuestion].bookmarked =
      newLocalQuestions[currentQuestion].bookmarked === 0 ? 1 : 0;
    setLocalQuestions(newLocalQuestions);

    // Update on the server
    try {
      const baseUrl = getBaseUrl();
      const dataRequest = {
        quiz_question_id: quizData[currentQuestion].id,
        user_email: email,
      };
      const response = await fetch(`${baseUrl}/api/bookmark-question`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      });

      if (!response.ok) {
        const responseData = await response.json();
        console.error("Error setting up bookmark:", responseData);
      }
    } catch (error) {
      console.error("Error setting up bookmark:", error);
    }

    setDisableBookmark(false);
  };

  const handleSpeakUrdu = async (text) => {
    const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`;
    const data = {
      input: { text },
      voice: { languageCode: "ur-PK" }, // Ensure the correct language code and voice name
      audioConfig: { audioEncoding: "MP3" },
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      const audioContent = result.audioContent;
      const audio = new Audio(`data:audio/mp3;base64,${audioContent}`);
      audio.play();
    } catch (error) {
      console.error("Error with Google Text-to-Speech API:", error);
    }
  };

  const handleSpeak = async (text, isQuestion, textLang) => {
    const utterance = new SpeechSynthesisUtterance();
    utterance.text = text;
    if (
      quizData[currentQuestion].course_type === "live" &&
      textLang === "urdu"
    ) {
      await handleSpeakUrdu(text);
      return;
    } else {
      utterance.lang = isQuestion
        ? languages[course_language]
        : languages[localStorage.getItem("user-language")];
    }
    window.speechSynthesis.speak(utterance);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {isLoading && <Spinner />}
      <div style={{ marginBottom: "10px" }}>
        <QuizNavigation
          userAnswers={userAnswers}
          setCurrentQuestion={setCurrentQuestion}
          currentQuestion={currentQuestion}
          quizData={quizData}
          handlePreviousQuestion={handlePreviousQuestion}
          handleNextQuestion={handleNextQuestion}
          handleSubmitQuiz={handleSubmitQuiz}
          handleBookmark={handleBookmark}
          disableBookmark={disableBookmark}
        />

        {quizData && (
          <div style={{ marginTop: "10px" }}>
            {isSubmitted ? (
              <QuizResult
                score={score}
                quizData={quizData}
                userAnswers={userAnswers}
                handleRestartQuiz={handleRestartQuiz}
                handleUploadScore={handleUploadScore}
                isBookmarkedTab={isBookmarkedTab}
                isLoading={isLoading}
              />
            ) : (
              <QuestionCard
                question={question}
                quizData={quizData}
                currentQuestion={currentQuestion}
                shuffledOptions={shuffledOptions}
                selectedOption={selectedOption}
                handleSpeak={handleSpeak}
                handleOptionChange={handleOptionChange}
                isDesktopOrLarger={isDesktopOrLarger}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Quiz;
