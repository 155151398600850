import React, { useState, useEffect, useRef } from "react";
import Spinner from "../../Spinner";
import { initializeWebSocket, closeWebSocket } from "./WebsocketUtils";
import ParticipantsList from "./ParticipantsList";
import Quiz from "./Quiz/Quiz";
import ScoreCard from "./ScoreCard";

const Kahoot = ({ quizData, chapterContent, courseId, chapterNumber }) => {
  const [sessionPin, setSessionPin] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isHost, setIsHost] = useState(false);
  const [users, setUsers] = useState([]);
  const [host, setHost] = useState(null);
  const [questionToStart, setQuestionToStart] = useState(0);
  const [questionStatus, setQuestionStatus] = useState("stop");
  const totalQuestions = quizData.length;
  const socketRef = useRef(null);
  const [scoreCard, setScoreCard] = useState(null);
  const [currentQuestionScore, setCurrentQuestionScore] = useState(0);

  const websocketUrl = `wss://77g0u0607h.execute-api.us-east-2.amazonaws.com/production/?courseId=${courseId}&chapterNumber=${chapterNumber}`;
  const userId = parseInt(localStorage.getItem("user_id"), 10);

  const disconnectWebSocket = () => {
    ("disconnecting websocket");
    if (socketRef.current) {
      closeWebSocket(socketRef);
      socketRef.current = null;
      setSessionPin(null);
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      disconnectWebSocket();
    };

    return () => {
      handleVisibilityChange(); // Call handleVisibilityChange when the component unmounts
    };
  }, []);

  const startQuestion = () => {
    if (isHost) {
      const message = JSON.stringify({
        type: "start-question",
        course_id: courseId,
        chapter_number: chapterNumber,
        session_pin: sessionPin,
        question_number: questionToStart,
        kahoot_host_id: userId,
      });
      socketRef.current.send(message);
    }
  };

  const startSession = async () => {
    try {
      setIsLoading(true);
      setIsHost(true);

      initializeWebSocket(
        null,
        websocketUrl,
        socketRef,
        userId,
        true,
        setUsers,
        setHost,
        setSessionPin,
        setQuestionToStart,
        setQuestionStatus,
        setScoreCard,
        setCurrentQuestionScore
      );
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const joinSession = async (sessionPinToJoin) => {
    if (!sessionPinToJoin) return;
    try {
      setIsLoading(true);

      setSessionPin(sessionPinToJoin);
      setIsHost(false);

      initializeWebSocket(
        sessionPinToJoin,
        websocketUrl,
        socketRef,
        userId,
        false,
        setUsers,
        setHost,
        setSessionPin,
        setQuestionToStart,
        setQuestionStatus,
        setScoreCard,
        setCurrentQuestionScore
      );
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {sessionPin ? (
        isHost ? (
          <div>
            <h3>Session PIN: {sessionPin}</h3>
            <ParticipantsList users={users} host={host} />
            {questionStatus === "stop" && questionToStart < totalQuestions && (
              <div className="button-container">
                <button className="styled-button" onClick={startQuestion}>
                  Start Question
                </button>
              </div>
            )}
          </div>
        ) : null
      ) : (
        <div className="center-container">
          <h3>Start or Join a Kahoot Session</h3>
          <div className="button-container">
            <button className="styled-button" onClick={startSession}>
              Start New Kahoot Session
            </button>
            <button
              className="styled-button"
              onClick={() => {
                const sessionPinToJoin = prompt("Enter Session Pin:");
                if (sessionPinToJoin) joinSession(sessionPinToJoin);
              }}
            >
              Join Existing Kahoot Session
            </button>
          </div>
        </div>
      )}
      {questionToStart >= totalQuestions
        ? scoreCard && <ScoreCard scoreCard={scoreCard} />
        : questionStatus === "start" && (
            <div>
              <Quiz
                quizData={quizData}
                chapterContent={chapterContent}
                questionToStart={questionToStart}
                questionStatus={questionStatus}
                sessionPin={sessionPin}
                courseId={courseId}
                chapterNumber={chapterNumber}
                socketRef={socketRef}
                setQuestionStatus={setQuestionStatus}
                isHost={isHost}
                userId={userId}
                setCurrentQuestionScore={setCurrentQuestionScore}
              />
            </div>
          )}
      {sessionPin && !isHost && questionStatus === "stop" && (
        <div>
          <h3>Current Question Score: {currentQuestionScore}</h3>
        </div>
      )}
      {isLoading && <Spinner />}
    </div>
  );
};

export default Kahoot;
