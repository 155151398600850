import React, { useState, useRef } from 'react';
import { Element } from 'react-scroll';
import { TextField, Button, Box, Typography, Container } from '@mui/material';
import { getBaseUrl } from '../Utils/getBaseUrl';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import Spinner from '../Spinner';

const ServiceLinkElement = () => {
  const [customer, setCustomer] = useState({
    name: '',
    email: localStorage.getItem('email') || '',
    phoneNumber: '',
    company: '',
    projectDetails: '',
  });
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const recaptchaRef = useRef(null);

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCustomer({ ...customer, [name]: value });
  };

  const handleSubmitCustomerData = async (event) => {
    event.preventDefault();

    if (customer.projectDetails.length > 1000) {
      setError('Project Details should not exceed 1000 characters.');
      return;
    }

    setIsLoading(true);

    try {
      // Verify reCAPTCHA before submitting
      if (!captchaVerified) {
        await recaptchaRef.current.executeAsync();
      }
      const baseUrl = getBaseUrl();

      const dataRequest = {
        name: customer.name.trim(),
        email: customer.email.trim(),
        phone_number: customer.phoneNumber.trim(),
        company: customer.company.trim(),
        project_details: customer.projectDetails.trim(),
      };

      const response = await fetch(`${baseUrl}/api/register-service-request`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataRequest),
      });

      const data = await response.json();
      navigate('/Thankyou');
    } catch (error) {
      console.error('Error verifying reCAPTCHA:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Element name="contact-form" className="element">
      <Container maxWidth="sm">
        <Box textAlign="center" py={3}>
          <Typography variant="h4" component="p" gutterBottom>
            Contact Us
          </Typography>
        </Box>

        <Box component="form" onSubmit={handleSubmitCustomerData} sx={{ mt: 3 }}>
          <TextField
            fullWidth
            required
            label="Email"
            name="email"
            type="email"
            value={customer.email}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            required
            label="Phone Number"
            name="phoneNumber"
            type="tel"
            value={customer.phoneNumber}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            required
            label="Full Name"
            name="name"
            value={customer.name}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Company Name"
            name="company"
            value={customer.company}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            required
            label="Project Details"
            name="projectDetails"
            value={customer.projectDetails}
            onChange={handleChange}
            margin="normal"
            multiline
            rows={4}
            inputProps={{ maxLength: 1000 }}
          />

          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}

          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6Lf98EYpAAAAAI42RS-0yTOrmHG8bQs7n24Y39qc" // Replace with your reCAPTCHA site key
            size="invisible" // Optional: make reCAPTCHA invisible
            onChange={() => setCaptchaVerified(true)}
          />

          {isLoading && <Spinner />}

          <Box textAlign="center" mt={2}>
            <Button variant="contained" color="primary" type="submit" disabled={isLoading}>
              SEND
            </Button>
          </Box>
        </Box>
      </Container>
    </Element>
  );
};

export default ServiceLinkElement;