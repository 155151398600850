import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import './style.css';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
      backgroundColor: "#f8f9fa",
      padding: "20px 12px", // Increased padding to increase height
      borderRadius: "4px",
      border: "1px solid #ced4da",
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
    complete: {
      color: "#4caf50",
      iconColor: "#4caf50",
    },
  },
};

function CardSection() {
  return (
    <label>
      Card Details
      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </label>
  );
}

export default CardSection;