import React from 'react';
import Divider from "@mui/material/Divider";

const TotalSummary = ({ products, productFormatWithCurrencyTotal }) => {
  return (
    <div>
      <div>{products}</div>
      <div style={{ backgroundColor: "white" }}>
        <Divider style={{ borderTop: "3px solid black" }} />
        <div
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "1.5em",
            border: "1px solid black",
            padding: "10px",
            margin: "10px 0",
          }}
        >
          <div>TOTAL</div>
          <div>{productFormatWithCurrencyTotal}</div>
        </div>
      </div>
    </div>
  );
};

export default TotalSummary;