import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getBaseUrl } from '../../Utils/getBaseUrl';

const MembershipStatus = ({ setIsMember, setIsLoading, setUserHeatMap, setNotGtSubscriptions }) => {
  const navigate = useNavigate();

  const checkMembershipStatus = async (email) => {
    try {
      const baseUrl = getBaseUrl();
      const dataCheckSubscriptionStatus = { email: email };

      const responseCheckSubscriptionStatus = await fetch(`${baseUrl}/api/check-subscription-status`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataCheckSubscriptionStatus),
      });
      const dataSubscriptionStatus = await responseCheckSubscriptionStatus.json();
      if (dataSubscriptionStatus.message === 'active') {
        setIsMember(true);
      } else {
        setIsMember(false);
      }
    } catch (e) {
      console.error('Error checking membership details:', e);
    }
  };

  const checkMembershipStatusNotGt = async (email) => {
    try {
      const baseUrl = getBaseUrl();
      const dataCheckSubscriptionStatus = { email: email };
      const responseCheckSubscriptionStatus = await fetch(`${baseUrl}/api/check-subscription-status-not-gt`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataCheckSubscriptionStatus),
      });
      const dataSubscriptionStatus = await responseCheckSubscriptionStatus.json();
      setNotGtSubscriptions(dataSubscriptionStatus);
    } catch (e) {
      console.error('Error checking membership details:', e);
    }
  };

  const getMemberHeatMap = async (email) => {
    try {
      const baseUrl = getBaseUrl();
      const dataGetMemberHeatMap = { user_email: email };

      const responseGetMemberHeatMap = await fetch(`${baseUrl}/api/user-heat-map`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataGetMemberHeatMap),
      });
      if (responseGetMemberHeatMap.ok) {
        const dataHeatMap = await responseGetMemberHeatMap.json();
        setUserHeatMap(dataHeatMap);
      }
    } catch (e) {
      console.error('Error getting user heat map:', e);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const resultEmail = localStorage.getItem("email");
        if (resultEmail !== "") {
          await checkMembershipStatus(resultEmail);
          await checkMembershipStatusNotGt(resultEmail);
          await getMemberHeatMap(resultEmail);
        } else {
          navigate('/');
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return null;
};

export default MembershipStatus;