import React, { useState } from 'react';
import { Card, CardContent, Typography, TextField, Select, MenuItem, FormControl, InputLabel, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachmentIcon from '@mui/icons-material/Attachment';

const QuizCard = ({ quiz, index, handleInputChange, handleDeleteQuestion }) => {
  const [open, setOpen] = useState(false);
  const [attachmentIndex, setAttachmentIndex] = useState(null);
  const [attachmentValue, setAttachmentValue] = useState('');

  if (quiz.deleted) {
    return null;
  }

  const handleAttachmentClick = (index) => {
    setAttachmentIndex(index);
    setAttachmentValue(quiz.slideAttachments[index]);
    setOpen(true);
  };

  const handleAttachmentChange = (e) => {
    setAttachmentValue(e.target.value);
  };

  const handleAttachmentSave = () => {
    const updatedAttachments = [...quiz.slideAttachments];
    updatedAttachments[attachmentIndex] = attachmentValue;
    handleInputChange(index, 'slideAttachments', updatedAttachments);
    setOpen(false);
  };

  const renderAttachmentIcon = (value) => {
    const match = value.match(/\[attach(\d+)\]/);
    if (match) {
      const attachIndex = parseInt(match[1], 10) - 1;
      return (
        <IconButton color="primary" onClick={() => handleAttachmentClick(attachIndex)}>
          <AttachmentIcon />
        </IconButton>
      );
    }
    return null;
  };

  const handleFieldChange = (field, value) => {
    if (!quiz.slideAttachments) {
      quiz.slideAttachments = [];
    }
    const match = value.match(/\[attach(\d+)\]/);
    if (match) {
      const attachIndex = parseInt(match[1], 10) - 1;
      quiz.slideAttachments[attachIndex] = '';
    }
    handleInputChange(index, field, value);
  };

  return (
    <Card className="quiz-card">
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={11}>
            <Typography variant="h6" component="div">
              Question:
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={quiz.question}
              onChange={(e) => handleFieldChange('question', e.target.value)}
            />
          </Grid>
          <Grid item xs={1}>
            <Box display="flex" justifyContent="flex-end">
              {renderAttachmentIcon(quiz.question)}
            </Box>
          </Grid>
        </Grid>
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel>Language</InputLabel>
          <Select
            value={quiz.question_language}
            onChange={(e) => handleInputChange(index, 'question_language', e.target.value)}
            label="Language"
          >
            <MenuItem value="english">English</MenuItem>
            <MenuItem value="urdu">Urdu</MenuItem>
          </Select>
        </FormControl>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={11}>
            <Typography variant="h6" component="div">
              Answer:
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={quiz.answer}
              onChange={(e) => handleFieldChange('answer', e.target.value)}
            />
          </Grid>
          <Grid item xs={1}>
            <Box display="flex" justifyContent="flex-end">
              {renderAttachmentIcon(quiz.answer)}
            </Box>
          </Grid>
        </Grid>
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel>Language</InputLabel>
          <Select
            value={quiz.answer_language}
            onChange={(e) => handleInputChange(index, 'answer_language', e.target.value)}
            label="Language"
          >
            <MenuItem value="english">English</MenuItem>
            <MenuItem value="urdu">Urdu</MenuItem>
          </Select>
        </FormControl>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={11}>
            <Typography variant="h6" component="div">
              Option 1:
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={quiz.option1}
              onChange={(e) => handleFieldChange('option1', e.target.value)}
            />
          </Grid>
          <Grid item xs={1}>
            <Box display="flex" justifyContent="flex-end">
              {renderAttachmentIcon(quiz.option1)}
            </Box>
          </Grid>
        </Grid>
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel>Language</InputLabel>
          <Select
            value={quiz.option1_language}
            onChange={(e) => handleInputChange(index, 'option1_language', e.target.value)}
            label="Language"
          >
            <MenuItem value="english">English</MenuItem>
            <MenuItem value="urdu">Urdu</MenuItem>
          </Select>
        </FormControl>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={11}>
            <Typography variant="h6" component="div">
              Option 2:
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={quiz.option2}
              onChange={(e) => handleFieldChange('option2', e.target.value)}
            />
          </Grid>
          <Grid item xs={1}>
            <Box display="flex" justifyContent="flex-end">
              {renderAttachmentIcon(quiz.option2)}
            </Box>
          </Grid>
        </Grid>
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel>Language</InputLabel>
          <Select
            value={quiz.option2_language}
            onChange={(e) => handleInputChange(index, 'option2_language', e.target.value)}
            label="Language"
          >
            <MenuItem value="english">English</MenuItem>
            <MenuItem value="urdu">Urdu</MenuItem>
          </Select>
        </FormControl>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={11}>
            <Typography variant="h6" component="div">
              Option 3:
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={quiz.option3}
              onChange={(e) => handleFieldChange('option3', e.target.value)}
            />
          </Grid>
          <Grid item xs={1}>
            <Box display="flex" justifyContent="flex-end">
              {renderAttachmentIcon(quiz.option3)}
            </Box>
          </Grid>
        </Grid>
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel>Language</InputLabel>
          <Select
            value={quiz.option3_language}
            onChange={(e) => handleInputChange(index, 'option3_language', e.target.value)}
            label="Language"
          >
            <MenuItem value="english">English</MenuItem>
            <MenuItem value="urdu">Urdu</MenuItem>
          </Select>
        </FormControl>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <IconButton color="secondary" onClick={() => handleDeleteQuestion(index)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Attachment Url</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            value={attachmentValue}
            onChange={handleAttachmentChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAttachmentSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default QuizCard;