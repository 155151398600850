import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getBaseUrl } from '../Utils/getBaseUrl';

const baseUrl = getBaseUrl();

const useRegisterSeminar = () => {
    const navigate = useNavigate();

    const registerSeminar = async (dataRequestRegister, name) => {
        try {
            const response = await fetch(`${baseUrl}/api/register-seminar`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(dataRequestRegister),
            });

            if (response.status === 200) {
                // If response status is 200, push to /ThankyouSeminar
                navigate(
                    '/ThankyouSeminar',
                    {state: { name }}
                  );
            }

            return response;
        } catch (error) {
            // Handle error if needed
            console.error('Error registering seminar:', error);
            return null;
        }
    };

    return registerSeminar;
};

export default useRegisterSeminar;