import React from 'react';
import services from '../../assets/landingPageThreeCards/services.jpg';
import android6 from '../../assets/android6.png';
import android7 from '../../assets/android7.png';
import android8 from '../../assets/android8.png';
import android9 from '../../assets/android9.png';
import android10 from '../../assets/android10.png';
import { useMediaQuery } from 'react-responsive';

import { Link } from 'react-router-dom';

const ServicesLandingPageDetails = () => {

  const isDesktopOrLarger = useMediaQuery({ minWidth: 768 });
  const containerStyle = {
    width: '80vw',
    height: '60vh',
    backgroundImage: `url(${services})`,
    backgroundSize: 'cover', // Stretch image to fill container
    backgroundPosition: 'center', // Center image
    display: 'flex', // Enable flexbox for centering
    justifyContent: 'center', // Center content horizontally
    alignItems: 'center', // Center content vertically
  };

  const textStyle = {
    fontSize: '3em', // Adjust font size as needed
  };

  const labelTextStyles = {
    position: 'absolute', // Inside the red background
    transform: 'translateX(100%)', // Start outside the view from the right
    width: 'fit-content', // Allow text to adjust width
    color: 'red', // White text
    fontSize: '100%', // Update font size based on window size
    fontWeight: 'bold', // Make the text bold
    fontFamily: 'Arial, sans-serif', // Change the font
    zIndex: 100
  };

  
  return (
    <div style={containerStyle}>
      <div
        style={{
          position: 'relative',
          width: '100vw',
          paddingBottom: isDesktopOrLarger ? '30vw' : '100vw',
          paddingTop: isDesktopOrLarger ? '0vw' : '10vw',
          overflow: 'hidden',
        }}
      >
        <div className="moveLabel" style={labelTextStyles}>

          <div style={{ padding: '2px' }}>
            <Link to={'/FreeAppForTeachers'}>
              <img src={android6} style={{ width: '60px', height: '60px', borderRadius: '10px' }} />
            </Link>
          </div>
          <div style={{ padding: '2px' }}>
            <Link to={'/FreeAppForTeachers'}>
              <img src={android7} style={{ width: '60px', height: '60px', borderRadius: '10px' }} />
            </Link>
          </div>          
          <div style={{ padding: '2px' }}>
            <Link to={'/FreeAppForTeachers'}>
              <img src={android8} style={{ width: '60px', height: '60px', borderRadius: '10px' }} />
            </Link>
          </div>
          <div style={{ padding: '2px' }}>
            <Link to={'/FreeAppForTeachers'}>
              <img src={android9} style={{ width: '60px', height: '60px', borderRadius: '10px' }} />
            </Link>
          </div>
          <div style={{ padding: '2px' }}>
            <Link to={'/FreeAppForTeachers'}>
              <img src={android10} style={{ width: '60px', height: '60px', borderRadius: '10px' }} />
            </Link>
          </div>        </div>
      </div>
    </div>
  );
};

export default ServicesLandingPageDetails;
