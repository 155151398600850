import React from 'react';

const ContactInformation = ({ formData, handleChange, errors }) => (
    <div className='userInfoSection'>
        <div>
            <label htmlFor="email">Email:</label>
            <input
                className="InputUserInfo"
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
            />
            {errors.email && <p className="error">{errors.email}</p>}
        </div>
        <div>
            <label htmlFor="name">Name:</label>
            <input
                className="InputUserInfo"
                style={{ backgroundColor: 'white' }}
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
            />
            {errors.name && <p className="error">{errors.name}</p>}
        </div>
        <div>
            <label htmlFor="phone">Phone:</label>
            <input
                className="InputUserInfo"
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
            />
            {errors.phone && <p className="error">{errors.phone}</p>}
        </div>
    </div>
);

export default ContactInformation;