import React from "react";
import "./ParticipantsList.css"; // Import the CSS file

const ParticipantsList = ({
  users,
  isHost,
  userId,
  socketRef,
  sessionId,
  courseId,
  chapterNumber,
}) => {
  const enableDrawingForUser = (userId, drawingEnabled) => {
    socketRef.current.send(
      JSON.stringify({
        type: "updateUsers",
        user_id: userId,
        session_id: sessionId,
        course_id: courseId,
        chapter_number: chapterNumber,
        is_host: isHost,
        drawing_enabled: drawingEnabled,
      })
    );
  };

  return (
    <div style={{ marginLeft: "10px" }}>
      <h4>Participants:</h4>
      <ul className="participants-list">
        {users.map((user) => (
          <li key={user.user_id} className="participant-item">
            {user.name} - Drawing{" "}
            <input
              type="checkbox"
              checked={user.is_drawing_enabled}
              onChange={(e) =>
                enableDrawingForUser(user.user_id, e.target.checked)
              }
              disabled={!isHost || user.user_id === userId}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ParticipantsList;
