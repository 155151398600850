import React, { useState } from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { getBaseUrl } from '../Utils/getBaseUrl';
import { useNavigate } from "react-router-dom";

import FloatCart from '../FloatCart';
import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';

import { toast } from 'react-toastify';

const PASSWORD = "password";
const LOGIN_TYPE = "login_type";
const ACCOUNT_TYPE = "account_type";
const EMAIL = "email";

const Register = (props) => {
  const navigate = useNavigate();

  const [formFirstName, setFormFirstName] = useState('');
  const [formLastName, setFormLastName] = useState('');
  const [formPassword, setFormPassword] = useState('');
  const [formPassword2, setFormPassword2] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);


  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };

  const signMeIn = () => {
    navigate('/Login');
  };

  const isMenuOpen = (state) => {
    if (state.isOpen === false) {
      setMenuHidden(true);
    } else {
      setMenuHidden(false);
    }
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };

  const handleChangeFirstName = (event) => {
    setFormFirstName(event.target.value);
  };

  const handleChangeLastName = (event) => {
    setFormLastName(event.target.value);
  };

  const handleChangeEmail = (event) => {
    setFormEmail(event.target.value);
  };

  const handleChangePassword = (event) => {
    setFormPassword(event.target.value);
  };

  const handleChangePassword2 = (event) => {
    setFormPassword2(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate all required fields
    const requiredFields = [formFirstName, formLastName, formEmail, formPassword, formPassword2];
    const emptyFields = requiredFields.filter((field) => !field.trim());
    if (emptyFields.length) {
      toast.error("Please fill in all required fields.");
      setErrorMessage("Please fill in all required fields.")
      return;
    }

    // Validate password match
    if (formPassword !== formPassword2) {
      toast.error("Passwords must match.");
      setErrorMessage("Passwords must match.")
      return;
    }

    // Prepare data for request
    const dataRequest = {
      first_name: formFirstName.trim(),
      last_name: formLastName.trim(),
      email: formEmail.trim(),
      password: formPassword.trim(),
    };

    // Send API request
    try {
      const response = await fetch(`${getBaseUrl()}/api/register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      });

      const data = await response.json();
      console.log(data);

      // Handle successful registration
      if (response.ok && data.message === "Registration successful. Please check your email to verify your account.") {
        toast.success("Registration successful. Please check your email to verify your account.");
        setErrorMessage(data.message)

        localStorage.setItem(EMAIL, dataRequest.email);
        props.login(dataRequest.email, dataRequest.password);
        navigate({ pathname: "/" });
        return
      }

      // Handle unsuccessful registration
      toast.error(data.error || "An error occurred. Please try again.");
      setErrorMessage(data.error);
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again.");
      setErrorMessage(error.message);
    }
  };

  const responseFacebook = (response) => {
    const fullName = response.name.split(" ");

    // Signup this person if email is not empty
    if (response.email !== "") {
      fetch(
        "https://stealthdocumentation.com/dourbeen_api_pdo.php?apicall=loginSpecial",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          body:
            "loginType=special" +
            "&firstName=" +
            fullName[0] +
            "&lastName=" +
            fullName[fullName.length - 1] +
            "&email=" +
            response.email
        }
      ).then(async function (data) {
        data.json().then(async function (data) {
          if (data.message === "Good to go new") {
            props.setErrorMessage("Logging in using Facebook.");

            localStorage.setItem("ACCOUNT_TYPE", "free");
            localStorage.setItem("LOGIN_TYPE", "special");
            localStorage.setItem("EMAIL", response.email);
            localStorage.setItem("PASSWORD", "facebookPassword");

            props.login(
              fullName[0] + " " + fullName[fullName.length - 1],
              "facebookPassword"
            );

            navigate({
              pathname: "/"
            });
          } else if (data.message === "Good to go old") {
            props.setErrorMessage("Logging in using Facebook.");

            localStorage.setItem("LOGIN_TYPE", "special");
            localStorage.setItem("EMAIL", response.email);
            localStorage.setItem("PASSWORD", "facebookPassword");

            props.login(
              fullName[0] + " " + fullName[fullName.length - 1],
              "facebookPassword"
            );

            navigate({
              pathname: "/"
            });
          } else if (data.message === "Regular account exists") {
            props.setErrorMessage(
              "Unable to login using Facebook. You already have an account with us. Please use email and password to login."
            );
          } else {
            props.setErrorMessage(data.message);
          }
        });
      });
    } else {
      props.setErrorMessage(
        "Unable to login using Facebook. Facebook did not provide email address"
      );
    }
  };

  // var errorMessageJsx;
  // if (errorMessage.trim() == "") errorMessageJsx = null;
  // else
  //   errorMessageJsx = (
  //     <p style={{ color: "red" }}>{errorMessage.trim()}</p>
  //   );

  return (
    <div id="outer-container">
      <HamburgerMenu
        isMenuOpenFunc={isMenuOpen}
        pageWrapId="page-wrap"
        outerContainerId="outer-container"
        isOpenStatus={menuHidden}
        history={props.history}
        callbackClose={callbackRoutingCloseMenu}
      />
      <div id="page-wrap">
        <Header
          openMenu={openMenuWindow}
          openCart={openFloatCart}
          signIn={signMeIn}
          history={props.history}
        />
        <div className="App">
          <header className="App-header">
            <p style={{ textDecorationLine: 'underline' }}> Welcome to Ghumman Tech</p>
            <p style={{ paddingLeft: '10px', paddingRight: '10px' }}>We are glad you are going to become Ghumman Tech User</p>
            {/* {errorMessageJsx} */}

            <p
              style={{
                borderStyle: "solid",
                borderColor: "black",
                borderRadius: 5,
                padding: 5
              }}
            >
              Please Register
            </p>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="loginFormFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="username"
                  value={formFirstName}
                  placeholder="Enter First Name"
                  onChange={handleChangeFirstName}
                />
              </Form.Group>

              <Form.Group controlId="loginFormLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="username"
                  value={formLastName}
                  placeholder="Enter Last Name"
                  onChange={handleChangeLastName}
                />
              </Form.Group>
              <Form.Group controlId="loginFormEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={formEmail}
                  placeholder="Enter Email"
                  onChange={handleChangeEmail}
                />
              </Form.Group>

              <Form.Group controlId="loginFormPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={formPassword}
                  placeholder="Password"
                  onChange={handleChangePassword}
                />
              </Form.Group>

              <Form.Group controlId="loginFormPassword2">
                <Form.Label>Password (again)</Form.Label>
                <Form.Control
                  type="password"
                  value={formPassword2}
                  placeholder="Password"
                  onChange={handleChangePassword2}
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Sign In
              </Button>
              <Link to="/" className="btn btn-link">
                Cancel
              </Link>
            </Form>
            <br />
            <br />
          </header>
        </div>
        <FloatCart isTheCartOpen={isOpen} history={props.history} />
        <Footer history={props.history} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => (
  {
    authenticate: state
  })

const mapDispatchToProps = (dispatch) => ({
  login: (email, password) =>
    dispatch({ type: "UPDATE", email: email, password: password }),
  signout: () => dispatch({ type: 'LOGOUT' }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
