export const initializeWebSocket = (
  sessionId,
  websocketUrl,
  socketRef,
  userId,
  isHost,
  setUsers,
  canvasRef,
  setIsDrawingEnabled
) => {
  let updatedWebsocketUrl;
  if (isHost) {
    updatedWebsocketUrl = `${websocketUrl}&sessionId=${sessionId}&hostId=${userId}`;
  } else {
    updatedWebsocketUrl = `${websocketUrl}&sessionId=${sessionId}&userId=${userId}`;
  }

  const socket = new WebSocket(updatedWebsocketUrl);
  socketRef.current = socket;

  // Extend the WebSocket instance to include closeCode and closeReason properties
  socket.closeCode = 1000; // Default close code
  socket.closeReason = "Drawing Closure"; // Default close reason

  // Function to set the close code and reason
  socket.setCloseDetails = (code, reason) => {
    socket.closeCode = code;
    socket.closeReason = reason;
  };

  socket.onopen = () => {};

  socket.onmessage = (event) => {
    handleWebSocketMessage(
      event,
      setUsers,
      canvasRef,
      setIsDrawingEnabled,
      userId
    );
  };

  socket.onclose = (event) => {};

  socket.onerror = (error) => {
    console.error("WebSocket error:", error);
  };
};

// Function to close the WebSocket using the predefined code and reason
export const closeWebSocket = (socketRef) => {
  if (socketRef.current) {
    socketRef.current.close(
      socketRef.current.closeCode,
      socketRef.current.closeReason
    );
  }
};

export const handleWebSocketMessage = (
  event,
  setUsers,
  canvasRef,
  setIsDrawingEnabled,
  userId
) => {
  let message;
  if (typeof event.data === "string") {
    try {
      message = JSON.parse(event.data);
    } catch (error) {
      return;
    }
  } else {
    message = event.data;
  }

  if (message.type === "updateUsers") {
    setUsers(message.participants);

    message.participants.forEach((participant) => {
      if (participant.user_id === userId) {
        setIsDrawingEnabled(participant.is_drawing_enabled);
      }
    });
  } else if (message.type === "drawing") {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    context.strokeStyle = message.drawing.color;
    context.lineWidth = message.drawing.lineWidth;

    context.beginPath();
    context.moveTo(message.drawing.startX, message.drawing.startY);
    context.lineTo(message.drawing.endX, message.drawing.endY);
    context.stroke();
  }
};
