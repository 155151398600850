import React from 'react';
import { useNavigate } from 'react-router-dom';




const CreateCoursesAd = ({setShowSignInPopup}) => {
  const navigate = useNavigate();

  const navigateUser = () => {
    const userEmail = localStorage.getItem("email") || "";
    if (!userEmail) {
      setShowSignInPopup(true);
    } else {
      navigate('/MyAccount/Teaching');
    }
  }


  return (
    <div className="image-screen" onClick={navigateUser}>
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '0',
          paddingBottom: '56.25%',
          overflow: 'hidden',
        }}
      >
        <img
          src="https://ghummantech-website.s3.us-east-2.amazonaws.com/CreateCourses.gif"
          alt="Friday Programming Class"
          style={{ width: '100%', maxWidth: '100%', boxSizing: 'border-box' }}
        />
      </div>
    </div>
  );
};

export default CreateCoursesAd;
