import React from 'react';

const IqbalDemystifiedDeleteAccount = () => {
  return (
    <div id="outer-container">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">

            <div style={{
              textAlign: "center", fontWeight: "400", margin: "0 0 0.5em",
              lineHeight: "1.4", fontFamily: "auto", fontSize: "40px"
            }}>
              Account Deletion - Iqbal Demystified
            </div>

            <div style={{
              fontWeight: "400", margin: "0 0 0.5em",
              lineHeight: "1.4", fontFamily: "auto", fontSize: "20px"
            }}>
              <p>If you wish to delete your account on Iqbal Demystified, please follow the steps below:</p>

              <h4>Steps to Delete Your Account</h4>
              <p>Send an email to <a href="mailto:admin@ghummantech.com">admin@ghummantech.com</a> with the title and subject stating that you want your name, username, email, and password deleted from our database.</p>

              <h4>Types of Data Deleted</h4>
              <p>Once your request is processed, the following data will be deleted from our user database:</p>
              <ul>
                <li>Your email</li>
                <li>Your name</li>
                <li>Your username</li>
              </ul>

              <h4>Data Retention</h4>
              <p>All specified data will be deleted promptly upon receiving your request. There is no additional retention period for this data once deletion is requested.</p>

              <p>For any questions or further assistance, please contact us at <a href="mailto:admin@ghummantech.com">admin@ghummantech.com</a>.</p>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default IqbalDemystifiedDeleteAccount;
