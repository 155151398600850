import React, { useState, useEffect, useRef } from 'react';


import FloatCart from '../FloatCart';
import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';


import { connect } from 'react-redux';
import './style.css';

import { getBaseUrl } from '../Utils/getBaseUrl';

/*
Without the magic of pdfjs, pdf rendering works locally but not in production. 
*/
import { Document, Page, pdfjs } from "react-pdf";
// pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';


const ReadBook = (props) => {

  const classes = useStyles();
  const containerRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();


  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [canRead, setCanRead] = useState(false);
  const [bookUrl, setBookUrl] = useState(null);
  const [containerWidth, setContainerWidth] = useState(175);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () => {
    setPageNumber(prevPageNumber => prevPageNumber - 1);
  };

  const goToNextPage = () => {
    setPageNumber(prevPageNumber => prevPageNumber + 1);
  };



  const signMeIn = () => {
    navigate('/Login');
  };

  useEffect(() => {
    if (!menuHidden) {
      document.body.setAttribute("style", "overflow: hidden; position: static;");
    } else {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    }
    // Cleanup function to reset the body style when the component unmounts or when menuHidden changes again
    return () => {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    };
  }, [menuHidden]);

  const checkIfEligible = async (email, productSku) => {
    try {
      const baseUrl = getBaseUrl();
      const dataRequest = {
        email: email,
        product_sku: productSku,
      };
      const response = await fetch(`${baseUrl}/api/check-book-eligibility`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataRequest),
      });

      if (response.ok) {
        const responseData = await response.json();
        setCanRead(responseData.isEligible);
        if (responseData.isEligible) {
          await fetchBookUrl(productSku);
        }
      } else {
        setCanRead(false);
      }
    } catch (e) {
      setCanRead(false);
    }
  };

  const checkSubscriptionStatus = async (email, productSku) => {
    try {
      const baseUrl = getBaseUrl();
      const dataRequest = {
        email: email,
      };
      const response = await fetch(`${baseUrl}/api/check-subscription-status`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataRequest),
      });

      if (response.ok) {
        const responseData = await response.json();
        setCanRead(responseData.message === 'active');
        if (responseData.message === 'active') {
          await fetchBookUrl(productSku);
          return true;
        }
        return false;
      } else {
        setCanRead(false);
        return false;
      }
    } catch (e) {
      setCanRead(false);
      return false;
    }
  };

  const fetchBookUrl = async (productSku) => {
    try {
      const baseUrl = getBaseUrl();
      const response = await fetch(`${baseUrl}/api/book-url`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ pdfFileName: `books/${productSku}.pdf` }),
      });

      if (response.ok) {
        const responseData = await response.json();
        setBookUrl(responseData.presignedUrl);
      }
    } catch (error) {
      console.error('Error fetching book URL:', error);
    }
  };

  useEffect(() => {
    const resultEmail = localStorage.getItem("email");

    const checkEligibility = async () => {
      if (location.state) {
        const { sku } = location.state;
        setPageNumber(1);
        const isMember = checkSubscriptionStatus(resultEmail, sku);
        if (!isMember) {
          await checkIfEligible(resultEmail, sku);
        }
      }
    };

    checkEligibility();
  }, [location.state]);

  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };

  const isMenuOpen = (state) => {
    setMenuHidden(!state.isOpen);
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };

  const handleChangePageNumber = (event) => {
    let inputPageNumber = parseInt(event.target.value);
    if (inputPageNumber >= 1 && inputPageNumber <= numPages) {
      setPageNumber(inputPageNumber);
    } else {
      setPageNumber(1);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.getBoundingClientRect().width - 200); // Subtract 200 for extra space
      }
    };

    // Initial width calculation when component mounts
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

    if (containerRef.current) {
      setContainerWidth(containerRef.current.getBoundingClientRect().width - 200); // Subtract 200 for extra space
    }
  }, [containerRef.current]);



  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: false,
    onChange: (oldIndex, newIndex) => {
    }
  }

  const zoomOutProperties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    scale: 0.4,
    arrows: true
  }

  return (
    <div id="outer-container">


      <HamburgerMenu isMenuOpenFunc={isMenuOpen} pageWrapId="page-wrap" outerContainerId="outer-container" isOpenStatus={menuHidden} history={props.history} callbackClose={callbackRoutingCloseMenu} />
      <div id="page-wrap">
        <Header openMenu={openMenuWindow} openCart={openFloatCart} signIn={signMeIn} history={props.history} />
        {canRead && <div>
          <div style={{ textAlign: "center" }}>
            <nav className={classes.navContainer}>
              <IconButton
                disabled={pageNumber < 2}
                onClick={goToPrevPage}
                className={classes.circleButton}
                classes={{ hover: classes.circleButtonHover }}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <span className={classes.pageNumberText}>Page</span>
              <TextField
                id="outlined-number"
                type="number"
                size="small"
                onChange={handleChangePageNumber}
                value={pageNumber}
                InputProps={{
                  className: classes.pageInput,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <span className={classes.numPagesText}>of {numPages}</span>
              <IconButton
                disabled={pageNumber >= numPages}
                onClick={goToNextPage}
                className={classes.circleButton}
                classes={{ hover: classes.circleButtonHover }}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </nav>
          </div>
          <div ref={containerRef}>
            <Document
              file={bookUrl}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <IconButton
                    disabled={pageNumber < 2}
                    onClick={goToPrevPage}
                    className={classes.circleButton}
                    classes={{ hover: classes.circleButtonHover }}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                </div>
                <div style={{ flex: 5, overflowX: 'auto' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Page
                      pageNumber={pageNumber}
                      width={containerWidth}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      customTextRenderer={false}
                    />
                  </div>
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <IconButton
                    disabled={pageNumber >= numPages}
                    onClick={goToNextPage}
                    className={classes.circleButton}
                    classes={{ hover: classes.circleButtonHover }}
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </div>
              </div>
            </Document>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton onClick={() => { setContainerWidth(prevWidth => prevWidth + 20) }} aria-label="Zoom In">
              <ZoomInIcon />
            </IconButton>
            <IconButton onClick={() => { setContainerWidth(prevWidth => prevWidth - 20) }} aria-label="Zoom Out">
              <ZoomOutIcon />
            </IconButton>
          </div>

        </div>}

        {!canRead && <div style={{ textAlign: "center", margin: '10px' }}>
          You don't have privileges to read this book. Please Log in and make sure you've purchased the book.
        </div>}

        <FloatCart isTheCartOpen={isOpen} history={props.history} />
        <Footer history={props.history} />


      </div>
    </div>
  );

}

const useStyles = makeStyles((theme) => ({
  circleButton: {
    backgroundColor: '#4CAF50',
    borderRadius: '50%', // Make it circular
    color: '#ffffff', // Text color
    '&:hover': {
      backgroundColor: '#66BB6A', // Change color on hover
    },
  },
  navContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '5px',
    padding: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  pageNumberText: {
    marginLeft: '10px',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginRight: '10px',
    color: '#333',
  },
  pageInput: {
    width: '60px',
    marginRight: '10px',
    '& input': {
      textAlign: 'center',
    },
  },
  numPagesText: {
    marginRight: '10px',
    fontSize: '1rem',
    color: '#555',
  },
  scrollableContainer: {
    flex: '5',
    overflow: 'auto', // Enable vertical scrolling
  },
}));

const mapStateToProps = (state) => (
  {
    authenticate: state.authenticate,
    cartProducts: state.cart.products,
    newProduct: state.cart.productToAdd,
    productToRemove: state.cart.productToRemove,
    productToChange: state.cart.productToChange,
    cartTotal: state.total.data
  })

const mapDispatchToProps = (dispatch) => ({
  login: (username, password) =>
    dispatch({ type: 'UPDATE', username: username, password: password }),
  signout: () => dispatch({ type: 'LOGOUT' }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReadBook);
