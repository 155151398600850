import React from 'react';
import { FaEnvelope, FaPhoneAlt, FaMapMarkerAlt, FaYoutube, FaFacebook, FaInstagram, FaPinterest, FaLinkedin } from 'react-icons/fa';
import { FaSquareXTwitter } from "react-icons/fa6";
import { Container, Grid, Typography, Card, CardContent, Link, IconButton, Grow, Box } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import ServiceLinkElement from '../SoftwareServices/ServiceLinkElement';

const ContactPage = () => {
    const LATITUDE = 29.552315;
    const LONGITUDE = -95.236097;

    const socialMediaItems = [
        { icon: <FaYoutube style={{ color: 'red', fontSize: '2rem' }} />, link: "https://www.youtube.com/@ghummantech", message: "Subscribe Us on Youtube" },
        { icon: <FaFacebook style={{ color: 'blue', fontSize: '2rem' }} />, link: "https://www.facebook.com/ghummantech", message: "Follow Us on Facebook" },
        { icon: <FaInstagram style={{ color: 'purple', fontSize: '2rem' }} />, link: "https://www.instagram.com/ghummantech/", message: "Follow Us on Instagram" },
        { icon: <FaSquareXTwitter style={{ color: 'black', fontSize: '2rem' }} />, link: "https://twitter.com/ghummantech", message: "Follow Us on Twitter" },
        { icon: <FaPinterest style={{ color: 'red', fontSize: '2rem' }} />, link: "https://www.pinterest.com/ghummantech/", message: "Follow Us on Pinterest" },
        { icon: <FaLinkedin style={{ color: 'blue', fontSize: '2rem' }} />, link: "https://www.linkedin.com/company/ghummantech", message: "Connect with Us on LinkedIn" }
    ];

    return (
        <Container maxWidth="md" className="contact-page" sx={{ mt: 4 }}>
            <Typography variant="h3" component="h1" gutterBottom align="center" style={{ fontSize: '2rem' }}>
                Get in Touch
            </Typography>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                    <Grow in timeout={1000}>
                        <Card sx={{ height: '100%' }}>
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <IconButton>
                                    <FaEnvelope className="icon" style={{ fontSize: '2rem' }} />
                                </IconButton>
                                <Typography variant="body1" align="center">
                                    Email: <Link href="mailto:admin@ghummantech.com">admin@ghummantech.com</Link>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grow>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Grow in timeout={1500}>
                        <Card sx={{ height: '100%' }}>
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <IconButton>
                                    <FaPhoneAlt className="icon" style={{ fontSize: '2rem' }} />
                                </IconButton>
                                <Typography variant="body1" align="center">
                                    Phone: <Link href="tel:14194058850">419-405-8850</Link>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grow>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Grow in timeout={2000}>
                        <Card sx={{ height: '100%' }}>
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <IconButton>
                                    <FaMapMarkerAlt className="icon" style={{ fontSize: '2rem' }} />
                                </IconButton>
                                <Typography variant="body1" align="center">
                                    Address: Houston, TX, 77375
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grow>
                </Grid>
            </Grid>
            <Grid container spacing={4} sx={{ mt: 4 }} justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Card sx={{ height: '100%', borderRadius: '16px' }}>
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <section className="social-media">
                                    <Typography variant="h4" component="h2" gutterBottom align="center">
                                        Follow Us
                                    </Typography>
                                    <Carousel>
                                        {socialMediaItems.map((item, index) => (
                                            <Grid container spacing={2} justifyContent="center" key={index}>
                                                <Grid item>
                                                    <Link href={item.link} target="_blank" rel="noopener noreferrer">
                                                        <IconButton>
                                                            {item.icon}
                                                        </IconButton>
                                                    </Link>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1" align="center">
                                                        {item.message}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Carousel>
                                </section>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card sx={{ height: '100%', borderRadius: '16px' }}>
                        <CardContent>
                            <ServiceLinkElement />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ContactPage;