import { FETCH_PRODUCTS, FETCH_PRODUCT } from './actionTypes';
import { getBaseUrl } from '../../components/Utils/getBaseUrl';

export const fetchProducts = (filters, filtersCategory, sortBy='latest', newFilters, callback, page = 1, pageSize = 10, search = '') => async dispatch => {
  try {
    const baseUrl = getBaseUrl();

    // Ensure pageSize is defined and has a valid value
    if (typeof pageSize === 'undefined' || pageSize === null) {
      throw new Error('pageSize is not defined');
    }

    // Ensure sortBy defaults to 'latest' if it is an empty string
    if (!sortBy) {
      sortBy = 'latest';
    }

    const dataRequest = {
      page, // Use the page parameter
      pageSize, // Use the pageSize parameter
      search, // Use the search parameter
      category: filters && filters.category && filters.category.length === 1 ? filters.category[0] : '', // Pass filtersCategory if available, otherwise, empty string
      sortBy, // Include sortBy parameter
      newFilters // Include newFilters parameter
    };

    const response = await fetch(`${baseUrl}/api/products`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dataRequest),
    });

    const data = await response.json();

    let { products, total, currentPage, totalPages } = data;

    try {
      if (!!filters && filters.hasOwnProperty('sku')) {
        products = products.filter(p => p.sku === filters.sku);
      }
    } catch (e) {}

    if (!!callback) {
      callback();
    }

    dispatch({
      type: FETCH_PRODUCTS,
      payload: { products, total, currentPage, pageSize, totalPages },
    });
  } catch (error) {
    console.log('Error fetching products:', error);
    // Handle error
  }
};

export const fetchProduct = (sku) => async dispatch => {
  try {
    const baseUrl = getBaseUrl();
    const dataRequest = {
      sku: sku
    };

    const response = await fetch(`${baseUrl}/api/product`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dataRequest),
    });

    const data = await response.json();
    dispatch({
      type: FETCH_PRODUCT,
      payload: [data],
    });
  } catch (error) {
    // Handle error
  }
};