import React from "react";
import { Modal, Backdrop, Fade, Box, Typography, Button } from '@mui/material';
import Spinner from "../../Spinner";
import { modalStyle } from './styles';

const CancelSubscriptionModal = ({ openCancelSubscriptionModal, setOpenCancelSubscriptionModal, cancelSubscription, isCancelLoading }) => {
  return (
    <Modal
      open={openCancelSubscriptionModal}
      onClose={() => setOpenCancelSubscriptionModal(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openCancelSubscriptionModal}>
        <Box sx={{ ...modalStyle }}>
          <Typography variant="h6" component="h2">
            Are you sure you want to cancel your subscription?
          </Typography>
          <Box mt={2} display="flex" justifyContent="space-between">
            {isCancelLoading && <Spinner />}
            <Button variant="contained" color="secondary" onClick={cancelSubscription} disabled={isCancelLoading}>
              Yes
            </Button>
            <Button variant="contained" color="primary" onClick={() => setOpenCancelSubscriptionModal(false)} disabled={isCancelLoading}>
              No
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CancelSubscriptionModal;