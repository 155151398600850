import React, { useState, useEffect } from 'react';
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import { getBaseUrl } from '../Utils/getBaseUrl';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useRegisterMember from './useRegisterMember';
import ContactInformation from './ContactInformation';
import PaymentSection from './PaymentSection';
import SubscriptionDetails from './SubscriptionDetails';
import { handleChange } from './utils';
import Spinner from '../Spinner';

const CheckoutForm = (props) => {
    const registerMember = useRegisterMember();
    const { stripe, elements, seminar, planType } = props;
    const [formData, setFormData] = useState({
        email: localStorage.getItem('email') || '',
        name: '',
        phone: ''
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [currentAmount, setCurrentAmount] = useState(seminar.price);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isCardComplete, setIsCardComplete] = useState(false);

    const baseUrl = getBaseUrl();

    useEffect(() => {
        const isFormFilled = formData.email && formData.name && formData.phone;
        setIsFormValid(isFormFilled && isCardComplete);
    }, [formData, isCardComplete]);

    const handleCardChange = (event) => {
        setIsCardComplete(event.complete);
    };

    const registerMemberAfterSuccessfulSubscription = async ( paymentMethodId) => {
        const dataRequestRegister = {
            email: formData.email,
            name: formData.name,
            phone_number: formData.phone,
            payment_method_id: paymentMethodId,
            has_had_trial: 1,
            subscription_status: 'active',
        };
        toast.success('Payment Successful. Registering you as a member...');
        const responseRegister = await registerMember(dataRequestRegister, formData.name);
        // responseRegister should not be returned. 
        // as at this point payment has been collected
        // and registerMember is persiting the entry in DB
        // registerMember is supposed to send user to ThankyouSeminar page
        if (responseRegister.status === 500) {
            toast.error('An error occurred during the payment process. Please try again.');
        }
    }

    const handlePayment = async (event) => {
        try {
            setIsLoading(true);
            event.preventDefault();
            const validationErrors = {};
            if (!formData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
                validationErrors.email = 'Please enter a valid email address.';
            }
            if (!formData.name) {
                validationErrors.name = 'Please enter your name.';
            }
            if (!formData.phone) {
                validationErrors.phone = 'Please enter your phone number.';
            }
            setErrors(validationErrors);
            if (Object.keys(validationErrors).length === 0) {
                const dataRequestCheckSubscriptionStatus = { email: formData.email };
                const responseDataRequestCheckSubscriptionStatus = await fetch(`${baseUrl}/api/check-subscription-status`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(dataRequestCheckSubscriptionStatus),
                });
                const dataSubscriptionStatus = await responseDataRequestCheckSubscriptionStatus.json();
                if (dataSubscriptionStatus.message === 'active') {
                    toast.success('You are already a member');
                    return;
                }
                if (currentAmount <= 0) {
                    await registerMemberAfterSuccessfulSubscription();
                    return;
                }
                if (!stripe || !elements) {
                    console.log('not loaded');
                    return;
                }
                const { paymentMethod, error } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: elements.getElement(CardElement),
                    billing_details: { name: formData.name, email: formData.email, phone: formData.phone },
                });
                if (error) {
                    console.log('error', error);
                    toast.error('Payment Declined. Registration was not successful.');
                    return;
                }
                const dataRequest = {
                    email: formData.email,
                    name: formData.name,
                    phone: formData.phone,
                    planType,
                    trialPeriodDays: 7,
                    paymentMethodId: paymentMethod.id,
                };
                const response = await fetch(`${baseUrl}/api/stripe-secret-subscription`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(dataRequest),
                });
                const data = await response.json();
                if (data.clientSecret) {
                    const result = await stripe.confirmCardPayment(data.clientSecret, { payment_method: paymentMethod.id });
                    if (result.error) {
                        toast.error('Payment Declined. Registration was not successful.');
                    } else {
                        await registerMemberAfterSuccessfulSubscription(paymentMethod.id);
                    }
                } else {
                    await registerMemberAfterSuccessfulSubscription(paymentMethod.id);
                }
            }
        } catch (error) {
            console.error('Error during payment process:', error);
            toast.error('An error occurred during the payment process. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <Container>
                <Row>
                    <Col lg={6}>
                        <form onSubmit={handlePayment}>
                            <fieldset>
                                <legend>Contact Information</legend>
                                <ContactInformation formData={formData} handleChange={(e) => handleChange(e, setFormData, setErrors)} errors={errors} />
                                <div className='cardInfoSection'>
                                    <CardElement onChange={handleCardChange} />
                                </div>
                                <div className='buttonSection'>
                                    <button 
                                        type="submit" 
                                        disabled={isLoading || !isFormValid}
                                        style={{
                                            backgroundColor: isLoading || !isFormValid ? '#808080' : '#0000ff',
                                            color: '#ffffff'
                                        }}
                                    >
                                        {isLoading ? <Spinner /> : (currentAmount > 0 ? 'Pay' : 'Become Member')}
                                    </button>
                                </div>
                            </fieldset>
                        </form>
                    </Col>
                    <Col lg={6} style={{padding: '20px'}}>
                        <SubscriptionDetails planType={planType} seminar={seminar} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default function InjectedCheckoutForm(props) {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <CheckoutForm stripe={stripe} elements={elements} seminar={props.seminar} planType={props.planType} />
            )}
        </ElementsConsumer>
    );
}