import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getBaseUrl } from '../Utils/getBaseUrl';
import Spinner from '../Spinner';
import { TextField, Button, Chip, Checkbox } from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import BusinessIcon from '@mui/icons-material/Business';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { withAlert } from 'react-alert'
import SubscriptionCheckboxes from '../CommonComponents/SubscriptionCheckboxes'; 


function JobDetails(props) {
  const navigate = useNavigate();

  const [job, setJob] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    title: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    resume: null,
    subscribeWhatsapp: true,
    subscribeNewsletter: true,
  });
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({}); // State to hold form errors
  const [errorMessage, setErrorMessage] = useState('');



  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        if (!id) {
          navigate('/Careers');
          return; // Exit the function to avoid further code execution
        }

        const baseUrl = getBaseUrl();
        const dataRequest = {
          job_id: id
        };

        const response = await fetch(`${baseUrl}/api/job`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dataRequest),
        });

        if (response.status === 500) {
          navigate('/Careers');
          return; // Exit the function to avoid further code execution
        }

        const data = await response.json();
        setJob(data[0]);
      } catch (error) {
        console.log('Unable to fetch job details');
      } finally {
        setIsLoading(false);
      }
    };

    fetchJobDetails();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file.size > 10 * 1024 * 1024) { // 10 MB limit
      setErrors({ ...errors, resume: 'File size exceeds the limit (10 MB)' });
    } else {
      setFormData({ ...formData, resume: file });
    }
  };

  const showSuccessMessageAndNavigate = () => {
    props.alert.show('Job Application Successful', {
      timeout: 2000, // custom timeout just for this one alert
      type: 'success',
      onClose: () => {
        navigate('/');
      }
    })
  }


  const handleSubmit = async () => {
    // Clear previous errors
    setErrors({});

    // Perform validation checks
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = 'Name is required';
    } else if (formData.name.length > 100) {
      errors.name = 'Name cannot be more than 100 characters';
    }
    if (!formData.title.trim()) {
      errors.title = 'Title is required';
    } else if (formData.title.length > 100) {
      errors.title = 'Title cannot be more than 100 characters';
    }
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (formData.email.length > 100 || !isValidEmail(formData.email)) {
      errors.email = 'Invalid email format';
    }
    if (!formData.phone.trim()) {
      errors.phone = 'Phone number is required';
    } else if (formData.phone.length > 100) {
      errors.phone = 'Invalid phone number format';
    }
    // Validate optional fields
    if (formData.address && formData.address.length > 100) {
      errors.address = 'Address cannot be more than 100 characters';
    }
    if (formData.city && formData.city.length > 100) {
      errors.city = 'City cannot be more than 100 characters';
    }
    if (formData.state && formData.state.length > 100) {
      errors.state = 'State cannot be more than 100 characters';
    }
    if (formData.country && formData.country.length > 100) {
      errors.country = 'Country cannot be more than 100 characters';
    }
    if (formData.zip && formData.zip.length > 100) {
      errors.zip = 'ZIP code cannot be more than 100 characters';
    }
    if (!formData.resume) {
      errors.resume = 'Resume is needed';
    }
    if (Object.keys(errors).length > 0) {
      // If there are errors, set them in the state
      setErrors(errors);
      return;
    }

    if (Object.keys(errors).length === 0) {
      try {
        setIsLoading(true);
        const baseUrl = getBaseUrl();
        const formDataToSend = new FormData();
        formDataToSend.append('job_title', job.title);
        formDataToSend.append('job_id', job.id);
        formDataToSend.append('name', formData.name);
        formDataToSend.append('title', formData.title);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('phone_number', formData.phone);
        formDataToSend.append('address', formData.address);
        formDataToSend.append('city', formData.city);
        formDataToSend.append('state', formData.state);
        formDataToSend.append('country', formData.country);
        formDataToSend.append('zip', formData.zip);
        formDataToSend.append('resume', formData.resume);
        formDataToSend.append('subscribe_whatsapp', formData.subscribeWhatsapp);
        formDataToSend.append('subscribe_newsletter', formData.subscribeNewsletter);

        console.log('value of formData.resume: ', formData.resume);

        const response = await fetch(`${baseUrl}/api/job-application`, {
          method: 'POST',
          // headers: { 'Content-Type': 'multipart/form-data' },
          body: formDataToSend,
        });

        if (!response.ok) {
          throw new Error('Failed to submit job application');
        }

        setErrorMessage('Application submitted successfully. We will be in touch soon.');
        await showSuccessMessageAndNavigate();

        // Handle successful submission (e.g., show success message, redirect, etc.)
      } catch (error) {
        setErrorMessage('Failed to submit job application. Please try again later.');
        console.error('Error submitting job application:', error);
        // Handle error (e.g., show error message to user)
      } finally {
        setIsLoading(false);
      }
    }
  };

  const isValidEmail = (email) => {
    // Simple email validation regex
    return /\S+@\S+\.\S+/.test(email);
  };

  const isValidPhone = (phone) => {
    // Phone number validation regex (accepts only digits and optional dashes or spaces)
    return /^[0-9]{3}[-\s]?[0-9]{3}[-\s]?[0-9]{4}$/.test(phone);
  };

  const isValidFileType = (file) => {
    // Check if file type is PDF
    return file.type === 'application/pdf';
  };


  return (
    <div style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }}>
      {isLoading ? ( // Render spinner if loading
        <Spinner />
      ) : job ? (
        <>
          <h1 style={{ textAlign: 'center', fontWeight: 'bold' }}>{job.title}</h1>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
            <Chip label={job.category} style={{ margin: '5px' }} />
            <Chip label={job.type.charAt(0).toUpperCase() + job.type.slice(1)} style={{ margin: '5px' }} />
            <Chip label={job.location} style={{ margin: '5px' }} />
          </div>
          <div style={{ whiteSpace: 'pre-wrap' }}>
            <p>{job.description}</p>
          </div>
          <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', width: '100%' }}>
              <div style={{ marginRight: '10px', flex: 1 }}>
                <TextField
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  style={{ width: '100%' }}
                  InputProps={{
                    startAdornment: <PersonOutlineIcon />,
                  }}
                />
                {errors.name && <span style={{ color: 'red', marginLeft: '10px' }}>{errors.name}</span>}
              </div>
              <div style={{ flex: 1 }}>
                <TextField
                  name="title"
                  placeholder="Title"
                  value={formData.title}
                  onChange={handleChange}
                  style={{ width: '100%' }}
                  InputProps={{
                    startAdornment: <BusinessIcon />,
                  }}
                />
                {errors.title && <span style={{ color: 'red', marginLeft: '10px' }}>{errors.title}</span>}
              </div>
            </div>
            <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', width: '100%' }}>
              <div style={{ marginRight: '10px', flex: 1 }}>
                <TextField
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  style={{ width: '100%' }}
                  InputProps={{
                    startAdornment: <EmailIcon />,
                  }}
                />
                {errors.email && <span style={{ color: 'red', marginLeft: '10px' }}>{errors.email}</span>}
              </div>
              <div style={{ flex: 1 }}>
                <TextField
                  name="phone"
                  placeholder="Phone"
                  value={formData.phone}
                  onChange={handleChange}
                  style={{ width: '100%' }}
                  InputProps={{
                    startAdornment: <PhoneIcon />,
                  }}
                />
                {errors.phone && <span style={{ color: 'red', marginLeft: '10px' }}>{errors.phone}</span>}
              </div>
            </div>
            <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', width: '100%' }}>
              <div style={{ marginRight: '10px', flex: 1 }}>
                <TextField
                  name="address"
                  placeholder="Address"
                  value={formData.address}
                  onChange={handleChange}
                  style={{ width: '100%' }}
                  InputProps={{
                    startAdornment: <LocationOnIcon />,
                  }}
                />
                {errors.address && <span style={{ color: 'red', marginLeft: '10px' }}>{errors.address}</span>}
              </div>
              <div style={{ flex: 1 }}>
                <TextField
                  name="city"
                  placeholder="City"
                  value={formData.city}
                  onChange={handleChange}
                  style={{ width: '100%' }}
                  InputProps={{
                    startAdornment: <LocationCityIcon />,
                  }}
                />
                {errors.city && <span style={{ color: 'red', marginLeft: '10px' }}>{errors.city}</span>}
              </div>
            </div>
            <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', width: '100%' }}>
              <div style={{ marginRight: '10px', flex: 1 }}>
                <TextField
                  name="state"
                  placeholder="State"
                  value={formData.state}
                  onChange={handleChange}
                  style={{ width: '100%' }}
                  InputProps={{
                    startAdornment: <LocationCityOutlinedIcon />,
                  }}
                />
                {errors.state && <span style={{ color: 'red', marginLeft: '10px' }}>{errors.state}</span>}
              </div>
              <div style={{ marginRight: '10px', flex: 1 }}>
                <TextField
                  name="country"
                  placeholder="Country"
                  value={formData.country}
                  onChange={handleChange}
                  style={{ width: '100%' }}
                  InputProps={{
                    startAdornment: <LocationCityOutlinedIcon />,
                  }}
                />
                {errors.country && <span style={{ color: 'red', marginLeft: '10px' }}>{errors.country}</span>}
              </div>
              <div style={{ flex: 1 }}>
                <TextField
                  name="zip"
                  placeholder="ZIP Code"
                  value={formData.zip}
                  onChange={handleChange}
                  style={{ width: '100%' }}
                  InputProps={{
                    startAdornment: <LocationCityOutlinedIcon />,
                  }}
                />
                {errors.zip && <span style={{ color: 'red', marginLeft: '10px' }}>{errors.zip}</span>}
              </div>
            </div>
            <SubscriptionCheckboxes formData={formData} setFormData={setFormData} />
            <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
              <label htmlFor="resume" style={{ flex: 1 }}>
                <input type="file" name="resume" accept=".pdf" onChange={handleFileChange} style={{ display: 'none' }} id="resume" />
                <Button variant="contained" color="primary" component="span">
                  Attach Resume
                </Button>
              </label>
              <div style={{ flexShrink: 0, marginLeft: '10px' }}>
                {formData.resume && (
                  <CloudUploadIcon style={{ color: 'green' }} />
                )}
                {errors.resume && <span style={{ color: 'red' }}>{errors.resume}</span>}
              </div>
            </div>
            <div>
              <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginTop: '10px' }}>
                Apply Now
              </Button>
            </div>
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </>
      ) : (
        <p>No job details available.</p>
      )}
    </div>

  );
}

export default withAlert()(JobDetails);