import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateNewFilters } from '../../../services/newFilters/actions';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import './Filter.css';

const availableSizes = ['Free', 'Paid', 'Ghumman Tech Courses', 'Custom Courses'];

const Filter = ({ updateNewFilters, newFilters }) => {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState(new Set(newFilters));

  useEffect(() => {
    setSelectedCheckboxes(new Set(newFilters));
  }, [newFilters]);

  const toggleCheckbox = label => {
    const newSelectedCheckboxes = new Set(selectedCheckboxes);
    if (newSelectedCheckboxes.has(label)) {
      newSelectedCheckboxes.delete(label);
    } else {
      newSelectedCheckboxes.add(label);
    }
    setSelectedCheckboxes(newSelectedCheckboxes);
    updateNewFilters(Array.from(newSelectedCheckboxes));
  };

  const resetFilters = () => {
    setSelectedCheckboxes(new Set());
    updateNewFilters([]);
  };

  const createCheckbox = label => (
    <FormControlLabel
      control={
        <Checkbox
          checked={selectedCheckboxes.has(label)}
          onChange={() => toggleCheckbox(label)}
          name={label}
          color="primary"
        />
      }
      label={label}
      key={label}
    />
  );

  const createCheckboxes = () => availableSizes.map(createCheckbox);

  return (
    <div className="filters">
      <span style={{fontSize: '18px', fontWeight: 'bold', paddingLeft: '10px', paddingRight: '10px', color: 'purple'}}>FILTERS : </span>
      {createCheckboxes()}
      <IconButton color="secondary" onClick={resetFilters}>
        <RefreshIcon />
      </IconButton>
    </div>
  );
};

Filter.propTypes = {
  updateNewFilters: PropTypes.func.isRequired,
  newFilters: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  newFilters: state.newFilters.items
});

export default connect(
  mapStateToProps,
  { updateNewFilters }
)(Filter);