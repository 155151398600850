import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getBaseUrl } from '../Utils/getBaseUrl';
import Spinner from '../Spinner';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './style.css';

const TeachingTabPanel = () => {
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState('');
  const [eligibleCourses, setEligibleCourses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [estimatedEarnings, setEstimatedEarnings] = useState(0);

  useEffect(() => {
    const fetchTeacherCourses = async () => {
      setIsLoading(true);
      try {
        const baseUrl = getBaseUrl();
  
        const dataRequest = {
          user_email: localStorage.getItem("email"),
        };
  
        const response = await fetch(`${baseUrl}/api/all-teacher-courses`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dataRequest),
        });
  
        if (response.ok) {
          const data = await response.json();
          setEligibleCourses(data);

          if (data.length > 0) {
            const earningsResponse = await fetch(`${baseUrl}/api/month-earnings-by-owner`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ email: localStorage.getItem("email") }),
            });

            if (earningsResponse.ok) {
              const earningsData = await earningsResponse.json();
              setEstimatedEarnings(earningsData.earnings);
            }
          }
        }
      } catch (e) {
        console.error('Error fetching teacher courses:', e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeacherCourses();
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleEditClick = (course) => {
    navigate('/CourseMetadataEdit', { state: { course } });
  }

  const handleDeleteClick = (course) => {
    setCourseToDelete(course);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    setIsLoading(true);
    try {
      const baseUrl = getBaseUrl();
      const dataRequest = {
        course_id: courseToDelete.id,
        user_email: localStorage.getItem("email"),
      };

      const response = await fetch(`${baseUrl}/api/delete-course`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataRequest),
      });

      if (response.ok) {
        setEligibleCourses(eligibleCourses.filter(course => course.id !== courseToDelete.id));
        setShowModal(false);
        setCourseToDelete(null);
        toast.success('Course deleted successfully');
      } else {
        console.error('Error deleting course');
        toast.error('Error deleting course');
      }
    } catch (e) {
      console.error('Error deleting course:', e);
      toast.error('Error deleting course');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCourseToDelete(null);
  };

  const filteredCourses = eligibleCourses.filter(course => {
    const matchesSearch = course.name.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesSearch;
  });

  return (
    <div>
      {isLoading && <Spinner />}
      <div className="earnings-container" style={{ textAlign: 'center', marginBottom: '20px' }}>
        <h4>Estimated Earnings This Month: ${estimatedEarnings.toFixed(2)}</h4>
      </div>
      <div className="filter-container">
        <Form.Control
          type="text"
          placeholder="Search by Name"
          value={searchQuery}
          onChange={handleSearchChange}
          className="search-bar"
        />
      </div>
      {filteredCourses && filteredCourses.length > 0 ? (
        <div>
          <Container>
            <Row className="justify-content-center">
              {filteredCourses.map(course => (
                <Col key={course.id} xs={12} sm={6} md={4} lg={3}>
                  <Card border="success" style={{ width: '18rem', height: '25rem', padding: '10px' }}>
                    <Link to={`/Courses/${course.id}`}>
                      <Card.Img
                        variant="top"
                        width={100}
                        height={300}
                        src={`https://ghummantech-website.s3.us-east-2.amazonaws.com/GT_Course_${course.id}_1.jpg`} />
                      <Card.Body style={{ textAlign: 'center' }}>
                        <Col>
                          <Row style={{ display: 'grid', placeItems: 'center', marginTop: '10px', fontWeight: 'bold' }}>
                            {course.name}
                          </Row>
                        </Col>
                      </Card.Body>
                    </Link>
                  </Card>
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    <Button variant="primary" onClick={() => handleEditClick(course)} style={{ marginRight: '10px' }}>
                      Edit
                    </Button>
                    <Button variant="danger" onClick={() => handleDeleteClick(course)}>
                      Delete
                    </Button>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>You haven't created any courses yet!</div>
      )}
      <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        <Button variant="success" onClick={() => navigate('/CreateNewCourse')}>
          Create New Course
        </Button>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this course?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default TeachingTabPanel;