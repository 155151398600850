export const formatPrice = (x, currency) => {
  if (x == null)
  return x;
  switch (currency) {
    case 'BRL':
      return x.toFixed(2).replace('.', ',');
    default:
      return x.toFixed(2);
  }
};

export const productsAPI = 
// 'https://api.myjson.com/bins/173kdu'
// 'https://stealthdocumentation.com/products_ghummantech.json';
'https://ghummantech-website.s3.us-east-2.amazonaws.com/products_ghummantech.json';
// 'https://stealthdocumentation.com/noFileType.json';
// 'https://stealthdocumentation.com/noFileType';
// 'https://api.jsonbin.io/b/5e709cfe4004a25eee132bd7';
// 'https://www.stealthdocumentation.com/products.json';
  // 'https://api.myjson.com/bins/co3nm';
  // 'https://1drv.ms/u/s!AhGEcXbE-3xUgQ89-ieUNoU0ButD';
  // 'https://react-shopping-cart-67954.firebaseio.com/products.json';
  // 'https://docs.google.com/document/d/1-OqaG2BgFCOfPan6QhHXrIcInDFJ7ijHoR8931RHQo0/edit?usp=sharing';
  // 'https://drive.google.com/file/d/1B3NOk9Q07OHjLnlTxRJqyzqSG-aW5vPy/view?usp=sharing';
// export const productsAPI = "http://localhost:8001/api/products";
