import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom'; // For routing to AboutUs page
import LanguagesAd from '../../assets/LanguagesAd.jpg';

const ImageWithButton = () => {

  return (
    <div className="image-screen">
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '0',
          paddingBottom: '56.25%',
          overflow: 'hidden',
        }}
      >
        <img
          src={LanguagesAd}
          alt="Lanugage Courses"
          style={{width: '100%', maxWidth: '100%', boxSizing: 'border-box'}}
          />
        <Link to="/Categories/languages" className="image-button">
          {'LANGUAGE COURSES'}
        </Link>

      </div>
    </div>
  );
};

export default ImageWithButton;;