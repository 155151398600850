// CourseContent.js

import React from 'react';
import './style.css';

const CourseContent = () => {
  return (
    <div className="course-container">
      <h1 className="course-title">Course Content</h1>
      <ul className="course-list">
        <li className="course-item">
          <h3>Introduction to Programming Languages</h3>
          <p>- Basics of Scratch for beginners.<br />
             - Fundamentals of Python, C++, and Java.<br />
             - Writing simple programs and understanding syntax.</p>
        </li>
        <li className="course-item">
          <h3>Programming Concepts</h3>
          <p>- Variables, loops, and conditionals.<br />
             - Functions and procedures.<br />
             - Basic algorithms and problem-solving techniques.</p>
        </li>
        <li className="course-item">
          <h3>Robotics Programming</h3>
          <p>- Introduction to robotics and automation.<br />
             - Writing code to control robots.<br />
             - Using sensors and actuators in robotic projects.</p>
        </li>
        <li className="course-item">
          <h3>Basics of Electronics</h3>
          <p>- Understanding electronic components (resistors, capacitors, LEDs, etc.).<br />
             - Basics of circuits and circuit design.<br />
             - Safety measures when working with electronics.</p>
        </li>
        <li className="course-item">
          <h3>Arduino and ESP32</h3>
          <p>- Introduction to Arduino and ESP32 microcontrollers.<br />
             - Setting up the development environment.<br />
             - Writing and uploading programs to microcontrollers.</p>
        </li>
        <li className="course-item">
          <h3>Connection and Communication Protocols</h3>
          <p>- Understanding digital and analog signals.<br />
             - Communication protocols (I2C, SPI, UART).<br />
             - Interfacing with various sensors and modules.</p>
        </li>
        <li className="course-item">
          <h3>Building Projects with Arduino and ESP32</h3>
          <p>- Hands-on projects (e.g., blinking LED, temperature sensor).<br />
             - Debugging and troubleshooting techniques.<br />
             - Integrating multiple sensors and actuators in a project.</p>
        </li>
        <li className="course-item">
          <h3>Reading and Understanding Datasheets</h3>
          <p>- Importance of datasheets in electronics.<br />
             - How to extract key information from datasheets.<br />
             - Practical examples using component datasheets.</p>
        </li>
        <li className="course-item">
          <h3>Introduction to Advanced Topics</h3>
          <p>- Basics of Artificial Intelligence and Machine Learning.<br />
             - Introduction to Internet of Things (IoT).<br />
             - Exploring future trends in robotics and electronics.</p>
        </li>
        <li className="course-item">
          <h3>Capstone Project</h3>
          <p>- Designing and building a complete robotics project.<br />
             - Applying all learned concepts in a practical scenario.<br />
             - Presenting and demonstrating the project.</p>
        </li>
      </ul>
    </div>
  );
};

export default CourseContent;
