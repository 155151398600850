import React from "react";
import { Box, Typography, IconButton, Chip, Grid } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const SubscriptionSwitchNotGt = ({ notGtSubscriptions = [], handleCancelPressed, setNotGtSubscriptionsDeleteCourseId }) => {

  return (
    <Box
      mt={4}
      textAlign="center"
      sx={{
        border: '2px solid #ff0604',
        borderRadius: '15px',
        padding: '20px'
      }}
    >
      <Typography
        variant="h4"
        component="div"
        gutterBottom
        sx={{
          fontWeight: 'bold',
          color: '#ff0604',
          padding: '10px',
          display: 'inline-block'
        }}
      >
        Other Paid Subscriptions
      </Typography>
      <Box mt={2}>
        {Array.isArray(notGtSubscriptions) && notGtSubscriptions.length > 0 ? (
          notGtSubscriptions.map((subscription) => (
            <Box
              key={subscription.course_id}
              mt={2}
              sx={{
                border: '1px solid #ccc',
                borderRadius: '10px',
                padding: '10px'
              }}
            >
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={4} textAlign="center">
                  <Chip 
                    label={subscription.course_name} 
                    sx={{ backgroundColor: '#1976d2', color: 'white', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }} 
                  />
                </Grid>
                <Grid item xs={4} textAlign="center">
                  <Chip 
                    label={subscription.owner_full_name} 
                    sx={{ backgroundColor: '#d32f2f', color: 'white', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }} 
                  />
                </Grid>
                <Grid item xs={4} textAlign="center">
                  <IconButton
                    color="error"
                    onClick={() => {
                      setNotGtSubscriptionsDeleteCourseId(subscription.course_id);
                      handleCancelPressed();
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          ))
        ) : (
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: '#ff0604',
              padding: '10px',
              display: 'inline-block'
            }}
          >
            You don't have any paid subscriptions
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default SubscriptionSwitchNotGt;