import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@mui/styles";
import { languages } from "../../languages";
import Clock from "./Clock";

const useStyles = makeStyles({
  card: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column", // Change to column layout
    padding: "50px",
    width: "90vw",
    minHeight: "300px",
    maxHeight: (props) => (props.isDesktopOrLarger ? "2200px" : "3000px"),
    backgroundColor: "grey",
    color: "yellow",
    overflowY: "auto",
    textAlign: "center",
  },
  questionContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column", // Use column direction for questions
    padding: "20px",
    border: "4px solid black",
    borderRadius: "20px",
    position: "relative",
    width: "100%",
    maxWidth: "600px", // Set a max-width for better layout
  },
  optionsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: "20px", // Add margin to separate from question
    width: "100%",
    maxWidth: "600px",
  },
  option: {
    flex: "1 1 45%", // Use flexbox to manage spacing
    margin: "10px", // Add margin between options
    padding: "10px",
    border: "3px solid white",
    borderRadius: "15px",
    cursor: "pointer",
    transition: "border-color 0.3s, opacity 0.3s",
    "&:hover": {
      opacity: 0.7,
    },
  },
  icon: {
    padding: "10px",
  },
  label: {
    display: "flex",
    alignItems: "center",
  },
  optionText: {
    marginLeft: "10px",
  },
  selectedOption: {
    borderColor: "green",
  },
  disabledOption: {
    cursor: "not-allowed",
    opacity: 0.5,
  },
});

const QuestionCardClick = ({
  question,
  quizData,
  currentQuestion,
  shuffledOptions,
  selectedOption,
  handleOptionChange,
  isDesktopOrLarger,
  isHost,
  remainingTime,
}) => {
  const classes = useStyles({ isDesktopOrLarger });

  const handleSpeakUrdu = async (text) => {
    const apiKey = "AIzaSyCIBPx5Ww6q7Cbuqca6EYoZmTTLeYiRJU4";
    const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`;
    const data = {
      input: { text },
      voice: { languageCode: "ur-PK" },
      audioConfig: { audioEncoding: "MP3" },
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      const audioContent = result.audioContent;
      const audio = new Audio(`data:audio/mp3;base64,${audioContent}`);
      audio.play();
    } catch (error) {
      console.error("Error with Google Text-to-Speech API:", error);
    }
  };

  const handleSpeak = async (text, isQuestion, textLang) => {
    const utterance = new SpeechSynthesisUtterance();
    utterance.text = text;
    if (
      quizData[currentQuestion].course_type === "live" &&
      textLang === "urdu"
    ) {
      await handleSpeakUrdu(text);
      return;
    } else {
      utterance.lang = isQuestion
        ? languages[quizData[currentQuestion].course_language || "en"]
        : languages[localStorage.getItem("user-language")];
    }
    window.speechSynthesis.speak(utterance);
  };

  return (
    <div className={classes.card}>
      <Clock remainingTime={remainingTime} />
      <div className={classes.questionContainer}>
        <FontAwesomeIcon
          icon={faVolumeUp}
          size="1x"
          className={classes.icon}
          onClick={() =>
            handleSpeak(
              quizData[currentQuestion].question,
              true,
              quizData[currentQuestion].question_language
            )
          }
        />
        <p dangerouslySetInnerHTML={{ __html: question }}></p>
      </div>
      <div className={classes.optionsContainer}>
        {shuffledOptions.map((option, index) => (
          <div
            key={index}
            className={`${classes.option} ${
              selectedOption === option.text ? classes.selectedOption : ""
            } ${isHost ? classes.disabledOption : ""}`}
            onClick={() => {
              if (!isHost) {
                handleOptionChange(option);
              }
            }}
          >
            <FontAwesomeIcon
              icon={faVolumeUp}
              size="1x"
              className={classes.icon}
              onClick={() => handleSpeak(option.text, false, option.language)}
            />
            <label htmlFor={`option-${index}`} className={classes.label}>
              <input
                type="radio"
                id={`option-${index}`}
                name="options"
                value={option.text}
                checked={selectedOption === option.text}
                onChange={() => handleOptionChange(option)}
                style={{ display: "none" }}
                disabled={isHost}
              />
              <span
                className={classes.optionText}
                dangerouslySetInnerHTML={{ __html: option.text }}
              ></span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuestionCardClick;
