import React, { useState, useEffect, useRef } from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import ReactionIcons from './ReactionIcons';
import ReplyIcon from '@mui/icons-material/Reply';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getBaseUrl } from '../../Utils/getBaseUrl';

const Message = ({ message, userId, handleReaction, activeMessageId, setActiveMessageId, setMessageInput, navigateToMessage, setMessageId, setParent, courseId, chapterNumber, ws }) => {
  const reactionRef = useRef(null);

  const formatTime = (dateString) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return new Date(dateString).toLocaleTimeString(undefined, options);
  };

  const handleClickOutside = (event) => {
    if (reactionRef.current && !reactionRef.current.contains(event.target)) {
      setActiveMessageId(null);
    }
  };

  useEffect(() => {
    if (activeMessageId !== message.message_id) return;
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeMessageId]);

  const isOwnMessage = message.user_id === userId;

  const renderMedia = (url) => {
    return (
      <>
        <img
          src={url}
          alt="media"
          onError={(e) => {
            e.target.style.display = 'none';
            e.target.nextSibling.style.display = 'block';
          }}
          style={{ maxWidth: '100%', marginTop: '10px', borderRadius: '10px', display: 'block' }}
        />
        <video
          controls
          style={{ maxWidth: '100%', marginTop: '10px', borderRadius: '10px', display: 'none' }}
        >
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </>
    );
  };

  const handleEdit = () => {
    setMessageInput(message.message_content);
    setMessageId(message.message_id);
  };

  const handleReply = () => {
    setParent({
      parentId: message.message_id,
      parentContent: message.message_content,
      parentMediaUrl: message.message_media_url
    });
  };

  const handleDelete = async () => {
    const confirmed = window.confirm('Are you sure you want to delete this message?');
    if (!confirmed) return;

    try {

      // Construct the WebSocket message data
      const messageData = {
        action: 'delete',
        message: {
          course_id: courseId,
          chapter_number: chapterNumber,
          message_id: message.message_id,
          user_id: userId,
        }
      };

      // Send the message data as a JSON string via WebSocket
      ws.send(JSON.stringify(messageData));

    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: isOwnMessage ? 'flex-end' : 'flex-start', marginBottom: '10px', position: 'relative' }}>
      {message.parent_content || message.parent_media_url ? (
        <div
          onClick={() => navigateToMessage(message.parent_message_id)}
          style={{
            position: 'absolute',
            top: '-50px',
            left: isOwnMessage ? 'auto' : '0',
            right: isOwnMessage ? '0' : 'auto',
            backgroundColor: '#f0f0f0',
            color: '#888',
            padding: '20px',
            borderRadius: '10px',
            cursor: 'pointer',
            opacity: 0.7,
            zIndex: 10,
          }}
        >
          {message.parent_content && <div>{message.parent_content}</div>}
          {message.parent_media_url && (
            <div style={{ width: '50px', height: '50px', overflow: 'hidden' }}>
              {renderMedia(message.parent_media_url, { width: '100%', height: '100%', objectFit: 'cover' })}
            </div>
          )}
        </div>
      ) : null}
      <div style={{ maxWidth: '60%', padding: '10px', borderRadius: '10px', backgroundColor: isOwnMessage ? '#007bff' : '#f0f0f0', color: isOwnMessage ? 'white' : 'black', boxShadow: '0 2px 5px rgba(0,0,0,0.1)', zIndex: 20 }}>
        <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>{message.user_name}</div>
        <div style={{ marginBottom: '5px' }}>{message.message_content}</div>
        {message.message_media_url && renderMedia(message.message_media_url)}
        <div style={{ fontSize: '0.8em', color: isOwnMessage ? '#d1e7ff' : '#888' }}>{formatTime(message.message_created_at)}</div>
        {message.reactions && message.reactions.length > 0 && (
          <ReactionIcons reactions={message.reactions} />
        )}
      </div>
      <div style={{ zIndex: 20 }}>
        <button onClick={handleReply} style={{ marginLeft: '10px', background: 'none', border: 'none', cursor: 'pointer' }}>
          <ReplyIcon />
        </button>
        {isOwnMessage && (
          <>
            <button onClick={handleEdit} style={{ marginLeft: '10px', background: 'none', border: 'none', cursor: 'pointer' }}>
              <EditIcon />
            </button>
            <button onClick={handleDelete} style={{ marginLeft: '10px', background: 'none', border: 'none', cursor: 'pointer' }}>
              <DeleteIcon />
            </button>
          </>
        )}
        {!isOwnMessage && (
          <button onClick={() => setActiveMessageId(activeMessageId === message.message_id ? null : message.message_id)} style={{ marginLeft: '10px', background: 'none', border: 'none', cursor: 'pointer' }}>
            <AddCircleOutlineIcon />
          </button>
        )}
        {activeMessageId === message.message_id && (
          <div ref={reactionRef} style={{ position: 'absolute', top: '-50px', left: isOwnMessage ? 'auto' : '0', right: isOwnMessage ? '0' : 'auto', display: 'flex', gap: '5px', backgroundColor: 'white', border: '1px solid #ccc', borderRadius: '5px', padding: '5px', boxShadow: '0 2px 5px rgba(0,0,0,0.1)', zIndex: 1000 }}>
            <Tooltip title="Thumbs Up">
              <button onClick={() => handleReaction(message.message_id, 'thumbs_up')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                <ThumbUpIcon style={{ color: '#1976d2' }} />
              </button>
            </Tooltip>
            <Tooltip title="Like">
              <button onClick={() => handleReaction(message.message_id, 'like')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                <SentimentSatisfiedAltIcon style={{ color: '#ffb300' }} />
              </button>
            </Tooltip>
            <Tooltip title="Love">
              <button onClick={() => handleReaction(message.message_id, 'love')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                <FavoriteIcon style={{ color: '#e91e63' }} />
              </button>
            </Tooltip>
            <Tooltip title="Wow">
              <button onClick={() => handleReaction(message.message_id, 'wow')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                <SentimentVerySatisfiedIcon style={{ color: '#ff9800' }} />
              </button>
            </Tooltip>
            <Tooltip title="Sad">
              <button onClick={() => handleReaction(message.message_id, 'sad')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                <SentimentDissatisfiedIcon style={{ color: '#f44336' }} />
              </button>
            </Tooltip>
            <Tooltip title="Angry">
              <button onClick={() => handleReaction(message.message_id, 'angry')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                <SentimentVeryDissatisfiedIcon style={{ color: '#9c27b0' }} />
              </button>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default Message;