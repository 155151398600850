import React, { useState, useEffect, useRef } from "react";
import { getBaseUrl } from "../../Utils/getBaseUrl";
import Spinner from "../../Spinner";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";

const Messages = ({ courseId, chapterNumber }) => {
  const [messages, setMessages] = useState([]);
  const messagesRef = useRef(messages); // Ref to store the current state of messages
  const [isLoading, setIsLoading] = useState(false);
  const [activeMessageId, setActiveMessageId] = useState(null); // State to track which message's ReactionIcons is open
  const [editMessageId, setEditMessageId] = useState(null); // State to track which message's ReactionIcons is open
  const [parent, setParent] = useState(null);
  const userId = parseInt(localStorage.getItem("user_id"), 10); // Convert userId to an integer
  const [messageInput, setMessageInput] = useState("");
  const messageRefs = useRef({});
  const [ws, setWs] = useState(null);
  const websocketUrl = `wss://77g0u0607h.execute-api.us-east-2.amazonaws.com/production/?courseId=${courseId}&chapterNumber=${chapterNumber}`;

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        setIsLoading(true);
        const baseUrl = getBaseUrl();

        const dataRequest = {
          course_id: courseId,
          chapter_number: chapterNumber,
        };

        const response = await fetch(`${baseUrl}/api/student-messages`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dataRequest),
        });

        const data = await response.json();
        setMessages(data);
      } catch (error) {
        console.error("Error fetching messages:", error);
      } finally {
        setIsLoading(false);
      }
    };
    setActiveMessageId(null);
    setEditMessageId(null);
    setMessageInput("");
    setParent(null);
    setMessages([]);
    fetchMessages();
  }, []);

  // Update the ref whenever messages state changes
  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  // setup websocket connection
  useEffect(() => {
    // Function to connect to WebSocket
    const connectWebSocket = () => {
      const socket = new WebSocket(websocketUrl);

      socket.onopen = () => {};

      socket.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.action === "upload" || data.action === "update-reaction") {
            setMessages((prevMessages) => {
              // Find the index of the message with the same message_id
              const index = prevMessages.findIndex(
                (msg) => msg.message_id === data.newMessage.message_id
              );
              if (index !== -1) {
                // Replace the existing message with the new one
                const updatedMessages = [...prevMessages];
                updatedMessages[index] = data.newMessage;
                return updatedMessages;
              } else {
                // Append the new message to the array
                return [...prevMessages, data.newMessage];
              }
            });
          } else if (data.action === "delete") {
            setMessages((prevMessages) => {
              // Filter out the deleted message
              return prevMessages.filter(
                (msg) => msg.message_id !== data.deletedMessageId
              );
            });
          }
        } catch (error) {
          // Message is not JSON, ignore it
        }
      };

      socket.onclose = () => {
        // Reconnect logic can be added here if needed
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      setWs(socket);
      return socket; // Return the socket instance
    };

    const socket = connectWebSocket();

    // Clean up function to close WebSocket connection on component unmount
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, []);

  const handleReaction = async (messageId, reactionType) => {
    try {
      // Construct the WebSocket message data
      const messageData = {
        action: "update-reaction",
        message: {
          message_id: messageId,
          user_id: userId,
          reaction_type: reactionType,
          course_id: courseId,
          chapter_number: chapterNumber,
        },
      };

      // Send the message data as a JSON string via WebSocket
      ws.send(JSON.stringify(messageData));

      // Optionally, update the local state to reflect the reaction
    } catch (error) {
      console.error("Error sending reaction:", error);
    }
  };

  const groupMessagesByDate = (messages) => {
    return messages.reduce((groups, message) => {
      const date = new Date(message.message_created_at).toLocaleDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});
  };

  const navigateToMessage = (messageId) => {
    const messageElement = messageRefs.current[messageId];
    if (messageElement) {
      messageElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const groupedMessages = groupMessagesByDate(messages);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "#f9f9f9",
      }}
    >
      {isLoading && <Spinner />}
      <div
        style={{
          flex: 1,
          overflowY: "auto",
          padding: "20px",
          position: "relative",
        }}
      >
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>Messages</h2>
        <div
          style={{
            border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
            height: "100%",
            overflowY: "auto",
            backgroundColor: "white",
          }}
        >
          <MessageList
            groupedMessages={groupedMessages}
            userId={userId}
            handleReaction={handleReaction}
            activeMessageId={activeMessageId}
            setActiveMessageId={setActiveMessageId}
            setMessageInput={setMessageInput}
            setMessageId={setEditMessageId}
            messageRefs={messageRefs}
            navigateToMessage={navigateToMessage}
            setParent={setParent}
            courseId={courseId}
            chapterNumber={chapterNumber}
            ws={ws}
          />
        </div>
      </div>
      <MessageInput
        userId={userId}
        input={messageInput}
        setInput={setMessageInput}
        messageId={editMessageId}
        setMessageId={setEditMessageId}
        parent={parent}
        setParent={setParent}
        courseId={courseId}
        chapterNumber={chapterNumber}
        ws={ws}
      />
    </div>
  );
};

export default Messages;
