import { UPDATE_CURRENCY } from './actionTypes';

export const updateCurrency = (inputCurrency) => dispatch => {

  let currency = inputCurrency;

  dispatch({
    type: UPDATE_CURRENCY,
    payload: currency
  });
};
