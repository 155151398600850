// eligibilityService.js
import { getBaseUrl } from './getBaseUrl'; // Adjust the import path as needed

export const checkIfEligibleAllScenarios = async (email, courseId) => {
  try {
    const baseUrl = getBaseUrl();
    const dataRequest = {
      user_email: email,
      course_id: courseId,
    };
    const response = await fetch(
      `${baseUrl}/api/check-user-course-eligibility-all-scenarios`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      }
    );
    const data = await response.json();
    return data.eligible;
  } catch (error) {
    console.error("Error checking course eligibility:", error);
    return false;
  }
};

export const checkIfEligible = async (email, courseId) => {
  try {
    const baseUrl = getBaseUrl();
    const dataRequest = {
      user_email: email,
      course_id: courseId,
    };
    const response = await fetch(
      `${baseUrl}/api/check-user-course-eligibility`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      }
    );
    const data = await response.json();
    return data.eligible;
  } catch (error) {
    console.error("Error checking course eligibility:", error);
    return false;
  }
};

export const checkSubscriptionStatus = async (email) => {
  try {
    const baseUrl = getBaseUrl();
    const dataRequest = {
      email: email,
    };
    const response = await fetch(`${baseUrl}/api/check-subscription-status`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataRequest),
    });

    if (response.ok) {
      const responseData = await response.json();
      return responseData.message === "active";
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error checking subscription status:", error);
    return false;
  }
};