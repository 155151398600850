import React, { useState, useRef, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { getBaseUrl } from '../Utils/getBaseUrl';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import './style.css';
import Spinner from '../Spinner';
import { useMediaQuery } from 'react-responsive';

const SubscribeNewsLetter = () => {
  const isDesktopOrLarger = useMediaQuery({ minWidth: 768 });
  const [customer, setCustomer] = useState({
    email: '',
    name: '',
    phone_number: '',
  });
  const emailInputRef = useRef(null); // Ref for input element
  const [isValidEmail, setIsValidEmail] = useState(true); // Email validity state
  const [buttonPressCount, setButtonPressCount] = useState(0);
  const [subscriptionComplete, setSubscriptionComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCustomer({ ...customer, [name]: value });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression for email format
    const isValidEmailBoolean = emailRegex.test(email); // Test if email is valid
    setIsValidEmail(isValidEmailBoolean); // Update validity state
    return isValidEmailBoolean;
  };

  useEffect(() => {
    const shakeAnimation = () => {
      if (emailInputRef.current) {
        emailInputRef.current.classList.add('shake'); // Add shaking animation class
        setTimeout(() => {
          if (emailInputRef.current) {
            emailInputRef.current.classList.remove('shake'); // Remove animation after timeout
          }
        }, 500); // Adjust animation duration as needed
      }
    };
    if (!isValidEmail && buttonPressCount > 0) { // Check buttonPressCount state
      shakeAnimation();
    }

    // Cleanup function to remove animation class on unmount
    return () => {
      if (emailInputRef.current) {
        emailInputRef.current.classList.remove('shake');
      }
    };
  }, [isValidEmail, buttonPressCount]);

  const handleSubmitCustomerData = async (event) => {
    event.preventDefault();

    setButtonPressCount((prev) => prev + 1);

    const isValidEmailBoolean = validateEmail(customer.email); // Validate email before submission

    if (!isValidEmailBoolean) {
      return; // Prevent submission if email is invalid
    }

    try {
      setIsLoading(true);
      const baseUrl = getBaseUrl();

      const dataRequest = {
        ...customer, // Include all customer fields
        email: customer.email.trim(),
      };

      const response = await fetch(`${baseUrl}/api/user-subscribe-newsletter`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataRequest),
      });

      const data = await response.json();
      if (response.ok && data.message === "User subscribed successfully") {
        setSubscriptionComplete(true);
      }
    } catch (error) {
      console.error('Error verifying reCAPTCHA:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div id="subscribe-newsletter" className="row">
      {isLoading && (<Spinner />)}
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: isDesktopOrLarger ? '100px' : '300px'
      }}>
        {!subscriptionComplete && (
          <>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center' }}>
              <div>
                <input
                  type="text"
                  name="name"
                  placeholder="Full Name"
                  onChange={handleInputChange}
                  style={{
                    borderRadius: '8px', // Updated to round all corners
                    padding: '10px',
                    border: '1px solid #ddd',
                    marginRight: '0',
                    backgroundColor: 'white',
                  }}
                  id="nameInput"
                />
              </div>
              <div>
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  onChange={handleInputChange}
                  style={{
                    padding: '10px',
                    border: isValidEmail ? '1px solid #ddd' : '1px solid red',
                    marginRight: '0',
                    borderRadius: '8px', // Updated to round all corners
                    backgroundColor: 'white',
                  }}
                  id="emailInput"
                  ref={emailInputRef} // Assign ref to input element
                />
              </div>
              <div>

                <input
                  type="text"
                  name="phone_number"
                  placeholder="Phone Number"
                  onChange={handleInputChange}
                  style={{
                    borderRadius: '8px', // Updated to round all corners
                    padding: '10px',
                    border: '1px solid #ddd',
                    marginRight: '0',
                    backgroundColor: 'white',
                  }}
                  id="phoneNumberInput"
                />
              </div>
              <button
                style={{
                  borderRadius: '8px', // Updated to round all corners
                  padding: '10px 20px',
                  backgroundColor: '#333',
                  color: 'white',
                  border: 'none',
                  cursor: 'pointer',
                }}
                onClick={handleSubmitCustomerData}
                disabled={isLoading}
              >
                Subscribe
              </button>
            </div>
          </>
        )}

        {subscriptionComplete && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: '50px', textAlign: 'center' }}>
            <h4>Thank you for subscribing to our newsletter!</h4>
            <p>Stay tuned for the latest updates and offers.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscribeNewsLetter;