import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import React from 'react';

import { ThreeDots } from 'react-bootstrap-icons'

import android1 from '../../assets/android1.png';
import android2 from '../../assets/android2.png';
import android3 from '../../assets/android3.png';
import android4 from '../../assets/android4.png';
import android5 from '../../assets/android5.png';
import android6 from '../../assets/android6.png';
import android7 from '../../assets/android7.png';
import android8 from '../../assets/android8.png';
import android9 from '../../assets/android9.png';
import android10 from '../../assets/android10.png';

import {androidAppsLink} from './../Utils/constants'

const AndroidTimeline = () => {
    return (
        <div>

        <div style={{textAlign: 'center', padding: '20px', textDecoration: 'underline'}}>
        <p className='platform-title'>Android Projects</p>
        </div>
        <VerticalTimeline>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'lightblue', color: 'black'}}
    contentArrowStyle={{ borderRight: '7px solid  lightblue'}}
    date="Your needs, our compass. Navigating solutions together."
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff', overflow: 'hidden' }}
    icon={<img style={{width:'100%', height: '100%'}} src={android1}/>}
  >
    <h3 className="vertical-timeline-element-title">Private Pilot Exam</h3>
    <a style={{color: 'black', font: '10px', textDecoration: 'underline'}} href="https://play.google.com/store/apps/details?id=com.ghummantech.ppllite"> Details </a>
    <p>
        Soar through your Private Pilot Knowledge Test with PPL Lite: your comprehensive cockpit companion for mastering aviation theory and acing the exam.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="Not just customers, collaborators. Building success side-by-side."
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff', overflow: 'hidden' }}
    icon={<img style={{width:'100%', height: '100%'}} src={android2}/>}
  >
    <h3 className="vertical-timeline-element-title">Computer Networking</h3>
    <a style={{color: 'black', font: '10px', textDecoration: 'underline'}} href="https://play.google.com/store/apps/details?id=com.ghummantech.networking"> Details </a>
    <p>
    Master the maze of cables and conquer complex configurations with Networking Master: your pocket-sized guide to mastering computer networks and Cisco switches.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'lightblue', color: 'black'}}
    contentArrowStyle={{ borderRight: '7px solid  lightblue'}}
    date="We listen, we learn, we wow. Unleashing smiles, one interaction at a time."
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff', overflow: 'hidden' }}
    icon={<img style={{width:'100%', height: '100%'}} src={android3}/>}
  >
    <h3 className="vertical-timeline-element-title">Cyber Security</h3>
    <a style={{color: 'black', font: '10px', textDecoration: 'underline'}} href="https://play.google.com/store/apps/details?id=com.ghummantech.cybersecurity"> Details </a>
    <p>
    Shield your digital life from cyber threats with Cybersecurity Guru: your essential guide to secure browsing, strong passwords, and online peace of mind.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="More than transactions, relationships. Where satisfaction fuels loyalty."
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff', overflow: 'hidden' }}
    icon={<img style={{width:'100%', height: '100%'}} src={android4}/>}
  >
    <h3 className="vertical-timeline-element-title">Dog Breeds</h3>
    <a style={{color: 'black', font: '10px', textDecoration: 'underline'}} href="https://play.google.com/store/apps/details?id=com.ghummantech.dogs"> Details </a>
    <p>
    Helps you master dog breed identification through engaging study modes and audio pronunciation, making you a canine connoisseur in no time.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    contentStyle={{ background: 'lightblue', color: 'black'}}
    contentArrowStyle={{ borderRight: '7px solid  lightblue'}}
    date='Beyond expectations, beyond compare. Crafting experiences that leave you saying "Wow!"'
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff', overflow: 'hidden' }}
    icon={<img style={{width:'100%', height: '100%'}} src={android5}/>}
  >
    <h3 className="vertical-timeline-element-title">Gym Workout Motivation & Track</h3>
    <a style={{color: 'black', font: '10px', textDecoration: 'underline'}} href="https://play.google.com/store/apps/details?id=com.ghummantech.gymmotivation"> Details </a>
    <p>
    Power through gym workouts, crush exams, and conquer goals with inspiring slides, progress charts, and motivational nudges.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="Your feedback is our roadmap. Paving the way to a customer-centric future."
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff', overflow: 'hidden' }}
    icon={<img style={{width:'100%', height: '100%'}} src={android6}/>}
  >
    <h3 className="vertical-timeline-element-title">Driving Road Sign</h3>
    <a style={{color: 'black', font: '10px', textDecoration: 'underline'}} href="https://play.google.com/store/apps/details?id=com.ghummantech.roadsigns"> Details </a>
    <p>
    Conquer confusing curves and cryptic cones with Road Signs: your pocket-sized driving test prep buddy for mastering every traffic symbol.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    contentStyle={{ background: 'lightblue', color: 'black'}}
    contentArrowStyle={{ borderRight: '7px solid  lightblue'}}
    date="Problems? We see possibilities. Transforming challenges into triumphs, together."
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff', overflow: 'hidden' }}
    icon={<img style={{width:'100%', height: '100%'}} src={android7}/>}
  >
    <h3 className="vertical-timeline-element-title">Internet Of Things</h3>
    <a style={{color: 'black', font: '10px', textDecoration: 'underline'}} href="https://play.google.com/store/apps/details?id=com.ghummantech.iot"> Details </a>
    <p>
    Spark your smart home curiosity with IoT Guru: unlock the secrets of connected devices and become a tech-savvy wizard in no time.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date='From "meh" to "marvelous." Elevating everyday interactions into extraordinary moments.'
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff', overflow: 'hidden' }}
    icon={<img style={{width:'100%', height: '100%'}} src={android8}/>}
  >
    <h3 className="vertical-timeline-element-title">Human Organs</h3>
    <a style={{color: 'black', font: '10px', textDecoration: 'underline'}} href="https://play.google.com/store/apps/details?id=com.ghummantech.organs"> Details </a>
    <p>
    Embark on a fascinating journey inside your own body with Human Organs: your interactive guide to exploring the amazing machinery that keeps you running!
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    contentStyle={{ background: 'lightblue', color: 'black'}}
    contentArrowStyle={{ borderRight: '7px solid  lightblue'}}
    date="Innovation inspired by you. Every decision, every detail, fueled by your needs."
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff', overflow: 'hidden' }}
    icon={<img style={{width:'100%', height: '100%'}} src={android9}/>}
  >
    <h3 className="vertical-timeline-element-title">Learn Spanish</h3>
    <a style={{color: 'black', font: '10px', textDecoration: 'underline'}} href="https://play.google.com/store/apps/details?id=com.ghummantech.learnspanish"> Details </a>
    <p>
    Hola amigo! Ditch the textbooks and dive into Spanish fluency with Learn Spanish: your fun and interactive pocket tutor on the go.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="Not just customers, fans. Cheering you on, every step of the way."
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff', overflow: 'hidden' }}
    icon={<img style={{width:'100%', height: '100%'}} src={android10}/>}
  >
    <h3 className="vertical-timeline-element-title">Audio Video Screen Recorder</h3>
    <a style={{color: 'black', font: '10px', textDecoration: 'underline'}} href="https://play.google.com/store/apps/details?id=com.videoaudioscreenrecorder"> Details </a>
    <p>
    Capture your screen, voice, and gameplay in any form with the versatile Video Audio Screen Recorder: your all-in-one multimedia maestro.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    contentStyle={{ background: 'lightblue', color: 'black'}}
    contentArrowStyle={{ borderRight: '7px solid  lightblue'}}
    date="Your happiness is our highest priority. Period."
    iconStyle={{ background: 'lightblue', color: '#fff', overflow: 'hidden' }}
    icon={<ThreeDots/>}
  >
    <h3 className="vertical-timeline-element-title">More Projects</h3>
    <a style={{color: 'black', font: '10px', textDecoration: 'underline'}} href={androidAppsLink}> Have a look at more of our projects... </a>
  </VerticalTimelineElement>
</VerticalTimeline>
        </div>
    )
}

export default AndroidTimeline; 