import React from 'react';
import { Carousel, Container } from 'react-bootstrap';
import { sliderTimer } from './../Utils/constants';

import serviceSlide1 from '../../assets/serviceSlide1.png';
import serviceSlide2 from '../../assets/serviceSlide2.png';
import serviceSlide3 from '../../assets/serviceSlide3.png';
import serviceSlide4 from '../../assets/serviceSlide4.png';
import serviceSlide5 from '../../assets/serviceSlide5.png';

const ServiceSlider = () => {
    return (
        <div style={{ fontFamily: 'Times New Roman', objectFit: 'contain', overflow: 'hidden' }}>
          <Container>
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
              <div style={{ fontSize: '2em', fontWeight: 'bold' }}>Our Expertise Powers Your Success!</div>
              <p style={{ fontSize: '1.2em' }}>
                At Ghumman Tech, we leverage cutting-edge technology to empower educators. Whether it's creating apps for popular courses or providing secure and robust platforms, our capabilities ensure your teaching reaches its full potential.
              </p>
            </div>
            <Carousel>
              <Carousel.Item interval={sliderTimer}>
                <img style={{ width: "100%", height: "100%" }} src={serviceSlide1} alt="Image 1" />
              </Carousel.Item>
              <Carousel.Item interval={sliderTimer}>
                <img style={{ width: "100%", height: "100%" }} src={serviceSlide2} alt="Image 2" />
              </Carousel.Item>
              <Carousel.Item interval={sliderTimer}>
                <img style={{ width: "100%", height: "100%" }} src={serviceSlide3} alt="Image 3" />
              </Carousel.Item>
              <Carousel.Item interval={sliderTimer}>
                <img style={{ width: "100%", height: "100%" }} src={serviceSlide4} alt="Image 4" />
              </Carousel.Item>
              <Carousel.Item interval={sliderTimer}>
                <img style={{ width: "100%", height: "100%" }} src={serviceSlide5} alt="Image 5" />
              </Carousel.Item>
            </Carousel>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <div style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Unlock New Opportunities with Ghumman Tech.</div>
              <p style={{ fontSize: '1.2em' }}>
                From Cyber Security to IoT integration, our expertise supports innovative course creation. Partner with us and transform how knowledge is shared with the world.
              </p>
            </div>
          </Container>
        </div>
    )
}

export default ServiceSlider;