import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import FloatCart from "../FloatCart";
import Header from "../Header";
import Footer from "../Footer";
import HamburgerMenu from "../HamburgerMenu";
import { updateFilters } from "../../services/filters/actions";
import { fetchProduct } from "../../services/shelf/actions";
import { addProduct } from "../../services/cart/actions";
import { connect } from "react-redux";
import "./style.css";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";
import { FaCopy } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Carousel from "react-bootstrap/Carousel";
import Chip from "@mui/material/Chip";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Spinner from "../Spinner";
import {
  checkIfEligible,
  checkSubscriptionStatus,
} from "../Utils/eligibilityService";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import ChatIcon from '@mui/icons-material/Chat';
import { getBaseUrl } from "../Utils/getBaseUrl";

const Product = (props) => {
  console.log("product", props.products);
  const navigate = useNavigate();
  const { id } = useParams();
  const currentUrl = useLocation().pathname;

  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);
  const [availableSizes, setAvailableSizes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSizeIndex, setSelectedSizeIndex] = useState(0);
  const [selectedSizeName, setSelectedSizeName] = useState("");
  const [selectedKey, setSelectedKey] = useState(null);
  const [copied, setCopied] = useState(false);
  const [eligible, setEligible] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEmailError("");
    setMessageError("");
  };
  const handleSend = async () => {
    setIsLoading(true);

    const baseUrl = getBaseUrl();

    const dataRequest = {
      email: email.trim(),
      message: message.trim(),
      course_id: props.products[0].course_id,
      course_name: props.products[0].title
    };

    const response = await fetch(`${baseUrl}/api/product-question-for-owner`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataRequest),
    });

    if (response.ok) {
      toast.success("Message sent successfully");
    } else {
      toast.error("Failed to send message");
    }

    setIsLoading(false);
    handleClose();
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!validateEmail(e.target.value)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    if (e.target.value.length === 0) {
      setMessageError("Message cannot be empty");
    } else if (e.target.value.length > 1000) {
      setMessageError("Message cannot exceed 1000 characters");
    } else {
      setMessageError("");
    }
  };
  const { products } = props;

  const handleClick = (key) => {
    setSelectedKey(key);
  };

  const prevFilters = useRef(props.filters);

  const handleFetchProduct = (sku = sku) => {
    setIsLoading(true);
    props.fetchProduct(sku, () => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (prevFilters.current !== props.filters) {
      handleFetchProduct(id);
      prevFilters.current = props.filters;
    }
  }, [props.filters, handleFetchProduct, id]);

  const signMeIn = () => {
    navigate("/Login");
  };

  useEffect(() => {
    document.body.setAttribute("style", "overflow: visible; position: static;");
    const resultEmail = localStorage.getItem("email");
    if (resultEmail !== null && resultEmail !== "") {
      setEmail(resultEmail);
    }
    props.fetchProduct({ sku: id });
    fillAvailableSizes();
  }, [id]);

  useEffect(() => {
    const checkEligibility = async () => {
      const isMember = await checkSubscriptionStatus(email);
      if (!isMember || !products[0].included_in_membership) {
        const isEligible = await checkIfEligible(email, products[0].course_id);
        const courseOwner = products[0].owner === localStorage.getItem("email");
        setEligible(isEligible || courseOwner);
      } else if (isMember && products[0].included_in_membership) {
        setEligible(true);
      }
    };
    if (
      localStorage.getItem("email") &&
      products.length === 1 &&
      products[0].course_id
    ) {
      setEmail(localStorage.getItem("email"));
      checkEligibility();
    }
  }, [products, localStorage]);

  useEffect(() => {
    if (!menuHidden) {
      document.body.setAttribute(
        "style",
        "overflow: hidden; position: static;"
      );
    } else {
      document.body.setAttribute(
        "style",
        "overflow: visible; position: static;"
      );
    }
    return () => {
      document.body.setAttribute(
        "style",
        "overflow: visible; position: static;"
      );
    };
  }, [menuHidden]);

  const fillAvailableSizes = () => {
    const availableSizes = props.products[0]
      ? props.products[0].availableSizes
      : [];
    setAvailableSizes(availableSizes);
  };

  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };

  const isMenuOpen = (state) => {
    if (state.isOpen === false) {
      setMenuHidden(true);
    } else {
      setMenuHidden(false);
    }
    return state.isOpen;
  };

  const canItemBeAdded = (item) => {
    for (let i = 0; i < props.cartProducts.length; i++) {
      if (props.cartProducts[i].id === item.id) {
        return false;
      }
    }
    return true;
  };

  const notify = () => {
    toast("You already have added this product to the cart.");
  };

  const addThisProductToCart = (item) => {
    if (canItemBeAdded(item)) {
      const selectedSize =
        selectedSizeName ||
        (props.products[0] &&
          props.products[0].availableSizes &&
          props.products[0].availableSizes[0]);
      props.addProduct({ ...item, selectedSize, quantity: 1 });
    } else {
      notify();
    }
  };

  const sizeSelectedPressed = (productSize, index) => {
    setSelectedSizeName(productSize);
    setSelectedSizeIndex(index);
  };

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };

  const processDescription = (description) => {
    return description.replace(
      /\*\*(.*?)\*\*/g,
      '<b style="color: #ff0000;">$1</b>'
    );
  };

  let itemSizes;
  if (availableSizes != null) {
    itemSizes = availableSizes.map((item) => (
      <span key={item.index}> {item}&nbsp;&nbsp;</span>
    ));
  } else {
    itemSizes = null;
  }

  const productFormatWithCurrency = (item) => {
    return (
      <>
        {item.price === 0 ? null : (
          <div
            style={{
              textAlign: "center",
              fontWeight: "400",
              margin: "0 0 0.5em",
              lineHeight: "1.4",
              fontFamily: "auto",
            }}
          >
            <div>
              {item.price !== item.actualPrice && (
                <div>
                  🏷️ $
                  <span style={{ textDecoration: "line-through" }}>
                    <span> </span>
                    {item.actualPrice} USD
                  </span>
                </div>
              )}
            </div>
            $<span> </span>
            {item.price}
            <span> </span>
            USD per month
          </div>
        )}
        {item.included_in_membership === 1 && (
          <div
            style={{
              textAlign: "center",
              fontWeight: "400",
              margin: "0 0 0.5em",
              lineHeight: "1.4",
              fontFamily: "auto",
            }}
          >
            Included in Membership
          </div>
        )}
        {
          <div
            style={{
              display: "flex",
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
              marginTop: "10px",
              marginBottom: "10px", // Added spacing below
            }}
          >
            <Chip
              label={
                item.owner === "ghummantech@gmail.com" &&
                item.owner_name === "Ahmed Ghumman"
                  ? "Ghumman Tech"
                  : item.owner_name
              }
              style={{
                backgroundColor:
                  item.owner === "ghummantech@gmail.com" &&
                  item.owner_name === "Ahmed Ghumman"
                    ? "#f80103"
                    : "black",
                color: "white",
                padding: "5px 10px", // Adjusted padding for pill shape
                borderRadius: "20px", // Increased borderRadius for pill shape
              }}
            />
          </div>
        }
      </>
    );
  };

  let productDetails;

  if (products != null) {
    productDetails = products.map((item) => {
      return (
        <div>
          <div
            style={{
              textAlign: "center",
              fontWeight: "400",
              margin: "0 0 0.5em",
              lineHeight: "1.4",
              fontFamily: "auto",
              fontSize: "40px",
            }}
          >
            {item.title}
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            {item?.status === "active" && (
              <Chip
                label="Active"
                style={{
                  fontWeight: "bold",
                  backgroundColor: "green",
                  color: "white",
                }}
              />
            )}
            {item?.status === "completed" && (
              <Chip
                label="Expired"
                style={{
                  fontWeight: "bold",
                  backgroundColor: "red",
                  color: "white",
                }}
              />
            )}
            {item?.status === "soon" && (
              <Chip
                label="Comming Soon"
                style={{
                  fontWeight: "bold",
                  backgroundColor: "yellow",
                  color: "black",
                }}
              />
            )}
            {item?.price === 0 && (
              <Chip
                label="FREE !"
                style={{
                  fontWeight: "bold",
                  backgroundColor: "green",
                  color: "white",
                }}
              />
            )}
          </div>

          {productFormatWithCurrency(item)}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a style={{ display: "inline-block", borderTopStyle: "solid" }}>
              &ensp;&ensp;&ensp;
            </a>
          </div>
          {item.availableSizes[0] !== "standard" && (
            <div>
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "400",
                  margin: "0 0 0.5em",
                  lineHeight: "1.4",
                  fontFamily: "auto",
                }}
              >
                Available Sizes/Options
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "400",
                  margin: "0 0 0.5em",
                  lineHeight: "1.4",
                  fontFamily: "auto",
                }}
              >
                {item.availableSizes.map((productSize, index) => {
                  if (index == selectedSizeIndex) {
                    return (
                      <span>
                        {" "}
                        <span
                          style={{
                            border: "2px solid black",
                            padding: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            sizeSelectedPressed(productSize, index)
                          }
                        >
                          {productSize}
                        </span>
                        &nbsp;&nbsp;
                      </span>
                    );
                  } else {
                    return (
                      <span>
                        {" "}
                        <span
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            sizeSelectedPressed(productSize, index)
                          }
                        >
                          {productSize}
                        </span>
                        &nbsp;&nbsp;
                      </span>
                    );
                  }
                })}
                {itemSizes}
              </div>
            </div>
          )}
<div>
      <div
        style={{
          textAlign: "center",
          fontWeight: "400",
          margin: "0 0 0.5em",
          lineHeight: "1.4",
          fontFamily: "auto",
        }}
      >
        <Button variant="contained" color="primary" onClick={() => addThisProductToCart(item)}>
          ADD TO CART
        </Button>{" "}
        <Button variant="contained" color="secondary" onClick={handleClickOpen} style={{ marginLeft: "10px" }}>
          <ChatIcon /> Chat Now
        </Button>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Chat Now</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you have any questions for the owner/teacher of this product/course, please enter your email address and message for the owner/teacher. Owner/Teacher will get back to you shortly.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address *"
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={handleEmailChange}
            error={!!emailError}
            helperText={emailError}
          />
          <TextField
            margin="dense"
            id="message"
            label="Message"
            type="text"
            fullWidth
            variant="standard"
            multiline
            rows={4}
            value={message}
            onChange={handleMessageChange}
            error={!!messageError}
            helperText={messageError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSend} color="primary" disabled={!validateEmail(email) || message.length === 0 || message.length > 1000}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>

          {eligible && (
            <div
              style={{
                textAlign: "center",
                fontWeight: "400",
                margin: "0 0 0.5em",
                lineHeight: "1.4",
                fontFamily: "auto",
              }}
            >
              <Button
                variant="dark"
                onClick={() => navigate(`/Courses/${products[0].course_id}`)}
              >
                GO TO THE COURSE
              </Button>{" "}
            </div>
          )}

          <div
            style={{
              textAlign: "center",
              fontWeight: "400",
              margin: "2em",
              lineHeight: "1.4",
              fontFamily: "auto",
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: processDescription(item.description),
              }}
            />
          </div>

          {item.additionalInformation && (
            <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
              {Object.entries(item.additionalInformation).map(([key]) => (
                <Chip
                  key={key}
                  label={key}
                  variant="outlined"
                  onClick={() => handleClick(key)}
                  color={selectedKey === key ? "primary" : "default"}
                />
              ))}
              {selectedKey && (
                <div>
                  <p
                    style={{
                      backgroundColor: "lightgray",
                      borderRadius: "25px",
                      padding: "10px",
                    }}
                  >
                    {item.additionalInformation[selectedKey]}
                  </p>
                </div>
              )}
            </div>
          )}
          <div
            style={{
              textAlign: "center",
              fontWeight: "400",
              margin: "2em",
              lineHeight: "1.4",
              fontFamily: "auto",
            }}
          >
            SHARE&ensp;
            <FacebookShareButton
              url={"https://ghummantech.com" + currentUrl}
              quote={item.title}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            &ensp;
            <TwitterShareButton
              url={"https://ghummantech.com" + currentUrl}
              title={item.title}
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            &ensp;
            <CopyToClipboard
              text={"https://ghummantech.com" + currentUrl}
              onCopy={() => setCopied(true)}
            >
              <button
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <FaCopy size={32} />
              </button>
            </CopyToClipboard>
            {copied && <span>Link Copied</span>}
          </div>
        </div>
      );
    });
  }

  let showCarousel;
  if (props.products[0] != undefined) {
    showCarousel = (
      <Carousel>
        {props.products[0].images.map((image) => (
          <Carousel.Item key={image}>
            <img className="d-block w-100" src={image} alt="First slide" />
          </Carousel.Item>
        ))}
      </Carousel>
    );
  }

  return (
    <div id="outer-container">
      <HamburgerMenu
        isMenuOpenFunc={isMenuOpen}
        pageWrapId="page-wrap"
        outerContainerId="outer-container"
        isOpenStatus={menuHidden}
        history={props.history}
        callbackClose={callbackRoutingCloseMenu}
      />
      <div id="page-wrap">
        <Header
          openMenu={openMenuWindow}
          openCart={openFloatCart}
          signIn={signMeIn}
          history={props.history}
        />
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6">{showCarousel}</div>
              <div className="col-md-6">{productDetails}</div>
            </div>
            <div className="row justify-content-center">
              {products[0]?.video_url && (
                <div
                  style={{
                    position: "relative",
                    width: "90vw",
                    paddingTop: "56.25%",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "0",
                    margin: "0 auto",
                    marginTop: "20px",
                  }}
                >
                  <iframe
                    src={products[0]?.video_url}
                    frameBorder="0"
                    allowFullScreen
                    title="Chapter Video"
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        <FloatCart isTheCartOpen={isOpen} history={props.history} />
        <Footer history={props.history} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  products: state.shelf.products,
  filters: state.filters.items,
  authenticate: state.authenticate,
  cartProducts: state.cart.products,
  newProduct: state.cart.productToAdd,
  productToRemove: state.cart.productToRemove,
  productToChange: state.cart.productToChange,
  cartTotal: state.total.data,
  currency: state.currency.data,
});

const mapDispatchToProps = (dispatch) => ({
  login: (username, password) =>
    dispatch({ type: "UPDATE", username: username, password: password }),
  signout: () => dispatch({ type: "LOGOUT" }),
});

export default connect(mapStateToProps, {
  mapDispatchToProps,
  updateFilters,
  fetchProduct,
  addProduct,
})(Product);
