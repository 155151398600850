import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';
import CheckoutDetails from './CheckoutDetails';
import './style.css';


const Checkout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);

  useEffect(() => {
    if (!menuHidden) {
      document.body.setAttribute(
        "style",
        "overflow: hidden; position: static;"
      );
    } else {
      document.body.setAttribute(
        "style",
        "overflow: visible; position: static;"
      );
    }
    // Cleanup function to reset the body style when the component unmounts or when menuHidden changes again
    return () => {
      document.body.setAttribute(
        "style",
        "overflow: visible; position: static;"
      );
    };
  }, [menuHidden]);

  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };

  const isMenuOpen = (state) => {
    if (state.isOpen === false) {
      setMenuHidden(true);
    } else {
      setMenuHidden(false);
    }
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };

  return (
    <div id="outer-container">
      <HamburgerMenu
        isMenuOpenFunc={isMenuOpen}
        pageWrapId="page-wrap"
        outerContainerId="outer-container"
        isOpenStatus={menuHidden}
        callbackClose={callbackRoutingCloseMenu}
      />
      <div id="page-wrap">
        <Header openMenu={openMenuWindow} openCart={openFloatCart} />
        <CheckoutDetails />
        <Footer />
      </div>
    </div>
  );
};

export default Checkout;