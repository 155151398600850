import React, { useState, useEffect } from 'react';
import { getBaseUrl } from '../Utils/getBaseUrl';
import Spinner from '../Spinner';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Box from '@mui/material/Box';

const Results = ({ chapterContent }) => {

    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [toggleState, setToggleState] = useState('ALL');
    const userEmail = localStorage.getItem("email");

    useEffect(() => {
        const getResults = async () => {
            setIsLoading(true);
            const baseUrl = getBaseUrl();
            try {
                const dataRequest = {
                    course_id: chapterContent.course_id,
                    chapter_id: chapterContent.chapter_number,
                    user_email: localStorage.getItem("email"),
                };
                const response = await fetch(`${baseUrl}/api/user-results`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(dataRequest),
                });
                if (response.ok) {
                    const data = await response.json();
                    setResults(data);
                }
            } catch (error) {
                console.error('Error getting User Results:', error);
            } finally {
                setIsLoading(false);
            }
        };

        getResults();
    }, []);

    const filteredResults = toggleState === 'ALL' ? results : (results?.length ? results.filter(result => result.user_email) : []);

    return (
        <>
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                        <ToggleButtonGroup
                            color="primary"
                            value={toggleState}
                            exclusive
                            onChange={(event, newValue) => setToggleState(newValue)}
                        >
                            <ToggleButton value="ALL">ALL</ToggleButton>
                            <ToggleButton value="MINE">MINE</ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {(!filteredResults || filteredResults.length === 0) ? (
                            <p>No Results Uploaded</p>
                        ) : (
                            filteredResults.map((result, index) => (
                                <div key={index} style={{ margin: '20px', padding: '20px', border: `1px solid ${result.user_email === userEmail ? 'green' : 'black'}`, borderRadius: '10px', width: '50%', textAlign: 'center' }}>
                                    <h2 style={{ color: 'blue' }}>{result.name}</h2>
                                    <p style={{ color: 'green' }}>Score: {result.score} %</p>
                                    <p style={{ color: 'red' }}>{new Date(result.timestamp).toLocaleString()}</p>
                                </div>
                            ))
                        )}
                    </div>
                </>
            )}
        </>
    );
}

export default Results;