import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';


import FloatCart from '../FloatCart';
import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import { updateFilters } from '../../services/filters/actions';
import { fetchProducts } from '../../services/shelf/actions';
import { addProduct } from '../../services/cart/actions';


import { connect } from 'react-redux';

import Moment from "moment";
import { getBaseUrl } from '../Utils/getBaseUrl';

import './style.css';

import { useNavigate, useParams } from 'react-router-dom';

import {
  FacebookIcon as ShareFacebookIcon,
  TwitterIcon,
} from "react-share";

import Carousel from 'react-bootstrap/Carousel';

import CustomerHistoryTabPanel from './CustomerHistoryTabPanel';
import BookshelfTabPanel from './BookshelfTabPanel';
import CoursesTabPanel from './CoursesTabPanel';
import CoursesCommunityTabPanel from './CoursesCommunityTabPanel';
import SettingsTabPanel from './SettingsTabPanel/SettingsTabPanel';
import TeachingTabPanel from './TeachingTabPanel';

import Spinner from '../Spinner';
import { tabNumberMap } from './constants';

import { getUserInfo } from '../Utils/userServices';


const EMAIL = "email";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const MyAccount = (props) => {

  const navigate = useNavigate();
  const { tabName } = useParams();
  if (tabName) {
    if (!tabNumberMap[tabName]) {
      navigate('/MyAccount');
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);
  const [availableSizes, setAvailableSizes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customerHistoryItems, setCustomerHistoryItems] = useState([]);
  const [tabValue, setTabValue] = useState(tabNumberMap[tabName] || 0);
  const [eligibleBooks, setEligibleBooks] = useState([]);
  const [eligibleCourses, setEligibleCourses] = useState([]);
  const [eligibleCommunityCourses, setEligibleCommunityCourses] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const userEmail = localStorage.getItem("email") || "";
      if (!userEmail) {
        navigate({ pathname: "/123" });
      }
      // set user_id if it is not set
      const userId = localStorage.getItem("user_id");
      if (!userId) {
        const userData = await getUserInfo(userEmail);
        if (userData && userData.user_id) {
          localStorage.setItem("user_id", userData.user_id);
        }
      }
    };
    fetchData();
  }, []);



  const signMeIn = () => {
    navigate('/123');
  };

  useEffect(() => {
    if (!menuHidden) {
      document.body.setAttribute("style", "overflow: hidden; position: static;");
    } else {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    }
    // Cleanup function to reset the body style when the component unmounts or when menuHidden changes again
    return () => {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    };
  }, [menuHidden]);

  const checkIfEligible = async (email) => {
    try {
      const baseUrl = getBaseUrl();

      const dataRequest = {
        email: email
      };

      const response = await fetch(`${baseUrl}/api/eligible-books`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataRequest),
      });

      if (response.ok) {
        const responseData = await response.json();
        const eligibleBooks = responseData.eligibleBooks;
        setEligibleBooks(eligibleBooks);
      } else {
        setEligibleBooks([]);
      }
    } catch (e) {
      setEligibleBooks([]);
    }
  };
  const getProductDetails = async (email) => {
    try {
      const baseUrl = getBaseUrl();

      const dataRequest = {
        email: email,
      };

      const response = await fetch(`${baseUrl}/api/purchase-history`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataRequest),
      });

      if (response.ok) {
        const data = await response.json();
        setCustomerHistoryItems(data.purchaseHistory);
      }
    } catch (e) {
      console.error('Error fetching product details:', e);
    }
  };



  const getUserCourses = async (email) => {
    try {
      const baseUrl = getBaseUrl();

      const dataRequest = {
        user_email: email,
      };

      const response = await fetch(`${baseUrl}/api/all-user-courses`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataRequest),
      });

      if (response.ok) {
        const data = await response.json();
        setEligibleCourses(data);
      }
    } catch (e) {
      console.error('Error fetching user courses:', e);
    }
  };

  const getUserCommunityCourses = async (email) => {
    try {
      const baseUrl = getBaseUrl();

      const dataRequest = {
        user_email: email,
      };

      const response = await fetch(`${baseUrl}/api/all-user-community-courses`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataRequest),
      });

      if (response.ok) {
        const data = await response.json();
        setEligibleCommunityCourses(data);
      }
    } catch (e) {
      console.error('Error fetching user courses:', e);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        document.body.setAttribute("style", "overflow: visible; position: static;");
        const resultEmail = localStorage.getItem("email");
        await checkIfEligible(resultEmail);
        // await getUserCourses(resultEmail);
        if (resultEmail !== "") {
          await getUserCourses(resultEmail);
          await getUserCommunityCourses(resultEmail);
          await getProductDetails(resultEmail);
        } else {
          // User not signed in 
          navigate('/123');
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        document.body.setAttribute("style", "overflow: visible; position: static;");
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const fillAvailableSizes = () => {
    // Assuming products is a prop
    setAvailableSizes(props.products.availableSizes);
  };

  const openFloatCart = () => {
    setIsOpen(prevState => !prevState);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };

  const isMenuOpen = (state) => {
    if (!state.isOpen) {
      setMenuHidden(true);
    } else {
      setMenuHidden(false);
    }
    return state.isOpen;
  };



  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };


  Moment.locale("en");
  let itemSizes;
  if (availableSizes != null) {
    itemSizes = availableSizes.map((item) =>
      <span key={item.index}> {item}&nbsp;&nbsp;</span>
    )
  }
  else {
    itemSizes = null;
  }

  const { products } = props;


  const showCarouselItems = []
  let i = 1;
  if (props.products[0] != undefined) {
    for (i = 1; i <= 5; i++) {
      try {
        showCarouselItems.push(
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={require(`../../static/products/GhummanTech/${props.products[0].sku}_${i}.jpg`)}
              alt="First slide"
            />
          </Carousel.Item>
        )
      } catch (e) {

      }
    }
  }

  let showCarousel;
  if (props.products[0] != undefined) {
    showCarousel =
      <Carousel>

        {showCarouselItems}
      </Carousel>
  }



  return (
    <div id="outer-container">
      <HamburgerMenu isMenuOpenFunc={isMenuOpen} pageWrapId="page-wrap" outerContainerId="outer-container" isOpenStatus={menuHidden} history={props.history} callbackClose={callbackRoutingCloseMenu} />
      <div id="page-wrap">
        <Header openMenu={openMenuWindow} openCart={openFloatCart} signIn={signMeIn} history={props.history} />

        <h1 style={{ textAlign: "center", fontFamily: "Old Standard TT" }}>MY ACCOUNT</h1>
      </div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <a style={{ display: "inline-block", borderTopStyle: "solid" }}>
          &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
        </a>
      </div>
      < div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12" >
            {isLoading && <Spinner />}
            {!isLoading && <Box sx={{ width: '100%', typography: 'body1' }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
                variant="scrollable"
                scrollButtons="auto"
                style={{ overflowX: 'auto' }}
              >
                <Tab style={{ textAlign: "center", fontFamily: "Times New Roman", fontSize: "20px" }} label="ORDER HISTORY" />
                <Tab style={{ textAlign: "center", fontFamily: "Times New Roman", fontSize: "20px" }} label="BOOKSHELF" />
                <Tab style={{ textAlign: "center", fontFamily: "Times New Roman", fontSize: "20px" }} label="GT COURSES" />
                <Tab style={{ textAlign: "center", fontFamily: "Times New Roman", fontSize: "20px" }} label="COMMUNITY COURSES" />
                <Tab style={{ textAlign: "center", fontFamily: "Times New Roman", fontSize: "20px" }} label="SETTINGS" />
                <Tab style={{ textAlign: "center", fontFamily: "Times New Roman", fontSize: "20px" }} label="TEACHING" />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                <CustomerHistoryTabPanel customerHistoryItems={customerHistoryItems} />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <BookshelfTabPanel eligibleBooks={eligibleBooks} history={props.history} />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <CoursesTabPanel eligibleCourses={eligibleCourses} history={props.history} />
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <CoursesCommunityTabPanel eligibleCourses={eligibleCommunityCourses} history={props.history} />
              </TabPanel>
              <TabPanel value={tabValue} index={4}>
                <SettingsTabPanel />
              </TabPanel>
              <TabPanel value={tabValue} index={5}>
                <TeachingTabPanel/>
              </TabPanel>
            </Box>}

          </div>
        </div>
      </div>

      <FloatCart isTheCartOpen={isOpen} history={props.history} />
      <Footer history={props.history} />


    </div>
  );

}

const mapStateToProps = (state) => (
  {
    products: state.shelf.products,
    filters: state.filters.items,
    cartProducts: state.cart.products,
    newProduct: state.cart.productToAdd,
    productToRemove: state.cart.productToRemove,
    productToChange: state.cart.productToChange,
    cartTotal: state.total.data,
    currency: state.currency.data,
    authenticate: state
  })

const mapDispatchToProps = (dispatch) => ({
  login: (username, password) =>
    dispatch({ type: 'UPDATE', username: username, password: password }),
  signout: () => dispatch({ type: 'LOGOUT' }),
});

export default connect(
  mapStateToProps,
  { mapDispatchToProps, updateFilters, fetchProducts, addProduct }
)(MyAccount);
