import React from 'react';
import { Grid, Box } from '@mui/material';

const ChapterSelection = ({ totalChapters, selectedChapter, handleChapterClick }) => {
  return (
    <div className="chapters-container">
      <Grid container spacing={2} justifyContent="center">
        {Array.from({ length: totalChapters }, (_, index) => (
          <Grid item xs={3} sm={3} lg={1} key={index}>
            <Box
              className={`chapter-circle ${selectedChapter === index + 1 ? 'selected' : ''}`}
              onClick={() => handleChapterClick(index + 1)}
            >
              {index + 1}
            </Box>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ChapterSelection;