import React from 'react';


const AIORecorder = () => {
	return (
	<div id="outer-container">
        < div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12" >
		
              <div style={{
                textAlign: "center", fontWeight: "400", margin: "0 0 0.5em",
                lineHeight: "1.4", fontFamily: "auto", fontSize: "40px"
              }}>
                Audio Video Screen Recorder - All In One: Privacy Policy
              </div>

              <div style={{
                fontWeight: "400", margin: "0 0 0.5em",
                lineHeight: "1.4", fontFamily: "auto", fontSize: "20px"
              }}>
              <p> This app does not collect any user information. All the videos, audios and screen recordings stay on the user phone or shared by user using other apps.</p>
              <p> There is also a web server which can be used by user to share videos with users' other devices on the same wifi network.</p>
              <p> Following permissions are required. </p>
              <p> <b>Camera:</b> To turn on the front and back camera. </p>
              <p> <b>Audio:</b> To record user voice. </p>
              <p> <b>Storage:</b> To save and manage recordings. </p>
              <p> <b>Draw Over Other Apps:</b> So that user can continue recording when app is running background. </p>
              <p> <b>Internet:</b> For the local web server. </p>
              </div>

		</div>
		</div>
		</div>
		</div>


	)
}

export default AIORecorder; 
