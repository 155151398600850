import React from 'react';
import { Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

function FileInput({ formData, handleFileChange, errors }) {
  return (
    <div className="fileInputContainer">
      <p style={{fontWeight: 'bold'}}>You can upload new images, if you replace old images. You will lose your old images(each less than 1.8MB)</p>
      {formData.images.map((image, index) => (
        <div key={index} className="fileInputWrapper">
          <label htmlFor={`image_${index}`} className="fileInputLabel">
            <input
              type="file"
              name={`image_${index}`}
              accept="image/*"
              onChange={(e) => handleFileChange(e, index)}
              style={{ display: 'none' }}
              id={`image_${index}`}
            />
            <Button variant="contained" color="primary" component="span">
              {image ? <CloudUploadIcon className="uploadIcon" /> : '+'}
            </Button>
          </label>
        </div>
      ))}
      {errors.images && <span className="error">{errors.images}</span>}
    </div>
  );
}

export default FileInput;