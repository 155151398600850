import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import './style.css';

const CoursesTabPanel = ({ eligibleCourses, history }) => {
  const [selectedType, setSelectedType] = useState('All');
  const [distinctTypes, setDistinctTypes] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (eligibleCourses) {
      const types = ['All', ...new Set(eligibleCourses.map(course => course.type))];
      setDistinctTypes(types);
    }
  }, [eligibleCourses]);

  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const filteredCourses = eligibleCourses.filter(course => {
    const matchesType = selectedType === 'All' || course.type === selectedType;
    const matchesSearch = course.name.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesType && matchesSearch;
  });

  return (
    <div>
      <div className="filter-container">
        <DropdownButton id="dropdown-basic-button" title={`Courses: ${capitalizeFirstLetter(selectedType)}`}>
          {distinctTypes.map(type => (
            <Dropdown.Item key={type} onClick={() => handleTypeChange(type)}>
              {capitalizeFirstLetter(type)}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        <Form.Control
          type="text"
          placeholder="Search by Name"
          value={searchQuery}
          onChange={handleSearchChange}
          className="search-bar"
        />
      </div>
      {filteredCourses && filteredCourses.length > 0 ? (
        <div>
          <Container>
            <Row className="justify-content-center">
              {filteredCourses.map(course => (
                <Col key={course.course_id} xs={12} sm={6} md={4} lg={3}>
                  <Link to={`/Courses/${course.course_id}`}>
                    <Card border="success" style={{ width: '18rem', height: '25rem', padding: '10px' }}>
                      <Card.Img
                        variant="top"
                        width={100}
                        height={300}
                        src={`https://raw.githubusercontent.com/ghumman/public-small-videos/main/Courses/GT_Course_${course.course_id}.jpg`} />
                      <Card.Body style={{ textAlign: 'center' }}>
                        <Col>
                          <Row style={{ display: 'grid', placeItems: 'center', marginTop: '10px', fontWeight: 'bold' }}>
                            {course.name}
                          </Row>
                        </Col>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>You didn't buy any courses yet!</div>
      )}
    </div>
  );
}

export default CoursesTabPanel;