import React from 'react';
import Product from './Product';

const ProductList = ({ products, history, currency }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
      {(products ?? []).map(p => (
        <Product product={p} key={p.id} history={history} currency={currency} />
      ))}
    </div>
  );
};

export default ProductList;