import React from 'react';
import { userPrivacyPolicy } from './../../../Utils/constants'

const LearnLanguage = () => {
  return (
    <div id="outer-container">
      < div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12" >

            <div style={{
              textAlign: "center", fontWeight: "400", margin: "0 0 0.5em",
              lineHeight: "1.4", fontFamily: "auto", fontSize: "40px"
            }}>
              Learning Application - Privacy Policy
            </div>

            <div style={{
              fontWeight: "400", margin: "0 0 0.5em",
              lineHeight: "1.4", fontFamily: "auto", fontSize: "20px"
            }}>
              <p>{userPrivacyPolicy}</p>
            </div>

          </div>
        </div>
      </div>
    </div>


  )
}

export default LearnLanguage; 
