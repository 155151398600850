import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import FloatCart from '../FloatCart';
import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';
import { getBaseUrl } from '../Utils/getBaseUrl';

import "./style.css";
import Button from "react-bootstrap/Button";
import Spinner from "../Spinner";

const EmailConfirmation = (props) => {
  const navigate = useNavigate();
  const [serverMessage, setServerMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };

  const signMeIn = () => {
    navigate('/Login');
  };

  const isMenuOpen = (state) => {
    if (state.isOpen === false) {
      setMenuHidden(true);
    } else {
      setMenuHidden(false);
    }
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };

  useEffect(() => {
    const verifyEmail = async () => {
      const url = new URL(window.location.href);
      const token = url.searchParams.get('token');
      const email = url.searchParams.get('email');

      if (token && email) {
        try {
          const response = await fetch(`${getBaseUrl()}/api/verify-email`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token, email })
          });

          const data = await response.json();
          if (data.message === 'Email verified successfully') {
            setServerMessage('Thank you for confirming your email. You are all set.');
          } else {
            setServerMessage(data.message || data.error || 'An error occurred while verifying your email.');
          }
        } catch (err) {
          setServerMessage('An error occurred while verifying your email.');
        }
      } else {
        setServerMessage('Invalid verification link.');
      }
      setIsLoading(false);
    };

    verifyEmail();
  }, []);

  const mainApplication = () => {
    navigate({
      pathname: "/"
    });
  }

  return (
    <div id="outer-container">
      <HamburgerMenu
        isMenuOpenFunc={isMenuOpen}
        pageWrapId="page-wrap"
        outerContainerId="outer-container"
        isOpenStatus={menuHidden}
        history={props.history}
        callbackClose={callbackRoutingCloseMenu}
      />
      <div id="page-wrap">
        <Header
          openMenu={openMenuWindow}
          openCart={openFloatCart}
          signIn={signMeIn}
          history={props.history}
        />
        <div className="App">
          <header className="App-header">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <p style={{ textDecorationLine: 'underline' }}>Welcome to Ghumman Tech</p>
                <p>Email Confirmation</p>
                <p style={{ color: "red" }}>{serverMessage}</p>
                <Button onClick={() => mainApplication()}>
                  Back to Web Application
                </Button>
              </>
            )}
          </header>
        </div>
        <FloatCart isTheCartOpen={isOpen} history={props.history} />
        <Footer history={props.history} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => (
  {
    authenticate: state
  })

const mapDispatchToProps = (dispatch) => ({
  login: (email, password) =>
    dispatch({ type: "UPDATE", email: email, password: password }),
  signout: () => dispatch({ type: 'LOGOUT' }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailConfirmation);