import React, { useRef } from "react";

const Canvas = ({
  canvasRef,
  canvasWidth,
  isDrawingEnabled,
  selectedColor,
  socketRef,
  courseId,
  chapterNumber,
  sessionId,
  userId,
  isHost,
}) => {
  const lastDrawTimeRef = useRef(0);
  const throttleInterval = 50; // Adjust this value to control the throttling interval (in milliseconds)

  const handleMouseDown = (e) => {
    if (!isDrawingEnabled) return;

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.strokeStyle = selectedColor;
    context.lineWidth = 2;

    const rect = canvas.getBoundingClientRect();
    let startX = e.clientX - rect.left;
    let startY = e.clientY - rect.top;

    context.beginPath();
    context.moveTo(startX, startY);

    const handleMouseMove = (moveEvent) => {
      const now = Date.now();
      if (now - lastDrawTimeRef.current < throttleInterval) return;
      lastDrawTimeRef.current = now;

      const endX = moveEvent.clientX - rect.left;
      const endY = moveEvent.clientY - rect.top;

      context.lineTo(endX, endY);
      context.stroke();

      socketRef.current.send(
        JSON.stringify({
          type: "drawing",
          course_id: courseId,
          chapter_number: chapterNumber,
          session_id: sessionId,
          user_id: userId,
          is_host: isHost,
          drawing: {
            startX,
            startY,
            endX,
            endY,
            color: selectedColor,
            lineWidth: 2,
          },
        })
      );

      startX = endX;
      startY = endY;
    };

    const handleMouseUp = () => {
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("mouseup", handleMouseUp);
    };

    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("mouseup", handleMouseUp);
  };

  const handleTouchStart = (e) => {
    if (!isDrawingEnabled) return;

    e.preventDefault();

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.strokeStyle = selectedColor;
    context.lineWidth = 2;

    const rect = canvas.getBoundingClientRect();
    const touch = e.touches[0];
    let startX = touch.clientX - rect.left;
    let startY = touch.clientY - rect.top;

    context.beginPath();
    context.moveTo(startX, startY);

    const handleTouchMove = (moveEvent) => {
      moveEvent.preventDefault();

      const now = Date.now();
      if (now - lastDrawTimeRef.current < throttleInterval) return;
      lastDrawTimeRef.current = now;

      const touchMove = moveEvent.touches[0];
      const endX = touchMove.clientX - rect.left;
      const endY = touchMove.clientY - rect.top;

      context.lineTo(endX, endY);
      context.stroke();

      socketRef.current.send(
        JSON.stringify({
          type: "drawing",
          course_id: courseId,
          chapter_number: chapterNumber,
          session_id: sessionId,
          user_id: userId,
          is_host: isHost,
          drawing: {
            startX,
            startY,
            endX,
            endY,
            color: selectedColor,
            lineWidth: 2,
          },
        })
      );

      startX = endX;
      startY = endY;
    };

    const handleTouchEnd = () => {
      canvas.removeEventListener("touchmove", handleTouchMove);
      canvas.removeEventListener("touchend", handleTouchEnd);
    };

    canvas.addEventListener("touchmove", handleTouchMove, { passive: false });
    canvas.addEventListener("touchend", handleTouchEnd);
  };

  return (
    <canvas
      ref={canvasRef}
      width={canvasWidth}
      height="500"
      style={{ border: "1px solid black", marginTop: "20px" }}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
    ></canvas>
  );
};

export default Canvas;
