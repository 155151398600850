import React from 'react';

const ExchangeRefundPolicy = () => (
  <div style={{ fontFamily: 'Times New Roman', padding: '20px' }}>
    <h2 style={{ fontFamily: 'Times New Roman', fontSize: '26px', fontWeight: 'bold' }}>Flexible Exchange &amp; Refund Policy</h2>
    <p style={{ fontFamily: 'Times New Roman', fontSize: '18px' }}>We prioritize your satisfaction, so here's our hassle-free approach to exchanges and refunds. Don't hesitate to reach out if you need assistance!</p>

    <section style={{ marginTop: '20px' }}>
      <h3 style={{ fontFamily: 'Times New Roman', fontSize: '22px', fontWeight: 'bold' }}>Exchanges</h3>
      <ul style={{ fontFamily: 'Times New Roman', fontSize: '18px' }}>
        <li>Have concerns? You can exchange any unused or undamaged online course, book, seminar, or membership/subscription within 30 days for a full refund or store credit.</li>
        <li>Just send us an email at <a href="mailto:admin@ghummantech.com">admin@ghummantech.com</a> with your order number and desired exchange item (if applicable).</li>
        <li>We'll handle everything! We'll process your exchange promptly.</li>
      </ul>
    </section>

    <section style={{ marginTop: '20px' }}>
      <h3 style={{ fontFamily: 'Times New Roman', fontSize: '22px', fontWeight: 'bold' }}>Refunds</h3>
      <ul style={{ fontFamily: 'Times New Roman', fontSize: '18px' }}>
        <li>Not completely satisfied? You have 14 days after purchasing the item to request a full refund.</li>
        <li>Simply drop us an email at <a href="mailto:admin@ghummantech.com">admin@ghummantech.com</a> explaining your reason for return.</li>
        <li>We'll understand! We'll review your request and process your refund promptly.</li>
        <li>Once the request is approved, we'll issue a full refund to your original payment method.</li>
      </ul>
    </section>

    <section style={{ marginTop: '20px' }}>
      <h3 style={{ fontFamily: 'Times New Roman', fontSize: '22px', fontWeight: 'bold' }}>Exceptions</h3>
      <p style={{ fontFamily: 'Times New Roman', fontSize: '18px' }}>Please note that this policy doesn't apply to:</p>
      <ul style={{ fontFamily: 'Times New Roman', fontSize: '18px' }}>
        <li>Items bought on sale or clearance.</li>
        <li>Personalized or customized items.</li>
        <li>Items that have been used or accessed.</li>
      </ul>
    </section>

    <p style={{ fontFamily: 'Times New Roman', fontSize: '18px', marginTop: '20px' }}>We value your trust and believe in fairness. If you have any concerns, please don't hesitate to contact us. We're always happy to help!</p>
  </div>
);

export default ExchangeRefundPolicy;