import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  CircularProgress,
  IconButton,
  Button,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import QuizCard from "./QuizCard";
import ChapterSelection from "./ChapterSelection";
import { getBaseUrl } from "../Utils/getBaseUrl";
import "./style.css";
import { toast } from "react-toastify";

const EditChapter = ({ courseData, appFileData }) => {
  const navigate = useNavigate();
  const [selectedChapter, setSelectedChapter] = useState(1); // Default to the first chapter
  const [chapterContent, setChapterContent] = useState({});
  const [quizFileData, setQuizFileData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const initialChapterData = appFileData.data.find(
    (chapter) => chapter.customChapterNumber === selectedChapter
  );

  const [chapterName, setChapterName] = useState(
    initialChapterData ? initialChapterData.customChapterName : ""
  );
  const [chapterDescription, setChapterDescription] = useState(
    initialChapterData ? initialChapterData.customChapterDescription : ""
  );
  const [chapterVideo, setChapterVideo] = useState(
    appFileData[`chapter${selectedChapter}Video`] || ""
  );
  const [chapterText, setChapterText] = useState(
    appFileData[`chapter${selectedChapter}Text`] || ""
  );
  const [uploadedPdf, setUploadedPdf] = useState(null);
  const [metadataChapterChanged, setMetadataChapterChanged] = useState(false);
  const [metadataFileChanged, setMetadataFileChanged] = useState(false);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);

  const handleChapterClick = (chapter) => {
    setSelectedChapter(chapter);
    setChapterVideo(appFileData[`chapter${chapter}Video`] || "");
    setChapterText(appFileData[`chapter${chapter}Text`] || "");

    const chapterData = appFileData.data.find(
      (chapterData) => chapterData.customChapterNumber === chapter
    );
    setChapterName(chapterData ? chapterData.customChapterName : "");
    setChapterDescription(
      chapterData ? chapterData.customChapterDescription : ""
    );
  };

  const handleInputChange = (index, field, value) => {
    setQuizFileData((prevQuizFileData) => {
      const updatedQuizData = [...prevQuizFileData];
      updatedQuizData[index][field] = value;
      updatedQuizData[index].updated = true;
      return updatedQuizData;
    });
    setIsUpdateDisabled(false);
  };

  const handleDeleteQuestion = (index) => {
    setQuizFileData((prevQuizFileData) => {
      const updatedQuizData = [...prevQuizFileData];
      updatedQuizData[index].deleted = true;
      updatedQuizData[index].updated = true;
      return updatedQuizData;
    });
    setIsUpdateDisabled(false);
  };

  const handleAddQuestion = () => {
    const newQuestion = {
      course_id: courseData.id,
      chapter_id: selectedChapter,
      question: "",
      question_language: "english",
      answer: "",
      answer_language: "english",
      option1: "",
      option1_language: "english",
      option2: "",
      option2_language: "english",
      option3: "",
      option3_language: "english",
      id: null,
      slideAttachments: [],
      course_type: courseData?.type,
      deleted: false,
      updated: false,
    };

    setQuizFileData((prevQuizFileData) => [...prevQuizFileData, newQuestion]);
    setIsUpdateDisabled(false);
  };

  const handleUpdateChapter = async () => {
    try {
      setIsLoading(true);
      setIsUpdateDisabled(true);
      const baseUrl = getBaseUrl();
      const dataRequest = new FormData();
      dataRequest.append("user_email", localStorage.getItem("email"));
      dataRequest.append("course_id", courseData.id);
      dataRequest.append("course_url", courseData.app_data_url);
      dataRequest.append("chapter_number", selectedChapter);
      dataRequest.append(
        "quizFileData",
        JSON.stringify(
          quizFileData.filter((quiz) => quiz.updated || quiz.deleted)
        )
      );

      if (metadataChapterChanged) {
        dataRequest.append("metadata_chapter_changed", true);
        dataRequest.append("chapter_new_name", chapterName);
        dataRequest.append("chapter_new_description", chapterDescription);
      }

      if (metadataFileChanged) {
        dataRequest.append("metadata_file_changed", true);
        dataRequest.append(
          "chapter_new_video",
          JSON.stringify({
            [`chapter${selectedChapter}Video`]: chapterVideo,
          })
        );
        if (uploadedPdf) {
          dataRequest.append("chapter_new_pdf", uploadedPdf);
        }
      }

      const updateChapterResponse = await fetch(
        `${baseUrl}/api/update-chapter`,
        {
          method: "POST",
          body: dataRequest,
        }
      );

      if (updateChapterResponse.ok) {
        toast.success("Chapter is updated.");
        navigate(`/Courses/${courseData.id}`);
      } else {
        toast.error("Failed to update chapter.");
      }
    } catch (error) {
      console.error("Error updating the chapter:", error);
      toast.error("Error updating the chapter.");
    } finally {
      setIsLoading(false);
      setIsUpdateDisabled(false);
    }
  };

  const validateForm = () => {
    if (!chapterName || !chapterDescription) {
      return false;
    }
    for (const quiz of quizFileData) {
      if (
        !quiz.question ||
        !quiz.answer ||
        !quiz.option1 ||
        !quiz.option2 ||
        !quiz.option3
      ) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    const getAppQuizContent = async () => {
      try {
        setIsLoading(true);

        if (!selectedChapter) {
          setSelectedChapter(1);
        }
        if (appFileData) {
          const chapter = appFileData.data.find((chapter) =>
            selectedChapter
              ? chapter.customChapterNumber === selectedChapter
              : chapter.customChapterNumber === 1
          );
          if (chapter) {
            const chapterNumber = selectedChapter || 1;
            setChapterContent({
              course_id: courseData.id,
              name: chapter.customChapterName,
              chapter_number: chapterNumber,
              study_text_link: appFileData[`chapter${chapterNumber}Text`],
              isFileCourse: true,
              video_link: appFileData[`chapter${chapterNumber}Video`],
              course_language: courseData.name.split(" ")[1].toLowerCase(),
              meeting_details: appFileData[`meetingDetails`],
            });
          }

          const newQuizData = appFileData.data
            .filter(
              (chapter) => chapter.customChapterNumber === selectedChapter
            )
            .map((chapter) => {
              return {
                course_id: courseData.id,
                chapter_id: selectedChapter,
                question: chapter.slideQuestion,
                question_language: chapter?.slideQuestionLanguage || "english",
                answer: chapter.slideAnswer,
                answer_language: chapter?.slideAnswerLanguage || "english",
                option1: chapter.slideWrongOption1,
                option1_language:
                  chapter?.slideWrongOption1Language || "english",
                option2: chapter.slideWrongOption2,
                option2_language:
                  chapter?.slideWrongOption2Language || "english",
                option3: chapter.slideWrongOption3,
                option3_language:
                  chapter?.slideWrongOption3Language || "english",
                id: chapter.customFlashcardNumber,
                slideAttachments: chapter.slideAttachments,
                course_type: courseData?.type,
                deleted: false,
                updated: false,
              };
            })
            .filter((quiz) => !quiz.deleted);
          setQuizFileData(newQuizData, courseData);
        }
      } catch (error) {
        console.error("Error setting up bookmark:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getAppQuizContent();
  }, [selectedChapter]);

  useEffect(() => {
    setIsUpdateDisabled(!validateForm());
  }, [chapterName, chapterDescription, quizFileData]);

  useEffect(() => {
    setIsUpdateDisabled(!validateForm());
  }, []);

  const handlePdfUpload = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      file.type === "application/pdf" &&
      file.size <= 2 * 1024 * 1024
    ) {
      setUploadedPdf(file);
      setChapterText(file.name);
      setMetadataFileChanged(true);
      setIsUpdateDisabled(false);
    } else {
      toast.error("Please upload a valid PDF file not greater than 2 MB.");
    }
  };

  const totalChapters =
    courseData.type === "programming" || courseData.type === "language"
      ? courseData?.number_of_chapters
      : appFileData?.totalChapters;

  return (
    <div>
      <h3>Edit Chapter</h3>
      <ChapterSelection
        totalChapters={totalChapters}
        selectedChapter={selectedChapter}
        handleChapterClick={handleChapterClick}
      />
      <TextField
        label="Chapter Name"
        value={chapterName}
        onChange={(e) => {
          setChapterName(e.target.value);
          setMetadataChapterChanged(true);
          setIsUpdateDisabled(false);
        }}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Chapter Description"
        value={chapterDescription}
        onChange={(e) => {
          setChapterDescription(e.target.value);
          setMetadataChapterChanged(true);
          setIsUpdateDisabled(false);
        }}
        fullWidth
        margin="normal"
        multiline
        rows={4}
        maxRows={10}
      />
      <TextField
        label="Chapter Video"
        value={chapterVideo}
        onChange={(e) => {
          setChapterVideo(e.target.value);
          setMetadataFileChanged(true);
          setIsUpdateDisabled(false);
        }}
        fullWidth
        margin="normal"
      />
      <input
        type="file"
        accept="application/pdf"
        style={{ display: "none" }}
        id="pdf-upload"
        onChange={handlePdfUpload}
      />
      <label htmlFor="pdf-upload">
        <Button variant="contained" component="span" fullWidth margin="normal">
          {chapterText ? "Change PDF" : "Upload PDF"}
        </Button>
      </label>

      {isLoading ? (
        <div className="loading-spinner">
          <CircularProgress />
        </div>
      ) : (
        <div className="quiz-content">
          <Grid container spacing={2}>
            {quizFileData.map((quiz, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <QuizCard
                  quiz={quiz}
                  index={index} // Pass the actual index
                  handleInputChange={handleInputChange}
                  handleDeleteQuestion={handleDeleteQuestion}
                />
              </Grid>
            ))}
          </Grid>
          <div className="add-question-button">
            <IconButton color="primary" onClick={handleAddQuestion}>
              <AddIcon />
            </IconButton>
          </div>
          <div className="update-button">
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateChapter}
              disabled={isLoading || isUpdateDisabled}
            >
              Update
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate(`/Courses/${courseData.id}`)}
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditChapter;