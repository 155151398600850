import React from 'react';
import { Link } from 'react-scroll';

const ServiceIntroductionLink = () => {
    return (
        <div className="homepage-container" style={{ fontFamily: 'Times New Roman' }}>
          <div style={{ fontSize: '2em', fontWeight: 'bold' }}>Free Mobile App Creation Opportunity For Popular Courses</div>
          <p style={{ fontSize: '1.3em' }}>
            At Ghumman Tech, we are committed to helping educators and creators amplify their reach and impact. To celebrate excellence in teaching, we offer a unique opportunity: if you have a popular paid course on our platform, we’ll develop a free Android and iOS app dedicated to your course. This app will enhance accessibility and engagement, giving your content the spotlight it deserves.
          </p>
          <div style={{ fontSize: '1.7em', fontWeight: 'bold' }}>Why Partner with Us?</div>
          <ul style={{ fontSize: '1.3em' }}>
            <li><strong>Expand Your Reach:</strong> A personalized app ensures your course is available to learners on the go.</li>
            <li><strong>Reward for Excellence:</strong> Popular courses get the recognition and tools to grow further.</li>
            <li><strong>Seamless Integration:</strong> Our apps provide an immersive learning experience tailored to your teaching style.</li>
          </ul>
          <div style={{ fontSize: '1.7em', fontWeight: 'bold' }}>Transform Learning Together</div>
          <p style={{ fontSize: '1.3em' }}>
            Join us in creating transformative educational experiences. With our support, instructors can focus on what they do best—teaching—while we handle the tech side, ensuring a smooth and professional app launch.
          </p>
          <div style={{ fontSize: '1.7em', fontWeight: 'bold' }}>Courses We’re Proud of</div>
          <p style={{ fontSize: '1.3em' }}>
            Here are some successful courses which we transformed into Android and iOS apps for our teachers for free.
          </p>
          <Link style={{ backgroundColor: "black", borderRadius: "25px", padding: "10px", color: "white", cursor: "pointer", margin: "20px" }} to="contact-form" spy={true} smooth={true} offset={50} duration={500}>
            Contact Us
          </Link>
        </div>
    )
}

export default ServiceIntroductionLink;