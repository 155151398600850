import React from 'react';

const IqbalDemystifiedPolicy = () => {
  return (
    <div id="outer-container">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">

            <div style={{
              textAlign: "center", fontWeight: "400", margin: "0 0 0.5em",
              lineHeight: "1.4", fontFamily: "auto", fontSize: "40px"
            }}>
              Iqbal Demystified - Privacy Policy
            </div>

            <div style={{
              fontWeight: "400", margin: "0 0 0.5em",
              lineHeight: "1.4", fontFamily: "auto", fontSize: "20px"
            }}>
              <p>Welcome to Iqbal Demystified. We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines our practices regarding the collection, use, and protection of your information when you use our mobile application.</p>

              <h3>Information We Collect</h3>
              <p>When you use Iqbal Demystified, we may collect the following information:</p>
              <ul>
                <li><strong>Name:</strong> To personalize your experience within the app.</li>
                <li><strong>Username:</strong> To uniquely identify you within the app.</li>
                <li><strong>Password:</strong> To secure your account and protect your personal information.</li>
              </ul>

              <h3>How We Use Your Information</h3>
              <p>We use the information we collect for the following purposes:</p>
              <ul>
                <li><strong>Account Management:</strong> To create and manage your user account.</li>
                <li><strong>User Contributions:</strong> To allow you to write comments and contribute to discussions within the app.</li>
                <li><strong>Security:</strong> To ensure the security of your account and prevent unauthorized access.</li>
              </ul>

              <h3>Information Sharing and Disclosure</h3>
              <p>We do not sell, trade, or otherwise transfer your personal information to outside parties, except as required by law or to protect our rights. Your information is used solely for the purposes outlined in this policy.</p>

              <h3>Data Security</h3>
              <p>We implement a variety of security measures to maintain the safety of your personal information. These measures include encryption and secure storage protocols to protect your data from unauthorized access.</p>

              <h3>Your Rights</h3>
              <p>You have the right to access, correct, or delete your personal information at any time. To exercise these rights, please contact us at admin@ghummantech.com.</p>

              <h3>Changes to This Privacy Policy</h3>
              <p>We may update this Privacy Policy from time to time. Any changes will be reflected with a new effective date and will be communicated to you via the app or email.</p>

              <h3>Contact Us</h3>
              <p>If you have any questions or concerns about this Privacy Policy, please contact us at admin@ghummantech.com.</p>

              <p>Thank you for using Iqbal Demystified. We are committed to protecting your privacy and ensuring a secure and enjoyable experience within our app.</p>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default IqbalDemystifiedPolicy;
