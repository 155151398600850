import React, { useState } from "react";
import { TextField, Button, Typography, Container, Box, CircularProgress } from "@mui/material";
import './Register.css';

const Register = ({ formFirstName, formLastName, formEmail, formPassword, formPassword2, handleChangeFirstName, handleChangeLastName, handleChangeEmail, handleChangePassword, handleChangePassword2, handleSubmitRegister, setStage, signInUsingGoogle }) => {
  const [loading, setLoading] = useState(false);

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    await handleSubmitRegister(e);
    setLoading(false);
  };

  const handleGoogleSignIn = async () => {
    setLoading(true);
    await signInUsingGoogle();
    setLoading(false);
  };

  return (
    <Container className="auth-container" maxWidth="xs">
      <Box className="auth-box">
        <Typography variant="h4" className="auth-title">Join Us</Typography>
        <Typography variant="body1" className="auth-subtitle">We are glad you are going to become a Ghumman Tech User</Typography>
        <form onSubmit={handleRegister} className="auth-form">
          <TextField
            label="First Name"
            fullWidth
            margin="normal"
            variant="outlined"
            type="text"
            value={formFirstName}
            placeholder="Enter First Name"
            onChange={handleChangeFirstName}
            disabled={loading}
          />
          <TextField
            label="Last Name"
            fullWidth
            margin="normal"
            variant="outlined"
            type="text"
            value={formLastName}
            placeholder="Enter Last Name"
            onChange={handleChangeLastName}
            disabled={loading}
          />
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            type="email"
            value={formEmail}
            placeholder="Enter Email"
            onChange={handleChangeEmail}
            label="Email"
            disabled={loading}
          />
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            type="password"
            value={formPassword}
            placeholder="Password"
            onChange={handleChangePassword}
            label="Password"
            disabled={loading}
          />
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            type="password"
            value={formPassword2}
            placeholder="Password (again)"
            onChange={handleChangePassword2}
            label="Confirm Password"
            disabled={loading}
          />
          <Button fullWidth variant="contained" color="primary" type="submit" className="auth-button" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Register'}
          </Button>
          <Button fullWidth variant="text" color="secondary" onClick={() => setStage('login')} style={{marginTop: '10px'}} disabled={loading}>
            Cancel
          </Button>
          <Button fullWidth variant="contained" color="success" onClick={handleGoogleSignIn} style={{marginTop: '10px'}} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Sign in with Google'}
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default Register;