import { UPDATE,  LOGOUT} from './actionTypes';

const initialState = {
  items: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "UPDATE":
      return {
        ...state,
        loggedIn: true,
        user: action.username,
        userPassword: action.password
      };
      case "LOGOUT":
          return {
            ...state,
            loggedIn: false,
            user: "",
            userPassword: ""

          };
    default:
      return {
        ...state,
        loggedIn: false,
        user: "",
        userPassword: ""
      }
  }
}
