import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

const BuyMembershipButton = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Button 
        variant="contained" 
        sx={{
          backgroundColor: '#f80103',
          color: 'white',
          '&:hover': {
            backgroundColor: '#d70000',
          },
        }} 
        onClick={() => navigate('/BuyMembership')}
      >
        Buy membership
      </Button>
    </div>
  );
};

export default BuyMembershipButton;