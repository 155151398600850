import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Image } from 'react-bootstrap';
import logo from '../../assets/gtLogoHeader.jpg'
import MenuIcon from '@mui/icons-material/Menu';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

import { updateCurrency } from '../../services/currency/actions';
import { connect } from 'react-redux';

import './style.css';
import { Link, useNavigate } from 'react-router-dom';

import { useMediaQuery } from 'react-responsive';
import BuyMembershipButton from './BuyMembershipButton';
import GhummanRoboTrialButton from './GhummanRoboTrialButton';
import SignInPopup from './SignInPopup';

const Header = (props) => {
  console.log("Header props", props)
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [showSignInPopup, setShowSignInPopup] = useState(false);
  const isDesktopOrLarger = useMediaQuery({ minWidth: 768 });

  useEffect(() => {
    const resultEmail = localStorage.getItem("email");
    setEmail(resultEmail);
  }, [showSignInPopup]);

  useEffect(() => {
    if (props.showSignInPopup) {
      setShowSignInPopup(true);
      props.setShowSignInPopup(false);
    }
  }, [props.showSignInPopup]);

  const goToMyAccount = () => {
    navigate("/MyAccount");
  };

  const goToSignIn = () => {
    setShowSignInPopup(true);
  };

  const closeSignInPopup = () => {
    setShowSignInPopup(false);
  };

  let signinTag
  var signinMessageLocal = ""
  try {
    if (email) {
      signinMessageLocal = email.charAt(0).toUpperCase()
      signinTag = <div className='round-logo'><button type="button" onClick={() => goToMyAccount()}> {signinMessageLocal} </button></div>
    }
    else {
      signinMessageLocal = "Sign In"
      signinTag = <PersonOutlineIcon fontSize="large" onClick={() => goToSignIn()} />
    }
  } catch (e) {
    signinMessageLocal = "Sign In"
    signinTag = <PersonOutlineIcon fontSize="large" onClick={() => goToSignIn()} />
  }

  const breadcrumbs = location.pathname.split('/').slice(1);
  const formatCrumb = (crumb) => {
    if (crumb.startsWith('GT')) {
      return crumb;
    }

    return crumb
      .split(/(?=[A-Z])/) // Split on uppercase letters
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
      .join(' '); // Join words with spaces
  };

  return (
    <div>
      {showSignInPopup && <div class="popup-window"><SignInPopup closePopup={closeSignInPopup} /></div>}
      <Box
        display="flex"
        justifyContent="space-around"
        p={1}
        m={1}
      >

        <Box
          p={1}
          m={1}
          justifyContent={'center'}
          display="flex" // Add this line
          alignItems="center" // Add this line
        >
          <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
            <div>
              <Link to="/">
                <Image alt="GT Logo" src={logo} style={{ width: '70px', height: 'auto' }} />
              </Link>
            </div>
            {isDesktopOrLarger && (<div>
              {breadcrumbs.length > 0 && (
                <ul style={{ display: 'flex', listStyle: 'none', padding: 0, margin: 0 }}>
                  {breadcrumbs.map((crumb, index) => (
                    <li key={index} style={{ display: 'flex', alignItems: 'center' }}>
                      <Link
                        to={`/${breadcrumbs.slice(0, index + 1).join('/')}`}
                        style={{ textDecoration: 'underline', color: 'blue' }}
                      >
                        {formatCrumb(crumb)}
                      </Link>
                      {index < breadcrumbs.length - 1 && ( // Add arrow after crumb except the last one
                        <span style={{ marginLeft: '5px', marginRight: '5px' }}>&rarr;</span>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>)}
          </div>
        </Box>
        {isDesktopOrLarger && (
          <Box
            p={1}
            m={1}
            justifyContent={'center'}
            display="flex" // Add this line
            flexDirection={'column'}
            alignItems="center" // Add this line
          >
            <div className='header-title' style={{ color: 'rgb(254, 1, 0)', fontWeight: 'bold', fontFamily: 'Times New Roman' }}>
              GHUMMAN TECH
            </div>
            <div className='header-subtitle'>
              Empowering Lifelong Learners
            </div>
          </Box>
        )}
        <Box
          display="flex"
          justifyContent="space-between"
          p={1}
          m={1}
        >
          <Box p={1}>
            <MenuIcon fontSize="large" onClick={props.openMenu} style={{ cursor: 'pointer' }} />
          </Box>
          <Box p={1}>
            <h3>
              <ShoppingCartIcon fontSize="large" onClick={props.openCart} style={{ cursor: 'pointer' }} />
            </h3>
          </Box>
          <Box p={1}>
            <h3>{signinTag}</h3>
          </Box>
          {isDesktopOrLarger && (
            <>
              <Box p={1}>
                <BuyMembershipButton />

              </Box>
              <Box p={1}>
                <GhummanRoboTrialButton />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </div>
  );
}

const mapStateToProps = (state) => (
  {
    currency: state.currency.data
  })

export default connect(
  mapStateToProps,
  { updateCurrency }
)(Header);