import React from 'react';
import { Carousel, Container } from 'react-bootstrap';
import {sliderTimer} from './../Utils/constants';

import serviceSlide1 from '../../assets/seminarSlide1.jpg';
import serviceSlide2 from '../../assets/seminarSlide2.jpg';
import serviceSlide3 from '../../assets/seminarSlide3.jpg';

const GenericSeminarsSlider = () => {
    return (
        <div style={{ objectFit: 'contain', overflow: 'hidden' }}>
          <Container>
        <Carousel>
          <Carousel.Item interval={sliderTimer}>
            <img style={{ width: "100%", height: "100%" }} src={serviceSlide1} alt="Image 1" />
          </Carousel.Item>
          <Carousel.Item interval={sliderTimer}>
            <img style={{ width: "100%", height: "100%" }} src={serviceSlide2} alt="Image 2" />
          </Carousel.Item>
          <Carousel.Item interval={sliderTimer}>
            <img style={{ width: "100%", height: "100%" }} src={serviceSlide3} alt="Image 3" />
          </Carousel.Item>
        </Carousel>
        </Container>
      </div>
    )
}
export default GenericSeminarsSlider; 