import React from "react";
import "./ParticipantsList.css"; // Import the CSS file

const ParticipantsList = ({ users, host }) => {
  return (
    <div className="participants-container">
      <h3>Host: {host.host_name}</h3>
      {users.length > 0 && <h4>Participants:</h4>}
      <ul className="participants-list">
        {users.map((user) => (
          <li key={user.user_id} className="participant-item">
            {user.user_name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ParticipantsList;
