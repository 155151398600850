import { UPDATE_CURRENCY } from './actionTypes';

const initialState = {
  data: {
    currency: 'PK'
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CURRENCY:
      return {
        ...state,
        data: action.payload
      };
    default:
      return state;
  }
}
