import React, { useState, useEffect } from 'react';
import FloatCart from '../FloatCart';
import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { connect } from 'react-redux';
import './style.css';
import { InlineWidget } from "react-calendly";
import { useNavigate } from 'react-router-dom';

const Thankyou = (props) => {

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);
  const signMeIn = () => {
    navigate('/Login');
  };

  useEffect(() => {
    if (!menuHidden) {
      document.body.setAttribute("style", "overflow: hidden; position: static;");
    } else {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    }
    // Cleanup function to reset the body style when the component unmounts or when menuHidden changes again
    return () => {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    };
  }, [menuHidden]);

  useEffect(() => {
    document.body.setAttribute("style", "overflow: visible; position: static;");
  }, []);

  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };


  const isMenuOpen = (state) => {
    setMenuHidden(!state.isOpen);
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };

  return (
    <div id="outer-container">
      <HamburgerMenu isMenuOpenFunc={isMenuOpen} pageWrapId="page-wrap" outerContainerId="outer-container" isOpenStatus={menuHidden} history={props.history} callbackClose={callbackRoutingCloseMenu} />
      <div id="page-wrap">
        <Header openMenu={openMenuWindow} openCart={openFloatCart} signIn={signMeIn} history={props.history} />

        <FloatCart isTheCartOpen={isOpen} history={props.history} />

        <h1 style={{ textAlign: "center", fontFamily: "Old Standard TT" }}>Thank you</h1>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <a style={{ display: "inline-block", borderTopStyle: "solid" }}>
            &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
          </a>
        </div>
        <div style={{ fontFamily: "serif", padding: "20px", textAlign: "center" }}>
          <Container>
            <Row>
              <Col>
                <p style={{ fontWeight: "bold" }}>THANK YOU FOR REACHING OUT!</p>
                <p>We'll be in touch within 24 hours</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <p style={{ fontWeight: "bold" }}>Schedule our technical review at your ease</p>
                <InlineWidget url="https://calendly.com/ghummanlinux/30min" />
              </Col>
            </Row>
          </Container>
        </div>
        <Footer history={props.history} />

      </div>
    </div>
  );

}

const mapStateToProps = (state) => (
  {
    authenticate: state.authenticate,
    cartProducts: state.cart.products,
    newProduct: state.cart.productToAdd,
    productToRemove: state.cart.productToRemove,
    productToChange: state.cart.productToChange,
    cartTotal: state.total.data
  })

const mapDispatchToProps = (dispatch) => ({
  login: (username, password) =>
    dispatch({ type: 'UPDATE', username: username, password: password }),
  signout: () => dispatch({ type: 'LOGOUT' }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Thankyou);

