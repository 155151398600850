import { FETCH_PRODUCTS, FETCH_PRODUCT } from './actionTypes';

const initialState = {
  products: [],
  total: 0,
  currentPage: 1,
  pageSize: 10,
  totalPages: 1,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return {
        ...state,
        products: action.payload.products,
        total: action.payload.total,
        currentPage: action.payload.currentPage,
        pageSize: action.payload.pageSize,
        totalPages: action.payload.totalPages,
      };
    case FETCH_PRODUCT:
      return {
        ...state,
        products: action.payload,
      };
    default:
      return state;
  }
}