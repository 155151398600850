import React from 'react';
import { TextField, Tooltip } from '@mui/material';
import './style.css';

function CourseFormFields({ formData, handleChange, errors }) {
  return (
    <>
      <Tooltip title="Enter the name of the course" arrow>
        <TextField
          label="Course Name *"
          name="courseName"
          placeholder="Course Name"
          value={formData.courseName}
          onChange={handleChange}
          className="textFieldCreateCourse"
          style={{ marginBottom: '20px' }}
        />
      </Tooltip>
      {errors.courseName && <span className="error">{errors.courseName}</span>}
      <Tooltip title="Enter a detailed description of the course" arrow>
        <TextField
          label="Course Description *"
          name="courseDescription"
          placeholder="Course Description"
          value={formData.courseDescription}
          onChange={handleChange}
          multiline
          rows={4}
          className="textFieldCreateCourse"
          style={{ marginBottom: '20px' }}
        />
      </Tooltip>
      {errors.courseDescription && <span className="error">{errors.courseDescription}</span>}
      <Tooltip title="Enter the timing of the course" arrow>
        <TextField
          label="Timing *"
          name="timing"
          placeholder="Timing"
          value={formData.timing}
          onChange={handleChange}
          className="textFieldCreateCourse"
          style={{ marginBottom: '20px' }}
        />
      </Tooltip>
      {errors.timing && <span className="error">{errors.timing}</span>}
      <Tooltip title="Enter the name of the instructor" arrow>
        <TextField
          label="Instructor *"
          name="instructor"
          placeholder="Instructor"
          value={formData.instructor}
          onChange={handleChange}
          className="textFieldCreateCourse"
          style={{ marginBottom: '20px' }}
        />
      </Tooltip>
      {errors.instructor && <span className="error">{errors.instructor}</span>}
      <Tooltip title="Enter the class meeting link (e.g., Zoom or Google Meet)" arrow>
        <TextField
          label="Class Meeting Link (e.g., Zoom or Google Meet) *"
          name="zoomLink"
          placeholder="Class Meeting Link"
          value={formData.zoomLink}
          onChange={handleChange}
          className="textFieldCreateCourse"
          style={{ marginBottom: '20px' }}
        />
      </Tooltip>
      {errors.zoomLink && <span className="error">{errors.zoomLink}</span>}
      <Tooltip title="Enter the monthly cost of the course" arrow>
        <TextField
          label="Monthly Cost ($) *"
          name="monthlyCost"
          placeholder="Monthly Cost ($)"
          type="number"
          value={formData.monthlyCost}
          onChange={handleChange}
          className="textFieldCreateCourse"
          inputProps={{ min: 0, max: 1000 }}
          style={{ marginBottom: '20px' }}
        />
      </Tooltip>
      {errors.monthlyCost && <span className="error">{errors.monthlyCost}</span>}
      <Tooltip title="Enter the promotional video link for the course" arrow>
        <TextField
          label="Promotional Video Link"
          name="courseVideoLink"
          placeholder="Promotional Video Link"
          value={formData.courseVideoLink}
          onChange={handleChange}
          className="textFieldCreateCourse"
          style={{ marginBottom: '20px', width: '400px' }} 
        />
      </Tooltip>
    </>
  );
}

export default CourseFormFields;