// Function to replace placeholders with images
export const replaceWithImages = (text, attachments, isDesktopOrLarger) => {
    if (!text) {
        return '';
    }
    return text.replace(/\[attach(\d+)\]/g, (match, index) => {
        const attachmentIndex = parseInt(index) - 1;
        if (attachments[attachmentIndex]) {
            return `<img style="width: ${isDesktopOrLarger ? '500px' : '200px'}; height: ${isDesktopOrLarger ? '500px' : '400px'}; object-fit: contain;" src="${attachments[attachmentIndex]}" alt="attachment${index}" />`;
        } else {
            return match;
        }
    });
};