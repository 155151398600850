import React, { useState } from 'react';
import { languages } from './languages'; // Import the languages object

const LanguageSelector = ({userLanguage, setUserLanguage, courseLanguage, setIsUserLanguageSelected}) => {

  const handleLanguageSelect = (language) => {
    setUserLanguage(language);
    setIsUserLanguageSelected(true);
  };

  return (
    <div style={{ 
      position: 'fixed', 
      zIndex: 9999, 
      top: '50%', 
      left: '50%', 
      transform: 'translate(-50%, -50%)', 
      backgroundColor: 'rgba(255, 255, 255, 0.8)', 
      padding: '20px', 
      borderRadius: '10px', 
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
      maxHeight: '100vh', // Set maximum height to viewport height
      overflowY: 'auto', // Enable vertical scrolling
      width: '100vw', // Set width to viewport width
    }}>
      <h2>Select Native Language</h2>
      <div>
        {Object.entries(languages).map(([language, code]) => (
          courseLanguage !== language && (
            <button
              key={language}
              onClick={() => handleLanguageSelect(language)}
              style={{ backgroundColor: userLanguage === language ? 'green' : 'white', color: userLanguage === language ? 'white' : 'black', padding: '10px', margin: '5px', border: 'none', borderRadius: '20px', cursor: 'pointer' }}
            >
              {language.charAt(0).toUpperCase() + language.slice(1)}
            </button>
          )
        ))}
      </div>
    </div>
  );
};

export default LanguageSelector;