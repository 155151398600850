import React, { useState, useEffect } from 'react';
import { getBaseUrl } from '../Utils/getBaseUrl';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";


import Alert from 'react-bootstrap/Alert';
import Fade from 'react-bootstrap/Fade';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import useRegisterSeminar from './useRegisterSeminar';

const PaypalCheckout = (props) => {

    const registerSeminar = useRegisterSeminar();

    const { seminar } = props;
    const [formData, setFormData] = useState({
        email: '',
        name: '',
        phone: '',
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCoupon, setIsLoadingCoupon] = useState(false);
    const [showPaypalButton, setShowPaypalButton] = useState(false);
    const [showRegistrationSuccess, setShowRegistrationSuccess] = useState(false);
    const [showRegistrationFailure, setShowRegistrationFailure] = useState(false);
    const [showCoupon, setShowCoupon] = useState(false);
    const [currentCoupon, setCurrentCoupon] = useState('');
    const [finalCoupon, setFinalCoupon] = useState(null);
    const [couponMessage, setCouponMessage] = useState('');
    const [currentAmount, setCurrentAmount] = useState(seminar.price);
    const [couponId, setCouponId] = useState(null);

    // const [show, setShow] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState("");
    const [orderID, setOrderID] = useState(false);

    useEffect(() => {
        if (showRegistrationFailure) {
            const timeoutId = setTimeout(() => {
                setShowRegistrationFailure(false);
            }, 2000); // 2 seconds in milliseconds

            return () => clearTimeout(timeoutId);
        }
    }, [showRegistrationFailure]);

    useEffect(() => {
        if (showRegistrationSuccess) {
            const timeoutId = setTimeout(() => {
                setShowRegistrationSuccess(false);
            }, 2000); // 2 seconds in milliseconds

            return () => clearTimeout(timeoutId);
        }
    }, [showRegistrationSuccess]);

    const clientId = process.env.REACT_APP_NOT_SECRET_CODE ?
        'AaqRauJRMhXufHghmDkMoi8wdZQxrnUWOatPhTXfwgC6wQaigkIyW5aki9DOm_uB_24MR1NKpzq-k-9d' :
        'ASu5ZETD_Z47u8oGvP_NgwPr5CtcgdYXqUauW267w4206BlaRmtzZzHencBt1pADeOsmB9TyUGcCull5';
    // creates a paypal order
    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    description: "Register for Seminar: " + seminar.title,
                    amount: {
                        currency_code: "USD",
                        value: currentAmount,
                    },
                },
            ],
        }).then((orderID) => {
            setOrderID(orderID);
            return orderID;
        });
    };

    // check Approval
    const onApprove = async (data, actions) => {
        try {
            const dataRequestRegister = {
                email: formData.email,
                name: formData.name,
                phone_number: formData.phone,
                seminar_id: seminar.id,
                coupon_id: couponId,
                amount_paid: currentAmount,
                payment_type: 'paypal'
            };
            const responseRegister = await registerSeminar(dataRequestRegister, formData.name);

            // Following condition when status is 200 will 
            // not happen anymore as above registerSeminar
            // function will push to thank you seminar page
            if (responseRegister.status === 200) {
                setShowRegistrationSuccess(true);
            } else {
                setShowRegistrationFailure(true);
            }
        } catch (error) {
            console.error('Error during capture or registration:', error);
            // Handle general errors
        }
    };


    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
        setErrors({}); // Clear any previous errors on change
        setShowPaypalButton(false);
    };

    const handleSubmitCoupon = async (event) => {

        try {
            setIsLoadingCoupon(true);


            event.preventDefault();
            const baseUrl = getBaseUrl();
            const dataRequestCoupon = {
                couponCode: currentCoupon,
                seminarId: seminar.id
            };

            const response = await fetch(`${baseUrl}/api/check-coupon`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(dataRequestCoupon),
            });
            const data = await response.json();

            if (response.status === 200) {
                const newAmount = currentAmount - data.couponDetails.discount;
                if (newAmount < 0) {
                    newAmount = 0;
                }
                setCurrentAmount(newAmount);
                setFinalCoupon(currentCoupon);
                setCouponId(data.couponDetails.id);
                setShowCoupon(true);
                setCouponMessage('Coupon Applied');
                setShowPaypalButton(false);
            } else {
                setCouponMessage("Coupon doesn't exist")
            }
        } catch (error) {
        } finally {
            setIsLoadingCoupon(false);
        }
    };

    const continuePaymentWithPaypal = async (event) => {

        event.preventDefault();
        // Basic sanity checks
        const validationErrors = {};
        if (!formData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            validationErrors.email = 'Please enter a valid email address.';
        }
        if (!formData.name) {
            validationErrors.name = 'Please enter your name.';
        }
        if (!formData.phone) {
            validationErrors.phone = 'Please enter your phone number.';
        }

        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            if (currentAmount > 0) {
                setShowPaypalButton(true);
            } else {
                await onApprove();
            }
        }
    };

    return (
        <PayPalScriptProvider options={{ "client-id": clientId }}>
            <div>


                <Container>
                    <Row>
                        <Col lg={6}>
                            <form onSubmit={continuePaymentWithPaypal}>
                                <fieldset>
                                    <legend>Contact Information</legend>
                                    <div className='userInfoSection'>
                                        <div>
                                            <label
                                                htmlFor="email">Email:</label>
                                            <input
                                                className="InputUserInfo"
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                            />
                                            {errors.email && <p

                                                className="error">{errors.email}</p>}
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="name">Name:</label>
                                            <input
                                                className="InputUserInfo"
                                                style={{ backgroundColor: 'white' }}
                                                type="text"
                                                id="name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                            />
                                            {errors.name && <p className="error">{errors.name}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="phone">Phone:</label>
                                            <input
                                                className="InputUserInfo"
                                                type="tel"
                                                id="phone"
                                                name="phone"
                                                value={formData.phone}
                                                onChange={handleChange}
                                            />
                                            {errors.phone && <p className="error">{errors.phone}</p>}
                                        </div>
                                    </div>
                                    <div className='buttonSection'>
                                        {!showPaypalButton && 
                                        <button 
                                            type='submit'
                                            style={{
                                                backgroundColor: '#0000ff',
                                                color: '#ffffff',
                                            }}
                                        >
                                            {currentAmount > 0 ? 'Pay' : 'Register'}
                                        </button>
                                        }
                                    </div>
                                </fieldset>
                            </form>
                            {showPaypalButton &&
                                <PayPalButtons
                                    style={{ layout: "vertical" }}
                                    createOrder={createOrder}
                                    onApprove={onApprove}
                                />}
                        </Col>
                        <Col lg={6} style={{padding: '20px'}}>
                            <form>
                                <Row className="d-flex justify-content-center">

                                    <Card className="text-center">
                                        <Card.Body>
                                            <Card.Title>Total Amount</Card.Title>
                                            <Card.Text>
                                                {currentAmount !== seminar.price ? (
                                                    <><del>{seminar.price} USD</del> <br /> {currentAmount} USD</>
                                                ) : (
                                                    <>{seminar.price} USD</>
                                                )}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Row>
                                <Row className="d-flex justify-content-center">
                                    <label>Coupon:</label>

                                    <input
                                        style={{ margin: '10px', backgroundColor: 'white' }}
                                        disabled={isLoadingCoupon || showCoupon}
                                        type="text"
                                        id="couponInput"


                                        name="coupon"


                                        value={currentCoupon}


                                        onChange={(event) => setCurrentCoupon(event.target.value)}
                                    />
                                </Row>
                                <Row>
                                    <Col></Col>
                                    <Col>
                                    

                                    <button 
                                    type="submit" 
                                    disabled={isLoadingCoupon || showCoupon} 
                                    onClick={handleSubmitCoupon}
                                    style={{
                                        backgroundColor: showCoupon ? '#808080' : '#0000ff',
                                        color: '#ffffff',
                                    }}
                                    >
                                        Apply
                                    </button>
                                    {couponMessage && <p className="success">{couponMessage}</p>}
                                    </Col>
                                    <Col></Col>

                                </Row>
                            </form>
                        </Col>
                    </Row>
                </Container>

                <Alert show={showRegistrationSuccess} variant="success">
                    We're thrilled you're joining! Look for an email with the class link shortly.
                </Alert>
                <Fade in={showRegistrationFailure}>
                    <Alert show={showRegistrationFailure} variant="danger" className="top-alert" onClose={() => setShowRegistrationFailure(false)} dismissible>
                        Payment Declined. Registration was not successful.
                    </Alert>
                </Fade>
                <Fade in={showRegistrationSuccess}>
                    <Alert show={showRegistrationSuccess} variant="success" className="top-alert" onClose={() => setShowRegistrationSuccess(false)} dismissible>
                        Registration completed Successfully. We're thrilled you're joining! Look for an email with the class link shortly.
                    </Alert>
                </Fade>
            </div>
        </PayPalScriptProvider>
    );
};

export default PaypalCheckout;