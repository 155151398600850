import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';

const SubscriptionDetails = ({ planType, seminar }) => (
    <Row>
        <Card className="text-center">
            <Card.Body>
                <Card.Title>First 7 Days on us</Card.Title>
                <Card.Title>Your card will not be charged for first 7 days.</Card.Title>
                <Card.Title>You can cancel your subscription free of charge anytime during the trial.</Card.Title>
                <Card.Title>After Trial Period</Card.Title>
                <Card.Title>Total Amount</Card.Title>
                <Card.Text>
                    {planType === 'Yearly' ? (
                        <p><del>9.99 USD / month</del> <br /> {seminar.price} USD / year (8.33 USD / month)</p>
                    ) : (
                        <p>{seminar.price} USD / month</p>
                    )}
                </Card.Text>
            </Card.Body>
        </Card>
    </Row>
);

export default SubscriptionDetails;