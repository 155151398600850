import './App.css';

import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

import ForwardRoundedIcon from '@mui/icons-material/ForwardRounded';
import BackspaceRoundedIcon from '@mui/icons-material/BackspaceRounded';
import SaveRoundedIcon from '@mui/icons-material/Save'; // Note: No 'Rounded' suffix in MUI v5

import React, { useState } from 'react';

// No changes to react-toastify imports
import { toast } from 'react-toastify';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

// import Background from './backgroundImage.jpg';
// import Background from '../public/';



const { Octokit } = require("@octokit/rest");



const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: '10px',
    minWidth: 120,
  },
}));



function App() {

  const classes = useStyles();

  const username = "ghumman";
  const password = "house6566";

  const [folders, setFolders] = useState(["Business", "DIY", "Degrees", "Gym", "Medical", "Other", "PersonIDs", "PropertyRelated", "Resume", "Scenic", "Tax", "UpdateAhmedWeights"]);
  const [currentFolderSelection, setCurrentFolderSelection] = useState("");
  const [folderSelectectionCompleted, setFolderSelectionCompleted] = useState(false);
  const [folderSelectionSequence, setFolderSelectionSequence] = useState([]);
  const [prefix, setPrefix] = useState("");
  const [selectedName, setSelectedName] = useState(false);
  const [selectedFile, setSelectedFile] = useState(false);
  const [fileName, setFileName] = useState("");
  const [files, setFiles] = useState([]);
  const [enableSaveIcon, SetEnableSaveIcon] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formUsername, setFormUsername] = useState("");
  const [formPassword, setFormPassword] = useState("");
  const [formUsernameHelperText, setFormUsernameHelperText] = useState("");
  const [formPasswordHelperText, setFormPasswordHelperText] = useState("Hello World");
  const [udpateWeights, setUpdateWeights] = useState(false); 
  const [dateOfWeight, setDateOfWeight] = useState(null);
  const [newWeight, setNewWeight] = useState(null);
  const [newWeightChanged, setNewWeightChanged] = useState(false);
  const [dateOfWeightChanged, setDateOfWeightChanged] = useState(false);

  var folderStages = {
    undefined: ["Business", "DIY", "Degrees", "Gym", "Medical", "Other", "PersonIDs", "PropertyRelated", "Resume", "Scenic", "Tax", "UpdateAhmedWeights"],
    "PropertyRelated": null,
    "Tax": null,
    "Scenic": ["Images", "Videos"],
    "Images": null,
    "Videos": null,
    "Business": null,
    "DIY": null,
    "Resume": null,
    "Degrees": null,
    "Gym": ["Other", "ProgressPictures"],
    "ProgressPictures": ["Dec2020", "Jan2021", "Feb2021", "Mar2021", "Apr2021", "May2021", "Jun2021", "Jul2021", "Aug2021", "Sep2021", "Oct2021", "Nov2021", "Dec2021"],
    "Dec2020": null,
    "Jan2021": null,
    "Feb2021": null,
    "Mar2021": null,
    "Apr2021": null,
    "May2021": null,
    "Jun2021": null,
    "Jul2021": null,
    "Aug2021": null,
    "Sep2021": null,
    "Oct2021": null,
    "Nov2021": null,
    "Dec2021": null,
    "PersonIDs": ["Ahmed", "FamilyMembers", "Hira", "Ibrahim", "Other", "Usama", "Zayd"],
    "Ahmed": null,
    "Hira": null,
    "Usama": null,
    "Zayd": null,
    "Ibrahim": null,
    "FamilyMembers": null,
    "Medical": null,
    "Other": null, 
    "UpdateAhmedWeights": null,
  };

  const handleFileNameChange = (event) => {
    setFileName(event.target.value);
    if (event.target.value !== "")
      setSelectedName(true);
    else
      setSelectedName(false);
  }

  const octokit = new Octokit({
    auth: "8f34fe634df919fe0e2218486daf2f41d2b90e44",
  });


  // If something needs to happen at the beginning
  // useEffect(() => { });


  const onFileSelected = (event) => {
    if (event.target.files[0]) {
      setFiles([event.target.files[0]]);
      setSelectedFile(true);
    } else {
      setSelectedFile(false);
    }
  }


  const readUploadedFileAsText = (inputFile) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsDataURL(inputFile);
    });
  };

  async function uploadFileToGithub() {

    const file = files[0];
    const idCardBase64 = await readUploadedFileAsText(file)

    const words = idCardBase64.split(',');

    var re = /(?:\.([^.]+))?$/;
    var ext = re.exec(file.name)[1];
    const pathName = prefix + fileName + '.' + ext;
    SetEnableSaveIcon(false);
    try {
      const result = await octokit.repos.createOrUpdateFileContents({
        owner: 'ghumman',
        repo: 'tasweer-e-bautan',
        message: "Adding an image to the repository",
        path: pathName,
        content: words[1],
      })
      toast.success('Upload was successful', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error('Upload Failed', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    SetEnableSaveIcon(true);






    /*  Tried following things to use github api */

    // const result = await octokit.request("GET /users");
    // const data = await octokit.repos.list({
    //   username: "ghumman"
    // });
    // const owner = "ghumman";
    // const repo = "https://github.com/ghumman/friends";

    // const data = await octokit.repos.getCommit({
    //   owner: 'ghumman',
    //   repo: 'tasweer-e-bautan',
    //   ref: 'baf1675'  
    // });

    // const getRefObject = await octokit.git.getRef({
    //   owner: 'ghumman',
    //   repo: 'tasweer-e-bautan',
    //   ref: `heads/main`,
    // })

    // const blobOject = await octokit.git.createBlob({
    //   owner: 'ghumman',
    //   repo: 'tasweer-e-bautan',
    //   content: idCardBase64,
    //   encoding: "utf-8"
    // });

    // const newBlobOjbect = octokit.git.createBlob("octocat/Hello-World", "foo bar baz");


    // const getTreeObject = await octokit.git.getTree({
    //   owner: 'ghumman',
    //   repo: 'tasweer-e-bautan',
    //   sha: 'baf1675d99aa0d59130d2962285d0a9a11ae755f',
    //   recursive: 1
    // })


    // const changes = {
    //   files: {
    //     'pic.png': idCardBase64
    //   },
    //   commit: 'Image from octokit'
    // }

    // const tree = {
    //   mode: '100644',
    //   type: 'blob',
    //   content: blobOject.data.sha
    // }

    // const treeObject = await octokit.git.createTree({
    //   owner: 'ghumman',
    //   repo: 'tasweer-e-bautan',
    //   tree: Object.keys(changes.files).map(path => {
    //     // get around some type thing...
    //     const mode: "100644" | "100755" | "040000" | "160000" | "120000" = "100644"
    //     const type: "blob" | "tree" | "commit" = "blob"
    //     return {
    //       path,
    //       mode,
    //       // content: changes.files[path],
    //       sha: blobOject.data.sha,
    //       type
    //     }
    //   })
    // });


    // const commitObject = await octokit.git.createCommit({
    //   owner: 'ghumman',
    //   repo: 'tasweer-e-bautan',
    //   message: 'Document Test 4 Commit Comment',
    //   tree: treeObject.data.sha,
    //   parents: [getRefObject.data.object.sha],
    // });

    // const updateObject = await octokit.git.updateRef({
    //   owner: 'ghumman',
    //   repo: 'tasweer-e-bautan',
    //   ref: 'heads/new_branch',
    //   sha: commitObject.data.sha,
    //   force: true
    // });

    // const pr = await octokit.pulls.create({
    //   owner: 'ghumman',
    //   repo: 'tasweer-e-bautan',
    //   title: 'Test PR',
    //   head: 'ghumman:main',
    //   base: 'new_branch',
    //   body: commitObject.data.sha,
    //   force: true
    // });

  }

  const handleRadioButtonChange = (event) => {
    setCurrentFolderSelection(event.target.value);

  }


  const folderItems = folders.map((value) =>
    <FormControlLabel value={value} control={<Radio />} label={value} />
  );

  let showPath = null
  if (folderSelectionSequence) {
    showPath = folderSelectionSequence.map((value) =>
      <li>{value}</li>
    )
  }


  const handleNextButtonPressed = () => {
    if (currentFolderSelection === "")
      return;

    // create the path
    let path = ""
    folderSelectionSequence.map(x => {
      path = path + x + "#$%";
    })
    path = path + currentFolderSelection + "#$%";
    setPrefix(path);


    setFolderSelectionSequence(oldArray => [...oldArray, currentFolderSelection]);
    // reset current folder seleection
    setCurrentFolderSelection("");



    if (folderStages[currentFolderSelection] !== null) {


      // change to the radio buttons to new choices
      setFolders(folderStages[currentFolderSelection])
    } else {
      setFolderSelectionCompleted(true);
      if (currentFolderSelection === "UpdateAhmedWeights") {
        setUpdateWeights(true);
      }
      setSelectedFile(false);
    }
  }

  const handlePreviousButtonPressed = () => {

    setNewWeightChanged(false);
    setDateOfWeightChanged(false);
    setUpdateWeights(false);
    setDateOfWeight(null);
    setNewWeight(null);


    const lastFolder = folderSelectionSequence[folderSelectionSequence.length - 2];
    setFolderSelectionCompleted(false);

    // setFolderSelectionSequence(folderSelectionSequence.filter(x => x != folderSelectionSequence.splice(-1)));
    folderSelectionSequence.pop();
    setFolderSelectionSequence(folderSelectionSequence);

    // reset current folder seleection
    setCurrentFolderSelection("");

    // change to the radio buttons to new choices
    setFolders(folderStages[lastFolder])

  }

  const handleChangeUsername = (event) => {
    setFormUsernameHelperText("")
    setFormPasswordHelperText("")
    setFormUsername(event.target.value);
  }
  const handleChangePassword = (event) => {
    setFormUsernameHelperText("")
    setFormPasswordHelperText("")
    setFormPassword(event.target.value);
  }
  const handleLoginPressed = () => {
    if (formUsername === "") {
      setFormUsernameHelperText("Username can not be empty")
    }

    if (formPassword === "") {
      setFormPasswordHelperText("Password can not be empty")
    }

    if (formUsername === "" || formPassword === "") {
      return;
    }

    if (formUsername === username && formPassword == password) {
      setLoggedIn(true);
    }
    if (formUsername !== username || formPassword !== password) {
      setFormUsernameHelperText("Username/Password incorrect")
      setFormPasswordHelperText("Username/Password incorrect")
    }

  }

  const handleWeightChanged = (event) => {
    setNewWeight(event.target.value);
    if (event.target.value === "") {
      setNewWeightChanged(false);
    } else {
      setNewWeightChanged(true);
    }
    
  }

  const handleDateOfWeightChanged = (event) => {
    setDateOfWeight(event.target.value);
    setDateOfWeightChanged(true);
  }

  async function uploadWeightToGithub() {
    let dateFormatted = dateOfWeight.replace(/-/g, " ");
    let fileContent = " " + newWeight + "\n " + dateFormatted;
    SetEnableSaveIcon(false);
    try {
      const result = await octokit.repos.createOrUpdateFileContents({
        owner: 'ghumman',
        repo: 'tasweer-e-bautan',
        message: "Updating Weight",
        path: "newData",
        content: btoa(fileContent),
      })
      toast.success('Upload was successful', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    } catch (err) {
      toast.error('Upload Failed', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    SetEnableSaveIcon(true);
  }

  return (
    <div >


      { !loggedIn &&


        <div className="App" style={{
          backgroundColor: "red",
          width: "100%",
          backgroundImage: `url('${process.env.PUBLIC_URL}/backgroudImage.jpg')`
        }}>
          <header className="App-header" >
            <div style={{
              borderRadius: "25px",
              border: "2px solid black",
              padding: "20px",
              color: "black",
              fontWeight: "bold",
              background: "rgba(265, 265, 265, 0.7)"
            }} >
              <p>TASWEER E BAUTAN</p>
              <p
                style={{
                  borderStyle: "solid",
                  borderColor: "black",
                  borderRadius: 5,
                  padding: 5
                }}
              >
                Please Login
          </p>
              <FormControl onSubmit={handleLoginPressed}>
                <p style={{ padding: '10' }}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Username"
                    defaultValue=""
                    value={formUsername}
                    variant="outlined"
                    onChange={handleChangeUsername}
                    helperText={formUsernameHelperText}
                  />
                </p>
                <p style={{ padding: '10' }}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Password"
                    defaultValue=""
                    value={formPassword}
                    type="password"
                    variant="outlined"
                    onChange={handleChangePassword}
                    helperText={formPasswordHelperText}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleLoginPressed();
                      }
                    }}
                  />
                </p>

                <p style={{ padding: '10' }}>

                  <IconButton type="submit" color="secondary" fontSize="large" onClick={handleLoginPressed}>
                    <LockOpenIcon />
                  </IconButton>
                </p>
              </FormControl>


              {errorMessage}

            </div>


          </header>
        </div>

      }
      { loggedIn && <div className="App">
        <Box
          display="flex"
          flexWrap="wrap"
          p={1}
          m={1}
          alignItems="flex-start"
          css={{ maxWidth: '100%' }}
        >
          <Box p={1} css={{
            fontSize: "40px",
            fontWeight: "bold",
          }}>
            TASWEER E BAUTAN
                </Box>
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          p={1}
          m={1}
          justifyContent="center"
          css={{ maxWidth: '100%' }}
        >
          <Box p={1} style={{
            backgroundColor: "#DCDCDC",
            borderRadius: 10,
            padding: "60px"
          }}>
            <p>
              <FormLabel component="legend">
                Category: {showPath}
              </FormLabel>
              {!folderSelectectionCompleted &&
                <RadioGroup value={currentFolderSelection} name="category1" onChange={handleRadioButtonChange}>
                  {folderItems}
                </RadioGroup>}
            </p>

          </Box>
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          p={1}
          m={1}
          justifyContent="center"
          bgcolor="background.paper"
        >


          <Box p={1} css={{
            backgroundColor: "#DCDCDC",
            padding: 10
          }}>
            {folderSelectionSequence.length != 0 && <BackspaceRoundedIcon color="secondary" fontSize="large" onClick={handlePreviousButtonPressed}></BackspaceRoundedIcon>}
          </Box>
          <Box p={1} css={{
            backgroundColor: "#DCDCDC",
            padding: 10
          }}>
            {!folderSelectectionCompleted && <ForwardRoundedIcon color="secondary" fontSize="large" onClick={handleNextButtonPressed}></ForwardRoundedIcon>}
          </Box>
        </Box>


        <Box
          display="flex"
          flexWrap="wrap"
          p={1}
          m={1}
          justifyContent="center"
          bgcolor="background.paper"
          css={{ maxWidth: '100%' }}
        >
          <Box>
            {folderSelectectionCompleted && !udpateWeights &&  <input type="file" name="file" onChange={onFileSelected} />}
            {folderSelectectionCompleted && udpateWeights &&          
            <div>

        <div><TextField
          id="weightInPounds"
          label="Weight"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">Pounds</InputAdornment>,
          }}
          onChange={handleWeightChanged}
        /></div>              
        <div><TextField
        id="dateOfWeight"
        label="Date"
        type="date"
        defaultValue="2021-01-01"
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleDateOfWeightChanged}
      /></div>
      </div>
        }
          </Box>
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          p={1}
          m={1}
          justifyContent="center"
          bgcolor="background.paper"
          css={{ maxWidth: '100%' }}
        >
          {
            folderSelectectionCompleted && selectedFile &&

            <TextField
              required
              label="File Name"
              defaultValue=""
              value={fileName}
              variant="outlined"
              onChange={handleFileNameChange}
            />
          }
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          p={1}
          m={1}
          justifyContent="center"
          bgcolor="background.paper"
          css={{ maxWidth: '100%' }}
        >
          <Box>
          
            {newWeightChanged && dateOfWeightChanged && enableSaveIcon && <SaveRoundedIcon color="secondary" fontSize="large" onClick={uploadWeightToGithub}></SaveRoundedIcon>}

            {folderSelectectionCompleted && selectedName && selectedFile && enableSaveIcon && <SaveRoundedIcon color="secondary" fontSize="large" onClick={uploadFileToGithub}></SaveRoundedIcon>}

          </Box>
        </Box>

      </div>
      }


    </div>
  );
}

export default App;
