import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import React from 'react';

import { ThreeDots } from 'react-bootstrap-icons'

import ios1 from '../../assets/ios1.png';
import ios2 from '../../assets/ios2.png';
import ios3 from '../../assets/ios3.png';
import ios4 from '../../assets/ios4.png';
import ios5 from '../../assets/ios5.png';
import ios6 from '../../assets/ios6.png';
import ios7 from '../../assets/ios7.png';
import ios8 from '../../assets/ios8.png';
import ios9 from '../../assets/ios9.png';
import ios10 from '../../assets/ios10.png';

import {iosAppsLink} from './../Utils/constants'

const AndroidTimeline = () => {
    return (
        <div>

        <div style={{textAlign: 'center', padding: '20px', textDecoration: 'underline'}}>
        <p className='platform-title'>iOS Projects</p>
        </div>
        <VerticalTimeline>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'lightgreen', color: 'black'}}
    contentArrowStyle={{ borderRight: '7px solid  lightgreen'}}
    date="Tech-powered dedication, customer-focused results. 👍 😃 📞"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff', overflow: 'hidden' }}
    icon={<img style={{width:'100%', height: '100%'}} src={ios1}/>}
  >
    <h3 className="vertical-timeline-element-title">Amusement Theme Parks</h3>
    <a style={{color: 'black', font: '10px', textDecoration: 'underline'}} href="https://apps.apple.com/us/app/amusement-theme-parks/id6446772411"> Details </a>
    <p>
    Test your coaster cred and park passion with this fun trivia app exploring the magic of famous theme parks worldwide.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="Bridging the gap between your needs and solutions. 🌉 🤝"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff', overflow: 'hidden' }}
    icon={<img style={{width:'100%', height: '100%'}} src={ios2}/>}
  >
    <h3 className="vertical-timeline-element-title">Car Logos</h3>
    <a style={{color: 'black', font: '10px', textDecoration: 'underline'}} href="https://apps.apple.com/us/app/learn-car-logos/id6447114590"> Details </a>
    <p>
    From prancing horses to roaring lions, master the automotive jungle with Car Logos: your pocket-sized guide to identifying iconic emblems.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'lightgreen', color: 'black'}}
    contentArrowStyle={{ borderRight: '7px solid  lightgreen'}}
    date="Beyond manuals, beyond the obvious - tech expertise to fuel your potential. 💡"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff', overflow: 'hidden' }}
    icon={<img style={{width:'100%', height: '100%'}} src={ios3}/>}
  >
    <h3 className="vertical-timeline-element-title">Control Systems</h3>
    <a style={{color: 'black', font: '10px', textDecoration: 'underline'}} href="https://apps.apple.com/us/app/learn-control-systems/id6446431258"> Details </a>
    <p>
    Decode the language of robots and unlock the world of control systems: From factories to spaceships, Learn Control Systems is your practical blueprint for mastering automation.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="Your vision, our tech toolbox - together we bring ideas to life. 🧰"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff', overflow: 'hidden' }}
    icon={<img style={{width:'100%', height: '100%'}} src={ios4}/>}
  >
    <h3 className="vertical-timeline-element-title">Food Dishes</h3>
    <a style={{color: 'black', font: '10px', textDecoration: 'underline'}} href="https://apps.apple.com/us/app/best-food-dishes/id6446873783"> Details </a>
    <p>
    Uncover hidden culinary gems and savor the world's finest flavors with Learn Best Food Dishes: your ultimate dining guide
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    contentStyle={{ background: 'lightgreen', color: 'black'}}
    contentArrowStyle={{ borderRight: '7px solid  lightgreen'}}
    date="More than service, it's partnership, co-creating success with tech skills. ⚙️ 🤝"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff', overflow: 'hidden' }}
    icon={<img style={{width:'100%', height: '100%'}} src={ios5}/>}
  >
    <h3 className="vertical-timeline-element-title">Minerals</h3>
    <a style={{color: 'black', font: '10px', textDecoration: 'underline'}} href="https://apps.apple.com/us/app/learn-about-minerals/id6446815895"> Details </a>
    <p>
    From glittering gems to everyday rocks, unlock the secrets of the earth's treasures with Learn About Minerals: your interactive guide to the fascinating world of geology.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="Dedication amplified by tech - we're not just helpers, we're tech-powered problem solvers. 👨‍💻"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff', overflow: 'hidden' }}
    icon={<img style={{width:'100%', height: '100%'}} src={ios6}/>}
  >
    <h3 className="vertical-timeline-element-title">Machine Learning</h3>
    <a style={{color: 'black', font: '10px', textDecoration: 'underline'}} href="https://apps.apple.com/us/app/learn-machine-learning/id6446439362"> Details </a>
    <p>
    No longer just black boxes, machine learning algorithms come alive with Learn Machine Learning: Your playground for experimenting with the future's most powerful tools.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    contentStyle={{ background: 'lightgreen', color: 'black'}}
    contentArrowStyle={{ borderRight: '7px solid  lightgreen'}}
    date="Customer-centric, tech-driven - we listen, we learn, we solve with cutting-edge technology. 👂 🧠 ⚙️"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff', overflow: 'hidden' }}
    icon={<img style={{width:'100%', height: '100%'}} src={ios7}/>}
  >
    <h3 className="vertical-timeline-element-title">Linux Certification Exam</h3>
    <a style={{color: 'black', font: '10px', textDecoration: 'underline'}} href="https://apps.apple.com/us/app/linux-certification/id6464318330"> Details </a>
    <p>
    Ace your Linux certification like a pro with practice tests, flashcards, and expert tips: Your pocket-sized bootcamp for conquering the exam.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date='From challenges to breakthroughs - our dedication and tech expertise fuel your progress. 🚀'
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff', overflow: 'hidden' }}
    icon={<img style={{width:'100%', height: '100%'}} src={ios8}/>}
  >
    <h3 className="vertical-timeline-element-title">Scuba Certification Exam</h3>
    <a style={{color: 'black', font: '10px', textDecoration: 'underline'}} href="https://apps.apple.com/us/app/scuba-certification/id6464329880"> Details </a>
    <p>
    Unleash your inner aquanaut with comprehensive study tools, real-world scenarios, and detailed explanations: Master aquatic skills and dominate the certification path, one question at a time.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    contentStyle={{ background: 'lightgreen', color: 'black'}}
    contentArrowStyle={{ borderRight: '7px solid  lightgreen'}}
    date="Your problem, our passion - dedicated to solving your challenges with tech expertise. ❤️‍🔥 🖥️"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff', overflow: 'hidden' }}
    icon={<img style={{width:'100%', height: '100%'}} src={ios9}/>}
  >
    <h3 className="vertical-timeline-element-title">USA Colleges</h3>
    <a style={{color: 'black', font: '10px', textDecoration: 'underline'}} href="https://apps.apple.com/us/app/usa-top-colleges/id6446713819"> Details </a>
    <p>
    Don't just apply, thrive! Top USA Colleges empowers you to navigate the college landscape with confidence and land your dream acceptance letter.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="We don't just sell products, we provide solutions. 📦 💡"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff', overflow: 'hidden' }}
    icon={<img style={{width:'100%', height: '100%'}} src={ios10}/>}
  >
    <h3 className="vertical-timeline-element-title">Vehicle Dashboard Indicators</h3>
    <a style={{color: 'black', font: '10px', textDecoration: 'underline'}} href="https://apps.apple.com/us/app/vehicle-dashboard-indicators/id6447113515"> Details </a>
    <p>
    Ditch the confusion, decode your car's language with Vehicle Dashboard Indicators: Your pocket-sized translator for every blinking light and cryptic symbol.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    contentStyle={{ background: 'lightgreen', color: 'black'}}
    contentArrowStyle={{ borderRight: '7px solid  lightgreen'}}
    date="Tech-savvy dedication, customer-focused results - we make customers for life. 🔗 ❤️"
    iconStyle={{ background: 'lightgreen', color: '#fff', overflow: 'hidden' }}
    icon={<ThreeDots/>}
  >
    <h3 className="vertical-timeline-element-title">More Projects</h3>
    <a style={{color: 'black', font: '10px', textDecoration: 'underline'}} href={iosAppsLink}> Have a look at more of our projects... </a>
  </VerticalTimelineElement>
</VerticalTimeline>
        </div>
    )
}

export default AndroidTimeline; 