import React from "react";
import { Modal, Backdrop, Fade, Box, Typography, Button } from '@mui/material';
import { modalStyle } from './styles';

const SignOutModal = ({ openSignOutModal, setOpenSignOutModal, signMeOut }) => {
  return (
    <Modal
      open={openSignOutModal}
      onClose={() => setOpenSignOutModal(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openSignOutModal}>
        <Box sx={{ ...modalStyle }}>
          <Typography variant="h6" component="h2">
            Are you sure you want to sign out?
          </Typography>
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button variant="contained" color="secondary" onClick={signMeOut}>
              Yes
            </Button>
            <Button variant="contained" color="primary" onClick={() => setOpenSignOutModal(false)}>
              No
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default SignOutModal;