import React, { useState, useEffect } from 'react';
import FloatCart from '../FloatCart';
import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';
import './style.css';
import 'react-vertical-timeline-component/style.min.css';
import { useNavigate } from 'react-router-dom';
import TrialDetails from './TrialDetails';
import CourseContent from './CourseContent';
import FreeTRialImage1 from './FreeTrialImage1Big.jpg'
import AdImage from './AdImage';


const GhummanRoboTrial = () => {
  const [state, setState] = useState({
    isOpen: false,
    menuHidden: true,
  });
  const navigate = useNavigate();

  useEffect(() => {
    // Run this effect after every render
    if (!state.menuHidden) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'static';
    } else {
      document.body.style.overflow = 'visible';
      document.body.style.position = 'static';
    }
  }, [state.menuHidden]); // Only re-run the effect if menuHidden changes

  useEffect(() => {
    // Run this effect only once after mounting
    document.body.style.overflow = 'visible';
    document.body.style.position = 'static';
  }, []);

  const openFloatCart = () => {
    setState((prevState) => ({ ...prevState, isOpen: !prevState.isOpen }));
  };

  const openMenuWindow = () => {
    setState((prevState) => ({ ...prevState, menuHidden: false }));
  };

  const isMenuOpen = (state) => {
    const menuHidden = state.isOpen === false;
    setState((prevState) => ({ ...prevState, menuHidden }));
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setState((prevState) => ({ ...prevState, menuHidden: true }));
  };

  return (
    <div id="outer-container">
      <HamburgerMenu
        isMenuOpenFunc={isMenuOpen}
        pageWrapId="page-wrap"
        outerContainerId="outer-container"
        isOpenStatus={state.menuHidden}
        callbackClose={callbackRoutingCloseMenu}
      />
      <div id="page-wrap">
        <Header openMenu={openMenuWindow} openCart={openFloatCart} />
        <div class="col-md-12" >
          <TrialDetails />
        </div>
        <div class="col-md-12" >
          <CourseContent />
        </div>
        <div style={{marginTop: '40px'}}>
          <AdImage src={FreeTRialImage1} />
        </div>
        <Footer />
        <FloatCart isTheCartOpen={state.isOpen} history={navigate} />

      </div>
    </div>
  );
};

export default GhummanRoboTrial;