import React, { useState } from "react";
import { TextField, Button, Typography, Container, Box, CircularProgress } from "@mui/material";
import './ForgotPassword.css';

const ForgotPassword = ({ formEmail, handleChangeEmail, handleSubmitForgot, setStage }) => {
  const [loading, setLoading] = useState(false);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    await handleSubmitForgot(e);
    setLoading(false);
  };

  return (
    <Container className="auth-container" maxWidth="xs">
      <Box className="auth-box">
        <Typography variant="h4" className="auth-title">Forgot Password</Typography>
        <form onSubmit={handleForgotPassword} className="auth-form">
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            type="email"
            value={formEmail}
            placeholder="Enter Email"
            onChange={handleChangeEmail}
            label="Email"
            disabled={loading}
          />
          <Button fullWidth variant="contained" color="primary" type="submit" className="auth-button" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Reset Password'}
          </Button>
        </form>
        <Typography variant="body2" className="auth-cancel" style={{marginTop: '10px'}} onClick={() => setStage('login')} disabled={loading}>
          Cancel
        </Typography>
      </Box>
    </Container>
  );
};

export default ForgotPassword;