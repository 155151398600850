import React from 'react';
import PropTypes from 'prop-types';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { updateFilters } from '../../../services/filters/actions';
import { fetchProducts } from '../../../services/shelf/actions';
import { addProduct } from '../../../services/cart/actions';
import { connect } from 'react-redux';
import Carousel from 'react-bootstrap/Carousel';
import GoogleMapReact from 'google-map-react';


const AnyReactComponent = ({ text }) => <div
style={{
   border: "2px solid #cc0066", backgroundColor: "#cc0066", borderRadius: "10px", padding: "6px", textAlign: "center"
}}
>{text}</div>;

class Product extends React.Component {

  static defaultProps = {
    center: {
      lat: 30.37,
      lng: 69.34
    },
    zoom: 5,
    greatPlaces: [
      {index: 0, id: '*', lat: 24.8607, lng: 67.0011},
      {index: 1, id: '*', lat: 31.5204, lng: 74.3587},
      {index: 2, id: '*', lat: 31.4504, lng: 73.1350},
      {index: 3, id: '*', lat: 33.5651, lng: 73.0169},
      {index: 4, id: '*', lat: 32.1877, lng: 74.1945},
      {index: 5, id: '*', lat: 34.0151, lng: 71.5249},
      {index: 6, id: '*', lat: 30.1575, lng: 71.5249},
      {index: 7, id: '*', lat: 25.3960, lng: 68.3578},
      {index: 8, id: '*', lat: 33.6844, lng: 73.0479},
      {index: 9, id: '*', lat: 30.1798, lng: 66.9750},

      {index: 10, id: '*', lat: 29.3544, lng: 71.6911},
      {index: 11, id: '*', lat: 32.0740, lng: 72.6861},
      {index: 12, id: '*', lat: 32.4945, lng: 74.5229},
      {index: 13, id: '*', lat: 27.7244, lng: 68.8228},
      {index: 14, id: '*', lat: 27.5570, lng: 68.2028},
      {index: 15, id: '*', lat: 31.7167, lng: 73.9850},
      {index: 16, id: '*', lat: 28.4212, lng: 70.2989},
      {index: 17, id: '*', lat: 31.2781, lng: 72.3317},
      {index: 18, id: '*', lat: 30.0489, lng: 70.6455},
      {index: 19, id: '*', lat: 32.5731, lng: 74.1005},

      {index: 20, id: '*', lat: 31.4504, lng: 73.1350},
      {index: 21, id: '*', lat: 31.5204, lng: 74.3587},
      {index: 22, id: '*', lat: 31.4504, lng: 73.1350},
      {index: 23, id: '*', lat: 31.5204, lng: 74.3587},
      {index: 24, id: '*', lat: 31.4504, lng: 73.1350},
      {index: 25, id: '*', lat: 31.5204, lng: 74.3587},
      {index: 26, id: '*', lat: 31.4504, lng: 73.1350},
      {index: 27, id: '*', lat: 31.5204, lng: 74.3587},
      {index: 28, id: '*', lat: 31.4504, lng: 73.1350},
      {index: 29, id: '*', lat: 31.5204, lng: 74.3587},

      {index: 30, id: '*', lat: 31.4504, lng: 73.1350},
      {index: 31, id: '*', lat: 31.5204, lng: 74.3587},
      {index: 32, id: '*', lat: 31.4504, lng: 73.1350},
      {index: 33, id: '*', lat: 31.5204, lng: 74.3587},
      {index: 34, id: '*', lat: 31.4504, lng: 73.1350},
      {index: 35, id: '*', lat: 31.5204, lng: 74.3587},
      {index: 36, id: '*', lat: 31.4504, lng: 73.1350},
      {index: 37, id: '*', lat: 31.5204, lng: 74.3587},
      {index: 38, id: '*', lat: 31.4504, lng: 73.1350},
      {index: 39, id: '*', lat: 31.5204, lng: 74.3587},

      {index: 40, id: '*', lat: 31.4504, lng: 73.1350},
      {index: 41, id: '*', lat: 31.5204, lng: 74.3587},
      {index: 42, id: '*', lat: 31.4504, lng: 73.1350},
      {index: 43, id: '*', lat: 31.5204, lng: 74.3587},
      {index: 44, id: '*', lat: 31.4504, lng: 73.1350},
      {index: 45, id: '*', lat: 31.5204, lng: 74.3587},
      {index: 46, id: '*', lat: 31.4504, lng: 73.1350},
      {index: 47, id: '*', lat: 31.5204, lng: 74.3587},
      {index: 48, id: '*', lat: 31.4504, lng: 73.1350},
      {index: 49, id: '*', lat: 31.5204, lng: 74.3587},

      {index: 50, id: '*', lat: 31.5204, lng: 74.3587}


    ]
  };

  static propTypes = {
    center: PropTypes.array, // @controllable
    zoom: PropTypes.number, // @controllable
    hoverKey: PropTypes.string, // @controllable
    clickKey: PropTypes.string, // @controllable
    onCenterChange: PropTypes.func, // @controllable generated fn
    onZoomChange: PropTypes.func, // @controllable generated fn
    onHoverKeyChange: PropTypes.func, // @controllable generated fn
    greatPlaces: PropTypes.array,
    fetchProducts: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
    filters: PropTypes.array,
    addProduct: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      textvalue: 'Change me',
      id: "1",
      username: "",
      password: "",
      signinConfirmation: "",

      signinVar: "",

      key: 'home',
      isOpen: false,
      menuHidden: true,
      currentMenu: 'slide',
      side: 'left',
      availableSizes: [],
      isLoading: false,
      selectedSizeIndex: 0,
      selectedSizeName: '',

      cities: [
        'Karachi',
        'Lahore',
        'Faisalabad',
        'Rawalpindi',
        'Gujranwala',
        'Peshawar',
        'Multan',
        'Hyderabad',
        'Islamabad',
        'Quetta',

        'Bahawalpur',
        'Sargodha',
        'Sialkot',
        'Sukkur',
        'Larkana',
        'Sheikhupura',
        'Rahim Yar Khan',
        'Jhang',
        'Dera Ghazi Khan',
        'Gujrat',

        'Sahiwal',
        'Wah Cantonment',
        'Mardan',
        'Kasur',
        'Okara',
        'Mingora',
        'Nawabshah',
        'Chiniot',
        'Kotri',
        'Kāmoke',

        'Hafizabad',
        'Sadiqabad',
        'Mirpur Khas',
        'Burewala',
        'Kohat',
        'Khanewal',
        'Dera Ismail Khan',
        'Turbat',
        'Muzaffargarh',
        'Abbotabad',

        'Mandi Bahauddin',
        'Shikarpur',
        'Jacobabad',
        'Jhelum',
        'Khanpur',
        'Khairpur',
        'Khuzdar',
        'Pakpattan',
        'Hub',
        'Daska',

        'Chistian'

      ],
      citiesSelectionStates: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

    }
  }

  componentWillReceiveProps(nextProps) {
    const { filters: nextFilters } = nextProps;
    const { filters } = this.props;
    if (nextFilters !== filters) {
      this.handleFetchProducts(nextFilters, undefined, undefined);
    }
  }

  handleFetchProducts = (
    filters = this.props.filters,
    filtersCategory = this.props.filtersCategory,
    sort = this.props.sort
  ) => {
    this.setState({ isLoading: true });
    this.props.fetchProducts(filters, filtersCategory, sort, () => {
      this.setState({ isLoading: false });
    });
  };

  changeMenu(menu) {
    this.setState({ currentMenu: menu });
  }

  changeSide(side) {
    this.setState({ side });
  }

  getItems() {
    return [

      <h2 key="0"><i className="fa fa-fw fa-inbox fa-2x" /><span>Sidebar</span></h2>,
      <a key="1" href=""><i className="fa fa-fw fa-database" /><span>Data Management</span></a>,
      <a key="2" href=""><i className="fa fa-fw fa-map-marker" /><span>Location</span></a>,
      <a key="3" href=""><i className="fa fa-fw fa-mortar-board" /><span>Study</span></a>,
      <a key="4" href=""><i className="fa fa-fw fa-picture-o" /><span>Collections</span></a>,
      <a key="5" href=""><i className="fa fa-fw fa-money" /><span>Credits</span></a>
      // <div>
      // <div style={{color: "white"}}> Item 1q</div>

      // <div> Item 1q</div>

      // <div> Item 1q</div>
      // <div> Item 1q</div>
      // <div> Item 1q</div>
      // <a key="0" href=""><i className="fa fa-fw fa-star-o" /><span>Favorites</span></a>,
      // <a key="1" href=""><i className="fa fa-fw fa-bell-o" /><span>Alerts</span></a>,
      // <a key="2" href=""><i className="fa fa-fw fa-envelope-o" /><span>Messages</span></a>,
      // <a key="3" href=""><i className="fa fa-fw fa-comment-o" /><span>Comments</span></a>,
      // <a key="4" href=""><i className="fa fa-fw fa-bar-chart-o" /><span>Analytics</span></a>,
      // <a key="5" href=""><i className="fa fa-fw fa-newspaper-o" /><span>Reading List</span></a>
      // </div>
    ];
  }


  signMeIn = () => {


    this.props.history.push('/Login');

    // if (this.state.username == "") {
    //   this.props.history.push({
    //     pathname: '/RegisterPage',
    //     state: { profileSigninConfirmation : this.state.signinConfirmation, profileUsername : this.state.username, profilePassword: this.state.password}
    //   })
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    // If the hamburger menu is open, disable scrolling on the site
    if (prevState.menuHidden !== this.state.menuHidden) {
      if (!this.state.menuHidden) {
        // overflow:hidden disables the scrolling on a desktop browser
        // position: fixed is additionally needed for mobile devices
        document.body.setAttribute("style", "overflow: hidden; position: static;");
      } else {
        document.body.setAttribute("style", "overflow: visible; position: static;");
      }
    }
  }

  componentDidMount() {

    document.body.setAttribute("style", "overflow: visible; position: static;");


    const resultUsername = localStorage.getItem("username");
    const resultPassword = localStorage.getItem("password");

    if (
      resultUsername === null ||
      resultUsername === ""
    ) {
    } else {
      this.setState({ username: resultUsername });
    }


    this.props.updateFilters({ 'sku': this.props.match.params.id });

    this.fillAvailableSizes();


  }

  fillAvailableSizes = () => {
    this.setState({ availableSizes: this.props.products.availableSizes });
  }

  openFloatCart = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  openMenuWindow = () => {
    this.setState({ menuHidden: false });

  }

  fbOpen = () => {
    window.open('https://www.facebook.com/SabaJC/');
  }

  instaOpen = () => {
    window.open('https://www.instagram.com/ederracouture/');
  }

  privacyOpen = () => {
    this.props.history.push('/Privacy');
  }

  exchangeOpen = () => {
    this.props.history.push('/Exchange');
  }

  sizeChartOpen = () => {
    this.props.history.push('/SizeChart');
  }

  contactOpen = () => {
    this.props.history.push('/ContactUs');
    // window.open('https://www.facebook.com/pg/SabaJC/about/?ref=page_internal');
  }

  aboutOpen = () => {
    this.props.history.push('/AboutUs');
    // window.open('https://www.facebook.com/pg/SabaJC/about/?ref=page_internal');
  }

  isMenuOpen = (state) => {
    if (state.isOpen == false) {
      this.setState({ menuHidden: true });
    }
    else {
      this.setState({ menuHidden: false });
    }
    return state.isOpen;
  };

  printData = () => {
  }

  addThisProductToCart = (item) => {
    if (this.state.selectedSizeName == '') {
      this.setState({ selectedSizeName: this.props.products[0].availableSizes[0] });
      item.selectedSize = this.props.products[0].availableSizes[0];
    }
    else {
      item.selectedSize = this.state.selectedSizeName;
    }
    item.quantity = 1;
    this.props.addProduct(item);
  }

  sizeSelectedPressed = (productSize, index) => {
    this.setState({ selectedSizeName: productSize });
    this.setState({ selectedSizeIndex: index });

  }

  callbackRoutingCloseMenu = () => {
    this.setState({ menuHidden: true });
  }

  toggleCityState = (index) => {

    let citiesSelectionStates = [...this.state.citiesSelectionStates];

    citiesSelectionStates[index] = 1 ^ citiesSelectionStates[index];


    this.setState({ citiesSelectionStates });
  }

  _onBoundsChange = (center, zoom /* , bounds, marginBounds */) => {
    this.props.onCenterChange(center);
    this.props.onZoomChange(zoom);
  }

  _onChildClick = (key, childProps) => {
    this.props.onCenterChange([childProps.lat, childProps.lng]);
  }

  _onChildMouseEnter = (key /*, childProps */) => {
    this.props.onHoverKeyChange(key);
  }

  _onChildMouseLeave = (/* key, childProps */) => {
    this.props.onHoverKeyChange(null);
  }



  render() {

    const places = this.props.greatPlaces
    .map((place) => {
      const {index, id, ...coords} = place;
      if (this.state.citiesSelectionStates[index] == 1)
      return (
        <AnyReactComponent
          key={id}
          {...coords}
          text={id}
          // use your hover state (from store, react-controllables etc...)
          hover={this.props.hoverKey === id} />
      );
    });

    const properties = {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: false,
      onChange: (oldIndex, newIndex) => {
      }
    }

    const zoomOutProperties = {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      scale: 0.4,
      arrows: true
    }

    let signinTag
    var signinMessageLocal = ""
    if (this.state.username !== "") {
      signinMessageLocal = this.state.username.charAt(0).toUpperCase()
      signinTag = <button type="button" class="btn btn-success btn-circle btn-lg"> {signinMessageLocal} </button>
    }
    else {
      signinMessageLocal = "Sign In"
      // signinTag = <button type="button" class="btn btn-primary" onClick={() => this.signMeIn()}> {signinMessageLocal} </button>
      signinTag = <PersonOutlineIcon fontSize="large" onClick={() => this.signMeIn()} />
    }
    let itemSizes;
    if (this.state.availableSizes != null) {
      itemSizes = this.state.availableSizes.map((item, index) =>
        <span key={item.index}> {item}&nbsp;&nbsp;</span>
      )
    }
    else {
      itemSizes = null;
    }

    const { products, currency } = this.props;
    const { isLoading } = this.state;



    // if (currency === "PK") {

    let productFormatWithCurrency;
    productFormatWithCurrency = (item) => {
      if (currency === "PK") {
        return (
          <div style={{
            textAlign: "center", fontWeight: "400", margin: "0 0 0.5em",
            lineHeight: "1.4", fontFamily: "auto",
          }}>
            {item.currencyFormat}<span> </span>
            {item.price} <span> </span>
            {item.currencyId}
          </div>
        )
      } else if (currency === "US") {
        return (
          <div style={{
            textAlign: "center", fontWeight: "400", margin: "0 0 0.5em",
            lineHeight: "1.4", fontFamily: "auto",
          }}>
            $<span> </span>
            {(item.price / 150).toFixed(2)}<span> </span>
            USD
      </div>
        )
      }
    }
    // else if (currency === "US") {
    //   productFormatWithCurrency = (
    //     <div style={{
    //       textAlign: "center", fontWeight: "400", margin: "0 0 0.5em",
    //       lineHeight: "1.4", fontFamily: "auto",
    //     }}>
    //       {item.currencyFormat}<span> </span>
    //       {item.price} <span> </span>
    //       {item.currencyId}
    //     </div>
    //     )
    //   }




    let productDetails;

    if (products != null) {
      productDetails = products.map((item, index) =>
        <div>



        </div>
      );
    }

    const showCarouselItems = []
    let i = 1;
    if (this.props.products[0] != undefined) {
      for (i = 1; i <= 5; i++) {
        try {
          showCarouselItems.push(
            <Carousel.Item>
              {/* <img
                className="d-block w-100"
                src={require(`../../static/products/GhummanTech/${this.props.products[0].sku}_${i}.jpg`)}
                alt="First slide"
              /> */}
            </Carousel.Item>
          )
        } catch (e) {

        }
      }
    }

    let showCarousel;
    if (this.props.products[0] != undefined) {
      showCarousel =
        <Carousel>

          {showCarouselItems}
        </Carousel>
    }

    let showCities;
    showCities = this.state.cities.map((item, index) => {
      if (this.state.citiesSelectionStates[index] == 0)
        return (
          <span style={{
            margin: "4px", border: "2px solid #cc0066", borderRadius: "10px", padding: "4px", display: "inline-block", cursor: "pointer"
          }} onClick={() => this.toggleCityState(index)}>
            {item}

          </span>
        )
      else if (this.state.citiesSelectionStates[index] == 1)
        return (
          <span style={{
            margin: "4px", border: "2px solid #cc0066", backgroundColor: "#cc0066", borderRadius: "10px", padding: "4px", display: "inline-block", cursor: "pointer"
          }} onClick={() => this.toggleCityState(index)}>
            {item}

          </span>
        )
    }
    )


    return (
      <div id="outer-container">

        < div class="container">
          <div class="row justify-content-center">
            {/* <div class="col-md-6">

                {showCarousel}




              </div> */}
            <div class="col-md-12" >
              <div style={{
                textAlign: "center", fontWeight: "400", margin: "0 0 0.5em",
                lineHeight: "1.4", fontFamily: "auto", fontSize: "40px"
              }}>
                Visited Cities
              </div>
              <div>
              {showCities}
              </div>

              <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyD2RCwLCLh0aXZbKh_wfov36lYNDdvQE9E" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          {places}
          {/* <AnyReactComponent
            lat={31.4504}
            lng={73.1350}
            text="Faisalabad"
          /> */}
        </GoogleMapReact>
      </div>

            </div>

          </div>
        </div>

      </div>
    );

  }
}

function mapStateToProps(state) {
  // const { loggingIn } = state.counter;
  // return { loggingIn };
  return {
    products: state.shelf.products,
    filters: state.filters.items,
    authenticate: state.authenticate,
    cartProducts: state.cart.products,
    newProduct: state.cart.productToAdd,
    productToRemove: state.cart.productToRemove,
    productToChange: state.cart.productToChange,
    cartTotal: state.total.data,
    currency: state.currency.data
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: (username, password) =>
      dispatch({ type: "UPDATE", username: username, password: password }),
    // loggedIn: () =>
    //   dispatch({
    //     type: "LOGIN"
    //   }),
    signout: () => dispatch({ type: "LOGOUT" })
  };
}

export default connect(
  mapStateToProps,
  { mapDispatchToProps, updateFilters, fetchProducts, addProduct }
)(Product);
