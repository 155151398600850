import React from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import Tooltip from '@mui/material/Tooltip';

const ReactionIcons = ({ reactions }) => {
  const getReactionIcon = (reactionType) => {
    switch (reactionType) {
      case 'thumbs_up':
        return <ThumbUpIcon style={{ color: '#1976d2' }} />;
      case 'like':
        return <SentimentSatisfiedAltIcon style={{ color: '#ffb300' }} />;
      case 'love':
        return <FavoriteIcon style={{ color: '#e91e63' }} />;
      case 'wow':
        return <SentimentVerySatisfiedIcon style={{ color: '#ff9800' }} />;
      case 'sad':
        return <SentimentDissatisfiedIcon style={{ color: '#f44336' }} />;
      case 'angry':
        return <SentimentVeryDissatisfiedIcon style={{ color: '#9c27b0' }} />;
      default:
        return null;
    }
  };

  const getReactionCounts = (reactions) => {
    const counts = {};
    reactions.forEach((reaction) => {
      counts[reaction.reaction_type] = counts[reaction.reaction_type] || { count: 0, users: [] };
      counts[reaction.reaction_type].count += 1;
      counts[reaction.reaction_type].users.push(reaction.reaction_user_name);
    });
    return counts;
  };

  const reactionCounts = getReactionCounts(reactions);

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
      {Object.entries(reactionCounts).map(([reactionType, { count, users }]) => (
        <Tooltip
          key={reactionType}
          title={
            <div>
              {users.map((user, index) => (
                <div key={index}>{user}</div>
              ))}
            </div>
          }
          arrow
        >
          <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
            {getReactionIcon(reactionType)}
            <span style={{ marginLeft: '5px' }}>{count}</span>
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

export default ReactionIcons;