import React from "react";
import { useNavigate, Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const BookshelfTabPanel = ({ eligibleBooks, history }) => {
  const navigate = useNavigate();
  return (
    <div>
      {eligibleBooks && eligibleBooks.length > 0 ? (
        <div>
              <Container>
  <Row className="justify-content-center">
    {eligibleBooks.map(book => (
      <Col xs={12} sm={6} md={4} lg={3}>
        <Link to="/ReadBook" state={{ sku: book.sku }}>
          <Card border="success" style={{ width: '18rem', height: '25rem', padding: '10px' }}>
            <Card.Img
              variant="top"
              width={100}
              height={300}
              src={`https://ghummantech-website.s3.us-east-2.amazonaws.com/${book.sku}_1.jpg`}
            />
            <Card.Body style={{ textAlign: 'center' }}>
              <Col>
                <Row style={{ display: 'grid', placeItems: 'center', marginTop: '10px', fontWeight: 'bold' }}>
                  {book.title}
                </Row>
              </Col>
            </Card.Body>
          </Card>
        </Link>
      </Col>
    ))}
  </Row>
</Container>
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>You didn't buy any books yet!</div>
      )}
    </div>
  );
};

export default BookshelfTabPanel;