import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';

const CartProduct = ({ product, removeProduct, changeProductQuantity, currency }) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isBookInCart, setIsBookInCart] = useState(false);

  useEffect(() => {
    if (product.category) {
      product.category.forEach(category => {
        if (category === "books") {
          setIsBookInCart(true);
        }
      });
    }
  }, [product.category]);

  const handleMouseOver = () => {
    setIsMouseOver(true);
  };

  const handleMouseOut = () => {
    setIsMouseOver(false);
  };

  const handleOnIncrease = () => {
    product.quantity = product.quantity + 1;
    changeProductQuantity(product);
  };

  const handleOnDecrease = () => {
    product.quantity = product.quantity - 1;
    changeProductQuantity(product);
  };

  const shelfItemThumbStyle = {
    verticalAlign: 'middle',
    width: '70px',
    height: '70px',
    marginRight: '3%'
  };

  const shelfItemDetailsStyle = {
    flexGrow: 1,
    marginRight: '10px'
  };

  const productPriceStyle = {
    border: '1px solid black',
    color: 'black',
    padding: '2px 5px',
    display: 'inline-block'
  };

  const classes = [];
  if (isMouseOver) {
    classes.push('shelf-item--mouseover');
  }

  const productFormatWithCurrency = (
    <p style={productPriceStyle}>
      $ <span>{product.price}</span>
    </p>
  );

  return (
    <Box
      className={classes.join(' ')}
      sx={{
        display: 'flex',
        alignItems: 'center',
        margin: '10px',
        position: 'relative',
        boxSizing: 'border-box',
        padding: '5%',
        transition: 'background-color 0.2s, opacity 0.2s'
      }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <Box
        component="img"
        sx={shelfItemThumbStyle}
        src={product.images[0]}
        alt={product.title}
      />
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={8}>
          <Box sx={shelfItemDetailsStyle}>
            <p className="title" style={{ color: 'black', margin: '0' }}>{product.title}</p>
            <p className="title" style={{ fontSize: '15px', margin: '0' }}>Quantity: {product.quantity}</p>
          </Box>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          {productFormatWithCurrency}
          <CloseIcon
            sx={{ cursor: 'pointer', marginLeft: '10px' }}
            onClick={() => removeProduct(product)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

CartProduct.propTypes = {
  product: PropTypes.object.isRequired,
  removeProduct: PropTypes.func.isRequired,
  changeProductQuantity: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
};

export default CartProduct;