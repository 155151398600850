import React, { useState, useEffect } from 'react';
import { getBaseUrl } from '../Utils/getBaseUrl';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Spinner from '../Spinner';

function SeminarList() {
  const [isSeminarLoading, setIsSeminarLoading] = useState(true);
  const [seminars, setSeminars] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [width, setWidth] = useState(window.innerWidth);

function handleWindowSizeChange() {
    setWidth(window.innerWidth);
}
useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);

const isMobile = width <= 768;

  useEffect(() => {
    const fetchSeminars = async () => {
      
      try {
        setIsSeminarLoading(true);
        const baseUrl = getBaseUrl();

        const dataRequest = {
            page: currentPage, search: searchTerm
        };
  
        const response = await fetch(`${baseUrl}/api/seminars`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dataRequest),
        });
  
        const data = await response.json();
        setSeminars(data.seminars);
        setTotalPages(data.totalPages);
        setIsSeminarLoading(false);
      } catch (error) {
        console.error('Error fetching seminars:', error);
      }
    };

    fetchSeminars();
  }, [currentPage, searchTerm]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page when search changes
  };

  return (

    <div style={{marginLeft: '20px', marginRight: '20px'}}>
    <h1 style={{ textAlign: 'center', backgroundColor: 'white' }}>Upcoming Seminars From Our Teachers</h1>
      <input type="text" placeholder="Search seminars..." value={searchTerm} onChange={handleSearchChange} />
      {seminars && (
      <Table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Presenter</th>
            <th>Date & Time</th>
            {!isMobile && <><th>Location</th><th>Language</th></>}
          </tr>
        </thead>
        <tbody>
          {seminars.map((seminar) => (
            <tr key={seminar.id}>
              <td>
                <Link to={`/Seminars/${seminar.id}`} className="underline-blue" >{seminar.title}</Link>
              </td>
              <td>{seminar.presenter}</td>
              <td>{new Date(seminar.seminar_datetime).toLocaleString()}</td>
              {!isMobile && <><td>{seminar.location}</td><td>{seminar.language}</td></>}
            </tr>
          ))}
        </tbody>
      </Table>
      )}
      {isSeminarLoading && <Spinner/>}

      <nav>
        <button style={{backgroundColor: 'white'}} onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
          Previous
        </button>
        <button style={{backgroundColor: 'white'}} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
          Next
        </button>
      </nav>
    </div>
  );
}

export default SeminarList;
