import React from "react";
import "./ScoreCard.css"; // Make sure to create and style this CSS file
import PetsIcon from "@mui/icons-material/Pets";
import EmojiNatureIcon from "@mui/icons-material/EmojiNature";
import BugReportIcon from "@mui/icons-material/BugReport";
import AcUnitIcon from "@mui/icons-material/AcUnit";

const animalIcons = [
  <PetsIcon />,
  <EmojiNatureIcon />,
  <BugReportIcon />,
  <AcUnitIcon />,
];

const ScoreCard = ({ scoreCard }) => {
  const { participants = [], topThreeParticipants = {} } = scoreCard;
  const { first, second, third } = topThreeParticipants;

  return (
    <div className="score-card">
      <h2>Quiz Results</h2>
      <div className="top-three">
        {first && (
          <div className="top-participant first">
            <img
              src="https://github.com/ghumman/public-small-videos/blob/main/images/gold-medal.png?raw=true"
              alt="Gold Medal"
            />
            <h3>{first.user_name}</h3>
            <p>{first.accumulated_score} points</p>
          </div>
        )}
        {second && (
          <div className="top-participant second">
            <img
              src="https://github.com/ghumman/public-small-videos/blob/main/images/silver-medal.png?raw=true"
              alt="Silver Medal"
            />
            <h3>{second.user_name}</h3>
            <p>{second.accumulated_score} points</p>
          </div>
        )}
        {third && (
          <div className="top-participant third">
            <img
              src="https://github.com/ghumman/public-small-videos/blob/main/images/bronze-medal.png?raw=true"
              alt="Bronze Medal"
            />
            <h3>{third.user_name}</h3>
            <p>{third.accumulated_score} points</p>
          </div>
        )}
      </div>
      <h3>All Participants</h3>
      {participants.length > 0 ? (
        <ul className="participants-list">
          {participants.map((participant, index) => (
            <li key={index} className="participant">
              {animalIcons[index % animalIcons.length]}
              <span>{participant.user_name}</span>
              <span>{participant.accumulated_score} points</span>
            </li>
          ))}
        </ul>
      ) : (
        <p>No scores available.</p>
      )}
    </div>
  );
};

export default ScoreCard;
