import React from 'react';
import Message from './Message';

const MessageList = ({ groupedMessages, userId, handleReaction, activeMessageId, setActiveMessageId, setMessageInput, setMessageId, messageRefs, navigateToMessage, setParent, courseId, chapterNumber, ws }) => {
  const isEmpty = Object.keys(groupedMessages).length === 0;

  return (
    <div>
      {isEmpty ? (
        <div style={{ textAlign: 'center', backgroundColor: '#f0f0f0', padding: '20px', borderRadius: '10px' }}>
          No messages sent so far.
        </div>
      ) : (
        Object.keys(groupedMessages).map((date) => (
          <div key={date}>
            <h3>{date}</h3>
            {groupedMessages[date].map((message) => (
              <div 
                key={message.message_id} 
                ref={(el) => (messageRefs.current[message.message_id] = el)}
                style={{ marginBottom: '40px', paddingBottom: '10px' }}
              >
                <Message
                  message={message}
                  userId={userId}
                  handleReaction={handleReaction}
                  activeMessageId={activeMessageId}
                  setActiveMessageId={setActiveMessageId}
                  setMessageInput={setMessageInput}
                  setMessageId={setMessageId}
                  navigateToMessage={navigateToMessage}
                  setParent={setParent}
                  courseId={courseId}
                  chapterNumber={chapterNumber}
                  ws={ws}
                />
              </div>
            ))}
          </div>
        ))
      )}
    </div>
  );
};

export default MessageList;