import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // For routing to AboutUs page
import FridayProgrammingClassImage from '../../assets/FridayProgrammingClass1.jpg';
import { useNavigate } from 'react-router-dom';




const FridayProgrammingClassAd = () => {
  const navigate = useNavigate();

  return (
    <div className="image-screen" onClick={() => navigate('/BuyMembership')}>
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '0',
          paddingBottom: '56.25%',
          overflow: 'hidden',
        }}
      >
        <img
          src={FridayProgrammingClassImage}
          alt="Friday Programming Class"
          style={{ width: '100%', maxWidth: '100%', boxSizing: 'border-box' }}
        />
        <Link
          to="/BuyMembership"
          className="camp-button"
          style={{
            position: 'absolute',
            left: '55%',
            transform: 'translate(-50%, -50%)',
            marginTop: '30px',
          }}
        >
          {'ENROLL NOW'}
        </Link>
      </div>
    </div>
  );
};

export default FridayProgrammingClassAd;
