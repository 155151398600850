import React from 'react';
import { Carousel } from 'react-bootstrap';
import { sliderTimer } from './../Utils/constants';
import './SeminarSlider.css'; // Import the CSS file

const SeminarSlider = (seminar) => {
  const imageUrls = seminar && seminar.seminar && seminar.seminar.image_urls || [];
  return (
    <div style={{ objectFit: 'contain', overflow: 'hidden' }}>
      <Carousel variant="dark">
        {imageUrls.map((item, index) => (
          <Carousel.Item interval={sliderTimer} key={index}>
            <img style={{ width: "100%", height: "100%" }} src={item} alt={`Slide ${index}`} />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  )
}
export default SeminarSlider;