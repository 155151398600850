import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import './style.css';

const CoursesCommunityTabPanel = ({ eligibleCourses, history }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const filteredCourses = eligibleCourses.filter(course => {
    const matchesSearch = course.name.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesSearch;
  });

  return (
    <div>
      <div className="filter-container">
        <Form.Control
          type="text"
          placeholder="Search by Name"
          value={searchQuery}
          onChange={handleSearchChange}
          className="search-bar"
        />
      </div>
      {filteredCourses && filteredCourses.length > 0 ? (
        <div>
          <Container>
            <Row className="justify-content-center">
              {filteredCourses.map(course => (
                <Col key={course.course_id} xs={12} sm={6} md={4} lg={3}>
                  <Link to={`/Courses/${course.course_id}`}>
                    <Card border="success" style={{ width: '18rem', height: '25rem', padding: '10px' }}>
                      <Card.Img
                        variant="top"
                        width={100}
                        height={300}
                        src={`https://ghummantech-website.s3.us-east-2.amazonaws.com/GT_Course_${course.course_id}_1.jpg`} />
                      <Card.Body style={{ textAlign: 'center' }}>
                        <Col>
                          <Row style={{ display: 'grid', placeItems: 'center', marginTop: '10px', fontWeight: 'bold' }}>
                            {course.name}
                          </Row>
                        </Col>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>You didn't buy any courses yet!</div>
      )}
    </div>
  );
}

export default CoursesCommunityTabPanel;