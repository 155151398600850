import React from 'react';

import './style.css';

export default () => (
  <div className="spinner lds-ring">
    <div />
    <div />
    <div />
    <div />
  </div>
);
