import React from 'react';
import barCode from '../../assets/whatapp-group.jpg';
import { useMediaQuery } from 'react-responsive';
import newsLetter from '../../assets/newsLetter.jpg';
import SubscribeNewsLetter from './SubscribeNewsLetter';
import { Element } from 'react-scroll'

const WhatsAppAnnouncementGroup = () => {
    const isDesktopOrLarger = useMediaQuery({ minWidth: 1400 });

    return (
        <Element name="subscribe" style={{ display: 'flex', flexDirection: isDesktopOrLarger ? 'row' : 'column', justifyContent: 'center', alignContent: 'center' }}>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <a href="https://chat.whatsapp.com/IU3XYWWeVE2K717wcFiWFU" target="_blank" rel="noopener noreferrer">
                    <img src={barCode} alt="Announcement" style={{ width: isDesktopOrLarger ? '30vw' : '90vw', height: isDesktopOrLarger ? '60vw' : '90vw', objectFit: 'contain' }} />
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px', textAlign: 'center' }}>
                        <b>Join Our Whatsapp Announcement Group for Great New Offers</b>
                    </div>
                </a>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <div style={{ display: 'flex', flex: 1, alignItems: 'center', margin: '30px' }}>
                    <div style={{
                        width: '100%',
                        height: '100%',
                        backgroundImage: `url(${newsLetter})`,
                        backgroundSize: 'cover', // Stretch image to fill container
                        backgroundPosition: 'center', // Center image
                        display: 'flex', // Enable flexbox for centering
                        justifyContent: 'center', // Center content horizontally
                        alignItems: 'flex-end', // Center content vertically
                    }}>
                        <SubscribeNewsLetter />
                    </div>
                </div>
                <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <a href="https://www.youtube.com/@ghummantech" target="_blank" rel="noopener noreferrer">
                        <video autoPlay muted loop style={{ width: isDesktopOrLarger ? '' : '90vw', height: isDesktopOrLarger ? '' : '45vw', objectFit: 'contain' }}>
                            <source src="https://github.com/ghumman/public-small-videos/blob/main/LandingPageYoutubeSubscribe.mp4?raw=true" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </a>
                </div>
            </div>
        </Element>
    );
};

export default WhatsAppAnnouncementGroup;