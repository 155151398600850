export const initializeCanvas = async (currentDrawingCanvas, canvasRef) => {
  // Check if currentDrawingCanvas is a Blob and read it
  if (currentDrawingCanvas instanceof Blob) {
    try {
      currentDrawingCanvas = await readBlobAsText(currentDrawingCanvas);
      currentDrawingCanvas = JSON.parse(currentDrawingCanvas);
    } catch (error) {
      console.error("Error reading or parsing currentDrawingCanvas:", error);
      return;
    }
  }

  // Convert ASCII values to string if currentDrawingCanvas.data is an array of numbers
  if (
    Array.isArray(currentDrawingCanvas?.data) &&
    typeof currentDrawingCanvas.data[0] === "number"
  ) {
    try {
      const jsonString = asciiArrayToString(currentDrawingCanvas.data);
      currentDrawingCanvas.data = JSON.parse(jsonString);
    } catch (error) {
      console.error("Error converting ASCII values to JSON:", error);
      return;
    }
  }

  // Ensure currentDrawingCanvas.data is an array of drawing objects
  if (!Array.isArray(currentDrawingCanvas?.data)) {
    console.error(
      "currentDrawingCanvas.data is not an array of drawing objects"
    );
    return;
  }

  const canvas = canvasRef.current;
  if (!canvas) {
    console.error("Canvas reference is null");
    return;
  }

  const context = canvas.getContext("2d");

  if (currentDrawingCanvas.data.length > 0) {
    currentDrawingCanvas.data.forEach((drawing) => {
      context.strokeStyle = drawing.color;
      context.lineWidth = drawing.lineWidth;
      context.beginPath();
      context.moveTo(drawing.startX, drawing.startY);
      context.lineTo(drawing.endX, drawing.endY);
      context.stroke();
    });
  }
};

// Helper function to read Blob as text
const readBlobAsText = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject(reader.error);
    reader.readAsText(blob);
  });
};

// Helper function to convert ASCII array to string in chunks
const asciiArrayToString = (array) => {
  const chunkSize = 10000; // Adjust chunk size as needed
  let result = "";
  for (let i = 0; i < array.length; i += chunkSize) {
    result += String.fromCharCode(...array.slice(i, i + chunkSize));
  }
  return result;
};
