import React from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import SubscriptionCheckboxes from "../CommonComponents/SubscriptionCheckboxes";


const CustomerInformation = ({
  customerBook,
  handleSubmitBookEmail,
  handleNameChangeBook,
  handleParentNameChangeBook,
  handlePhoneChangeBook,
  handleCityChangeBook,
  handleStateChangeBook,
  handleZipChangeBook,
  handleCountryChangeBook,
  handleEmailChangeBook,
  handleEmailChangeBookConfirm,
  setCustomerBook,
  isCustomerUsingCard
}) => {
  return (
    <Form onSubmit={handleSubmitBookEmail} style={{ backgroundColor: "white" }}>
      <Row>
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Student Name</Form.Label>
          <Form.Control
            placeholder="Enter Studnet/s Name/s"
            type="text"
            maxLength={50}
            value={customerBook.name}
            onChange={handleNameChangeBook}
            style={{ backgroundColor: "white" }}
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Parent Name</Form.Label>
          <Form.Control
            placeholder="Parent Name in case student is minor"
            type="text"
            maxLength={50}
            value={customerBook.parentName}
            onChange={handleParentNameChangeBook}
            style={{ backgroundColor: "white" }}
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            placeholder="Enter phone number"
            type="tel"
            maxLength={50}
            value={customerBook.phoneNumber}
            onChange={handlePhoneChangeBook}
            style={{ backgroundColor: "white" }}
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>City</Form.Label>
          <Form.Control
            placeholder="Enter city"
            type="text"
            maxLength={50}
            value={customerBook.city}
            onChange={handleCityChangeBook}
            style={{ backgroundColor: "white" }}
          />
        </Form.Group>
      </Row>

      <Row>
        <Col>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>State</Form.Label>
            <Form.Control
              placeholder="Enter state"
              type="text"
              maxLength={50}
              value={customerBook.stateProvince}
              onChange={handleStateChangeBook}
              style={{ backgroundColor: "white" }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Zip Code</Form.Label>
            <Form.Control
              placeholder="Enter Zip Code"
              type="number"
              maxLength={50}
              value={customerBook.zip}
              onChange={handleZipChangeBook}
              style={{ backgroundColor: "white" }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Country</Form.Label>
          <Form.Control
            placeholder="Enter country"
            type="text"
            maxLength={50}
            value={customerBook.country}
            onChange={handleCountryChangeBook}
            style={{ backgroundColor: "white" }}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label style={{ color: "red" }}>Email*</Form.Label>
          <Form.Control
            placeholder="Enter email"
            type="email"
            required
            maxLength={50}
            value={customerBook.emailBook}
            onChange={handleEmailChangeBook}
            style={{ backgroundColor: "white" }}
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} controlId="formGridEmailConfirm">
          <Form.Label style={{ color: "red" }}>
            Confirm Email*
          </Form.Label>
          <Form.Control
            placeholder="Enter email (again)"
            type="email"
            required
            maxLength={50}
            value={customerBook.emailBookConfirm}
            onChange={handleEmailChangeBookConfirm}
            style={{ backgroundColor: "white" }}
          />
        </Form.Group>
      </Row>
      <SubscriptionCheckboxes
        formData={customerBook}
        setFormData={setCustomerBook}
        isCustomerUsingCard={isCustomerUsingCard}
      />
      <div style={{ marginBottom: "10px" }}>
        <Button variant="primary" type="submit">
          Confirm Email and Continue to Payment
        </Button>
      </div>
    </Form>
  );
};

export default CustomerInformation;