import React from "react";
import "./ColorPicker.css"; // Import the CSS file

const ColorPicker = ({ selectedColor, setSelectedColor }) => {
  return (
    <input
      type="color"
      value={selectedColor}
      onChange={(e) => setSelectedColor(e.target.value)}
      className="color-picker" // Apply the CSS class
    />
  );
};

export default ColorPicker;
