import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

const ProfileButton = () => {
  const [email, setEmail] = useState("");
  
  useEffect(() => {
    const resultEmail = localStorage.getItem("email");
    setEmail(resultEmail);
  }, []);
  
  const navigate = useNavigate();
  
  return (
    <div style={{ marginTop: '10px' }}>
      <Button 
        variant="contained" 
        sx={{
          backgroundColor: 'green',
          color: 'white',
          '&:hover': {
            backgroundColor: '#d70000',
          },
        }} 
        onClick={() => email ? navigate("/MyAccount") : navigate("/Login")}
      >
        User Profile
      </Button>
    </div>
  );
};

export default ProfileButton;