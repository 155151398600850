import React from "react";
import { Box, Switch, Typography, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";

const SubscriptionSwitch = ({ isMember, handleToggleChange }) => {
  const navigate = useNavigate();

  return (
    <Box
      mt={4}
      textAlign="center"
      sx={{
        border: '2px solid #ff0604',
        borderRadius: '15px',
        padding: '20px'
      }}
    >
      <Typography
        variant="h4"
        component="div"
        gutterBottom
        sx={{
          fontWeight: 'bold',
          color: '#ff0604',
          padding: '10px',
          display: 'inline-block'
        }}
      >
        Ghumman Tech Subscription
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
        <Switch
          checked={isMember}
          onChange={handleToggleChange}
          disabled={!isMember}
          sx={{
            padding: '5px'
          }}
        />
        {isMember ? (
          <Typography variant="body1" ml={2}>
            You are a Ghumman Tech Gold Member 🏅
          </Typography>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/BuyMembership')}
            sx={{
              padding: '5px 15px',
              ml: 2
            }}
          >
            BECOME MEMBER TODAY
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default SubscriptionSwitch;