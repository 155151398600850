import React, { useState, useEffect, useRef } from "react";
import QuestionCardClick from "./QuestionCardClick";
import Clock from "./Clock";
import { useMediaQuery } from "react-responsive";
import Spinner from "../../../Spinner";
import { replaceWithImages } from "../../../Utils/replaceWithImages";
import { shuffleOptions } from "../../../Utils/shuffleOptions";

const Quiz = ({
  quizData,
  chapterContent,
  questionToStart,
  questionStatus,
  sessionPin,
  courseId,
  chapterNumber,
  socketRef,
  setQuestionStatus,
  isHost,
  userId,
  setCurrentQuestionScore,
}) => {
  const isDesktopOrLarger = useMediaQuery({ minWidth: 768 });
  const course_language = chapterContent.course_language || "en";

  if (quizData.length === 0) {
    return null;
  }

  const [selectedOption, setSelectedOption] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [score, setScore] = useState(0);
  const [shuffledOptions, setShuffledOptions] = useState([]);
  const [userAnswers, setUserAnswers] = useState(
    Array(quizData.length).fill(null)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [remainingTime, setRemainingTime] = useState(10); // 10 seconds timer
  const timerRef = useRef(null);

  if (questionToStart >= quizData.length) {
    return null;
  }
  const currentCard = quizData[questionToStart];
  const question = replaceWithImages(
    currentCard.question,
    currentCard.attachments,
    isDesktopOrLarger
  );
  const answer = replaceWithImages(
    currentCard.answer,
    currentCard.attachments,
    isDesktopOrLarger
  );
  const option1 = replaceWithImages(
    currentCard.option1,
    currentCard.attachments,
    isDesktopOrLarger
  );
  const option2 = replaceWithImages(
    currentCard.option2,
    currentCard.attachments,
    isDesktopOrLarger
  );
  const option3 = replaceWithImages(
    currentCard.option3,
    currentCard.attachments,
    isDesktopOrLarger
  );

  useEffect(() => {
    if (quizData && Array.isArray(quizData) && quizData.length > 0) {
      const shuffled = shuffleOptions([
        {
          text: option1,
          language: quizData[questionToStart]?.option1_language,
          origText: currentCard.option1,
        },
        {
          text: option2,
          language: quizData[questionToStart]?.option2_language,
          origText: currentCard.option2,
        },
        {
          text: option3,
          language: quizData[questionToStart]?.option3_language,
          origText: currentCard.option3,
        },
        {
          text: answer,
          language: quizData[questionToStart]?.answer_language,
          origText: currentCard.answer,
        },
      ]);
      setShuffledOptions(shuffled);
    }
  }, [quizData, questionToStart, chapterContent]);

  useEffect(() => {
    setSelectedOption(userAnswers[questionToStart]);
  }, [questionToStart]);

  useEffect(() => {
    if (questionStatus === "start") {
      setRemainingTime(10); // Reset timer to 10 seconds
      timerRef.current = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timerRef.current);
            handleTimeout();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timerRef.current);
  }, [questionStatus]);

  const handleOptionChange = (op) => {
    if (questionStatus !== "start") return;

    let score = 0;
    if (op.text === answer) {
      // Calculate score based on remaining time
      score = Math.floor((remainingTime / 10) * 1000);
    }

    setCurrentQuestionScore(score);

    const message = JSON.stringify({
      type: "upload-score",
      course_id: courseId,
      chapter_number: chapterNumber,
      session_pin: sessionPin,
      kahoot_user_id: userId,
      user_id: userId,
      question_number: questionToStart,
      score: score,
    });
    socketRef.current.send(message);

    setQuestionStatus("stop");
    clearInterval(timerRef.current);
  };

  const handleTimeout = () => {
    let message;
    if (isHost) {
      message = JSON.stringify({
        type: "stop-question",
        course_id: courseId,
        chapter_number: chapterNumber,
        session_pin: sessionPin,
        kahoot_host_id: userId,
        question_number: questionToStart,
      });
    } else {
      setCurrentQuestionScore(0);
      message = JSON.stringify({
        type: "upload-score",
        course_id: courseId,
        chapter_number: chapterNumber,
        session_pin: sessionPin,
        kahoot_user_id: userId,
        user_id: userId,
        question_number: questionToStart,
        score: 0,
      });
    }

    socketRef.current.send(message);

    setQuestionStatus("stop");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {isLoading && <Spinner />}
      <div style={{ marginBottom: "10px", position: "relative" }}>
        {quizData && (
          <div style={{ marginTop: "10px", position: "relative" }}>
            <QuestionCardClick
              question={question}
              quizData={quizData}
              currentQuestion={questionToStart}
              shuffledOptions={shuffledOptions}
              selectedOption={selectedOption}
              handleOptionChange={handleOptionChange}
              isDesktopOrLarger={isDesktopOrLarger}
              isHost={isHost}
              remainingTime={remainingTime}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Quiz;
