import React from 'react';
import products from '../../assets/landingPageThreeCards/products.jpg';
import { Link } from 'react-router-dom';

const ProductsLandingPageDetails = () => {
  const containerStyle = {
    width: '80vw',
    height: '60vh',
    backgroundImage: `url(${products})`,
    backgroundSize: 'cover', // Stretch image to fill container
    backgroundPosition: 'center', // Center image
    display: 'flex', // Enable flexbox for centering
    justifyContent: 'center', // Center content horizontally
    alignItems: 'center', // Center content vertically
  };

  const textStyle = {
    fontSize: '3em', // Adjust font size as needed
  };

  return (
    <Link to="/Categories">
    <div style={containerStyle}>
    </div>
    </Link>
  );
};

export default ProductsLandingPageDetails;
