import React, {useEffect} from 'react';
import './style.css';
import Auth from './Authentication/Auth';

const SignInPopup = ({ closePopup }) => {
  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflow = 'hidden';

    // Re-enable scrolling on unmount
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-button" onClick={closePopup}>×</button>
        <div className="login-container">
          <Auth closePopup={closePopup}/>
        </div>
      </div>
    </div>
  );
};

export default SignInPopup;