const Review = ({ quizData, userAnswers, isDesktopOrLarger }) => {
    const replaceWithImages = (text, attachments, isDesktopOrLarger) => {
        return text.replace(/\[attach(\d+)\]/g, (match, index) => {
            const attachmentIndex = parseInt(index) - 1;
            if (attachments[attachmentIndex]) {
                return `<img style="width: ${isDesktopOrLarger ? '500px' : '200px'}; height: ${isDesktopOrLarger ? '500px' : '400px'}; object-fit: contain;" src="${attachments[attachmentIndex]}" alt="attachment${index}" />`;
            } else {
                return match;
            }
        });
    };

    return (
        <div>
            {quizData.map((question, index) => {
                const questionText = replaceWithImages(question.question, question.attachments, isDesktopOrLarger);
                const answerText = replaceWithImages(question.answer, question.attachments, isDesktopOrLarger);
                const option1Text = replaceWithImages(question.option1, question.attachments, isDesktopOrLarger);
                const option2Text = replaceWithImages(question.option2, question.attachments, isDesktopOrLarger);
                const option3Text = replaceWithImages(question.option3, question.attachments, isDesktopOrLarger);

                return (
                    <div key={index}>
                        <p style={{ fontWeight: 'bold', marginLeft: '20px' }} dangerouslySetInnerHTML={{ __html: questionText }}></p>
                        {['option1', 'option2', 'option3', 'answer'].map((option, optionIndex) => {
                            const isCorrectAnswer = question[option] === question.answer;
                            const isUserAnswer = question[option] === userAnswers[index];
                            const isUserCorrect = isCorrectAnswer && isUserAnswer;
                            const isUserIncorrect = !isCorrectAnswer && isUserAnswer;

                            let optionColor;
                            let optionSymbol;
                            if (isCorrectAnswer) {
                                optionColor = 'green';
                                optionSymbol = '✓';
                            } else if (isUserIncorrect) {
                                optionColor = 'red';
                                optionSymbol = '✗';
                            } else {
                                optionColor = 'black';
                                optionSymbol = '';
                            }

                            let optionText;
                            switch (option) {
                                case 'option1':
                                    optionText = option1Text;
                                    break;
                                case 'option2':
                                    optionText = option2Text;
                                    break;
                                case 'option3':
                                    optionText = option3Text;
                                    break;
                                case 'answer':
                                    optionText = answerText;
                                    break;
                                default:
                                    optionText = '';
                            }

                            return (
                                <>
                                    <div key={optionIndex} style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                                        <span dangerouslySetInnerHTML={{ __html: optionText }}></span>
                                        <span style={{ marginLeft: '10px', color: optionColor, fontSize: '5em' }}>{optionSymbol}</span>
                                    </div>
                                   
                                </>
                            );
                        })}
                    <hr style={{ margin: '20px 0', border: '2px solid black' }} />
                    </div>
                );
            })}

        </div>
    );
};

export default Review;