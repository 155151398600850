import React, { useState, useEffect } from 'react';
import { getBaseUrl } from '../Utils/getBaseUrl';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Spinner from '../Spinner';

function JobsList() {
  const [isLoading, setIsLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredJobs, setFilteredJobs] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        setIsLoading(true);
        const baseUrl = getBaseUrl();
        const dataRequest = {
          page: 1,
          search: searchTerm
        };

        const response = await fetch(`${baseUrl}/api/jobs`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dataRequest)
        });

        const data = await response.json();
        console.log('value of data: ', data);
        setJobs(data);
        // Extracting unique categories from jobs
        const uniqueCategories = [...new Set(data.map(job => job.category))];
        setCategories(uniqueCategories);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    fetchJobs();
  }, []);

  useEffect(() => {
    // Filtering jobs based on search term
    const filtered = jobs.filter(job =>
      job.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    console.log('value of filtered: ', filtered);
    console.log('value of searchTerm: ', searchTerm);
    setFilteredJobs(filtered);
    console.log('value of jobs: ', jobs);
  }, [jobs, searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div style={{marginLeft: '10px', marginRight: '10px'}}>
      <h1 style={{ margin: '10px', textAlign: 'center' }}>Jobs</h1>
      <input type="text" placeholder="Search jobs..." value={searchTerm} onChange={handleSearchChange} />

      {isLoading ? (
        <Spinner />
      ) : (
        categories.map(category => (
          <div key={category}>
            <h2>{category}</h2>
            <Table>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Location</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredJobs.filter(job => job.category === category).map(job => (
                  <tr key={job.id}>
                    <td>{job.title}</td>
                    <td>{job.location}</td>
                    <td>
                      <Link 
                      to={`/Careers/${job.id}`}
                      style={{
                        color: 'blue',        // Blue color
                        textDecoration: 'underline',  // Underline
                        backgroundColor: '#f0f0f0',  // Different background color
                        padding: '5px 10px',  // Add padding for better readability
                        borderRadius: '5px',  // Add border radius for rounded corners
                        display: 'inline-block',  // Display as block element
                      }}
                      >Apply</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ))
      )}
    </div>
  );
}

export default JobsList;
